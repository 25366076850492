import React,
{
  useCallback,
  useEffect,
  useState,
} from 'react';
import { UseFormTrigger } from 'react-hook-form';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { UseFormStateReturn } from 'react-hook-form/dist/types';

const AutoSaveStatus = {
  NONE: 'NONE',
  SUBMITTING: 'SUBMITTING',
  ERROR: 'ERROR',
  SAVED: 'SAVED',
};

export default function useAutoSave<T>(
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  autoSaveSubmit?: (value: T) => void,
  trigger?: UseFormTrigger<any>,
  submitting?: boolean,
) {
  const { field, fieldState } = control;
  // eslint-disable-next-line no-undef
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [initialValue, setInitialValue] = useState<T | undefined>(field.value);
  const [status, setStatus] = useState<string>(AutoSaveStatus.NONE);
  const editing = () => fieldState.isDirty;
  const isEditing = (value: T) => initialValue !== value;

  const clearTimer = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
    }
  }, [timer]);

  useEffect(() => clearTimer,
    [clearTimer]);
  const getSaveResult = () => (<div />);

  // const getSaveResult = () => (
  //   <div>
  //     {
  //       status === AutoSaveStatus.NONE && editing() && !submitting && (
  //         <AiOutlineEdit className="glyphicon" />
  //       )
  //     }
  //     {
  //       (status === AutoSaveStatus.SUBMITTING || submitting) && (
  //         <AiOutlineLoading className="glyphicon fa_spin" />
  //       )
  //     }
  //     {
  //       status === AutoSaveStatus.SAVED && (
  //         <AiOutlineCheck className="glyphicon" />
  //       )
  //     }
  //     {
  //       status === AutoSaveStatus.ERROR && (
  //         <BiError className="glyphicon" />
  //       )
  //     }
  //   </div>
  // );

  const hasSaveResult = submitting === true
    || (autoSaveSubmit
      && (
        (status === AutoSaveStatus.NONE && editing())
        || status === AutoSaveStatus.SUBMITTING
        || status === AutoSaveStatus.SAVED
        || status === AutoSaveStatus.ERROR
      )
    );

  const handleChange = (value: T) => {
    field.onChange(value);
    setStatus(AutoSaveStatus.NONE);
    if (autoSaveSubmit) {
      clearTimer();
      setTimer(setTimeout(() => {
        handleSubmit(value);
      }, 1000));
    }
  };

  const handleSubmit = async (value: T) => {
    if (!autoSaveSubmit) {
      return;
    }
    if (isEditing(value)) {
      setStatus(AutoSaveStatus.SUBMITTING);
      try {
        if (trigger) {
          const isValid = await trigger(field.name);
          if (!isValid) {
            setStatus(AutoSaveStatus.ERROR);
            return;
          }
        }
        await autoSaveSubmit(value);
        setInitialValue(value);
        setStatus(AutoSaveStatus.SAVED);
      } catch {
        setStatus(AutoSaveStatus.ERROR);
      }
    }
  };

  const onBlur = (e: T) => {
    clearTimer();
    if (autoSaveSubmit) {
      handleSubmit(e);
    }
  };

  return {
    hasSaveResult,
    getSaveResult,
    handleChange,
    onBlur,
  };
}
