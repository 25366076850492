import React from 'react';
import {
  MANAGE,
  MANAGE_EXERCICE,
  MANAGE_MEAL,
  MANAGE_WORKOUT,
  MANAGE_WORKOUT_ADD,
  MANAGE_WORKOUT_EDIT,
  MANAGE_WORKOUT_SERIE_EDIT,
  MANAGE_FOODS,
  MANAGE_MISSING_MEALS,
} from 'routes/Routes';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Manage from 'pages/swole-app/manage/Manage';
import Workouts from 'pages/swole-app/manage/workouts/Workouts';
import WorkoutCreate from 'pages/swole-app/manage/workouts/WorkoutCreate';
import WorkoutEdit from 'pages/swole-app/manage/workouts/WorkoutEdit';
import WorkoutSerieEdit from 'pages/swole-app/manage/workouts/WorkoutSerieEdit';
import ExercicesManage from 'pages/swole-app/manage/exercices/ExercicesManage';
import MealsManage from 'pages/swole-app/manage/meals/MealsManage';
import FoodRouter from 'pages/swole-app/manage/foods/FoodRouter';
import MissingMeals from 'pages/swole-app/manage/missingMeals/MissingMeals';

// TODO split routes
export default function ManageRouter() {
  return (
    <Switch>
      <Route exact path={MANAGE} component={Manage} />
      <Route exact path={MANAGE_EXERCICE} component={ExercicesManage} />
      <Route exact path={MANAGE_WORKOUT} component={Workouts} />
      <Route exact path={MANAGE_WORKOUT_ADD} component={WorkoutCreate} />
      <Route exact path={MANAGE_WORKOUT_EDIT} component={WorkoutEdit} />
      <Route exact path={MANAGE_WORKOUT_SERIE_EDIT} component={WorkoutSerieEdit} />
      <Route exact path={MANAGE_MEAL} component={MealsManage} />
      <Route path={MANAGE_FOODS} component={FoodRouter} />
      <Route exact path={MANAGE_MISSING_MEALS} component={MissingMeals} />
      <Route render={() => <Redirect to={MANAGE} />} />
    </Switch>
  );
}
