// eslint-disable-next-line max-classes-per-file
type JsonResponse = { [key: string]: string[] } | { errorMessage: string[] };

export class FetchError {
  json_response: JsonResponse | null;

  status: number;

  constructor(json_response: JsonResponse | null, status: number) {
    this.json_response = json_response;
    this.status = status;
  }

  toString() {
    return `${this.status} ${this.json_response}`;
  }
}

export class BackendAuthenticationError {
  private json_response: JsonResponse | null;

  constructor(json_response: JsonResponse | null) {
    this.json_response = json_response;
  }
}
