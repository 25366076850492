import React from 'react';
// Import auth directly because most components need it
// Other Firebase libraries can be lazy-loaded as-needed
import 'firebase/auth';
import LogInForm from 'pages/authentification/LogInForm';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LOG_IN, SIGN_IN } from 'routes/Routes';
import SignInForm from './SignInForm';

export default function AuthentificationRoot() {
  return (
    <Switch>
      <Route exact path={LOG_IN} component={LogInForm} />
      <Route exact path={SIGN_IN} component={SignInForm} />
      <Route render={() => <Redirect to={LOG_IN} />} />
    </Switch>
  );
}
