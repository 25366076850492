import React, { useState } from 'react';
import { useWorkoutTemplateBackend } from 'network/queries/WorkoutTemplateQueries';
import Messages from 'services/i18n/Messages';
import {
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { RouterPrompt } from 'lib/RouterPrompt';
import SpinButton from 'theme/SpinButton';
import { FetchError } from 'network/Errors';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { Delete } from '@mui/icons-material';
import confirmationService from 'services/ConfirmationService';
import { NotificationService } from 'lib/notification';
import ExerciceTemplateModal from 'pages/swole-app/manage/workouts/ExerciceTemplateModal';
import { WorkoutTemplateExercice } from 'types/types';
import Button from 'theme/Button';

type Param = {
  id: string,
  serieId: string,
};

export default function WorkoutSerieEdit() {
  const { id, serieId } = useParams<Param>();
  const location = useLocation();
  const workoutTemplateQueries = useWorkoutTemplateBackend();
  const [editExercice, setEditExercice] = useState<WorkoutTemplateExercice | undefined>();
  const {
    createWorkoutTemplateExercice,
    getWorkoutTemplateExercice,
    deleteWorkoutTemplateExercice,
  } = workoutTemplateQueries;
  const { data: workoutExercices } = getWorkoutTemplateExercice(id, serieId);

  const [submitting, setSubmitting] = useState(false);
  const [apiErrors, setApiErrors] = useState({});

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<WorkoutTemplateExercice>();

  const onSubmit: SubmitHandler<WorkoutTemplateExercice> = async (
    data: WorkoutTemplateExercice,
  ) => {
    setSubmitting(true);
    data.order = (workoutExercices?.length || 0) + 1;
    createWorkoutTemplateExercice.mutateAsync({
      data,
      workoutTemplateId: id,
      workoutTemplateSerieId: serieId,
    })
      .then(() => {
        reset(data);
      })
      .catch((error: FetchError) => {
        if (error.json_response) {
          setApiErrors(error.json_response);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const deleteWorkoutExercice = async (
    workoutTemplateId: string,
    workoutTemplateSerieId: string,
    workoutTemplateExerciceId: string,
  ) => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('serie.delete'),
      message: Messages.t('global.delete'),
    });
    if (isConfirmed) {
      setSubmitting(true);
      deleteWorkoutTemplateExercice.mutateAsync({
        workoutTemplateId,
        workoutTemplateSerieId,
        workoutTemplateExerciceId,
      }).then(() => NotificationService.notifySuccess(Messages.t('notifications.delete')))
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <div className="page-body">
      {
        editExercice && (
          <ExerciceTemplateModal
            workoutId={id}
            workoutExercice={editExercice}
            onClose={() => setEditExercice(undefined)}
          />
        )
      }
      <h1>{Messages.t('manage.workouts')}</h1>
      {
        workoutExercices?.map((workoutExercice) => (
          <div className="serie-container">
            <button
              type="button"
              onClick={() => setEditExercice(workoutExercice)}
              className="serie-link"
              key={workoutExercice.id}
            >
              <div>
                <span>poid:</span>
                {workoutExercice.weight}
                <span>kg | rep:</span>
                {workoutExercice.repetitionNumber}
              </div>
              <Button
                variant="icon"
                disabled={submitting}
                onClick={(e) => {
                  e.preventDefault();
                  deleteWorkoutExercice(id, serieId, workoutExercice.id);
                }}
              >
                <Delete />
              </Button>
            </button>
          </div>
        ))
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <RouterPrompt
          whenPageClose={isDirty}
          when={(newPathName) => isDirty && !newPathName.startsWith(location.pathname.split('/').slice(0, -1).join('/'))}
        />
        <Controller
          name="weight"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              type="number"
              error={errors}
              control={controller}
              label={Messages.t('field.weight')}
            />
          )}
        />
        <Controller
          name="repetitionNumber"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              type="number"
              error={errors}
              control={controller}
              label={Messages.t('field.repetitionNumber')}
            />
          )}
        />
        <SpinButton
          editing
          className="submit-button"
          spin={submitting}
          title={Messages.t('formButton.confirm')}
        />
      </form>
    </div>
  );
}
