import React from 'react';
import Messages from 'services/i18n/Messages';
import { ChevronRight } from '@mui/icons-material';
import { useMealHistoryBackend } from 'network/queries/MealsHistoryQueries';
import { Link } from 'react-router-dom';
import { MANAGE_MISSING_MEALS } from 'routes/Routes';

export default function MissingMealsTile() {
  const { getUserMissingMeals } = useMealHistoryBackend();
  const { data: missingMeals } = getUserMissingMeals();

  if (!missingMeals?.length || missingMeals?.length === 0) {
    return <></>;
  }

  return (
    <Link to={MANAGE_MISSING_MEALS} className="tile">
      <div className="tile-title-container">
        <div className="tile-title">
          {Messages.t('dashboard.missingMeal.title')}
          <ChevronRight />
        </div>
        <div className="tile-subtitle">
          {Messages.t('dashboard.missingMeal.subtitle', { number: missingMeals.length })}
        </div>
      </div>
      <div>
        {missingMeals.length}
      </div>
    </Link>
  );
}
