import React from 'react';
import {
  Fastfood,
  ChevronLeft,
} from '@mui/icons-material';
import Image from 'theme/Image';
import { useFoodReferenceBackend } from 'network/queries/FoodReferenceQueries';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import Messages from 'services/i18n/Messages';
import NutritionnalTab from 'pages/common/foods/nutritionalTab/NutritionnalTab';
import EcoProduct from 'pages/common/foods/EcoProduct';
import HealthProduct from 'pages/common/foods/HealthProduct';
import Button from 'theme/Button';
import foodBasketService, { foodBasketTypeEnum } from 'services/FoodBasketService';

type Param = {
  id: string,
};

export default function FoodPage() {
  const { id } = useParams<Param>();
  const history = useHistory();
  const { getFoodReferenceById } = useFoodReferenceBackend();
  const { data: food } = getFoodReferenceById(id);

  return (
    <div className="page-body">
      <h2 role="presentation" onClick={() => history.goBack()}><ChevronLeft /> {Messages.t('manage.meal')}</h2>
      {
        food && (
          <div className="food-card">
            <div className="food-card-image-container">
              <Image alt="product" src={food.imageSmallURL} alternativElement={<Fastfood />} />
            </div>
            <div className="food-card-data">
              <div className="food-name">{food.productName}</div>
              <div className="food-brand">{food.brands}</div>
              <div className="food-quantity">{food.quantity}</div>
            </div>
            <br />
            <NutritionnalTab food={food} />
            <br />
            <EcoProduct food={food} />
            <br />
            <HealthProduct food={food} />
            <Button
              onClick={() => foodBasketService.add({
                food,
                portion: 1,
                type: foodBasketTypeEnum.portion,
              })}
            >
              {Messages.t('formButton.add')}
            </Button>
          </div>
        )
      }
    </div>
  );
}
