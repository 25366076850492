import React, { useState } from 'react';
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { FetchError } from 'network/Errors';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';
import { nullOrUndefined } from 'services/utils/utils';
import { useWorkoutBackend } from 'network/queries/WorkoutQueries';
import confirmationService from 'services/ConfirmationService';
import { WorkoutExercice } from 'types/types';

type Props = {
  workoutId: string,
  workoutExercice: WorkoutExercice,
};
export default function WorkoutExerciceDetail({ workoutExercice, workoutId }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const [apiErrors, setApiErrors] = useState({});
  const [isFilled, setIsFilled] = useState(!nullOrUndefined(workoutExercice.repetitionNumber));

  const { updateWorkoutExercice } = useWorkoutBackend();

  const weightObjectiv = workoutExercice.weight || workoutExercice.weightObjectiv;
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<WorkoutExercice>({
    defaultValues: {
      weight: weightObjectiv,
      repetitionNumber: workoutExercice.repetitionNumber
        || workoutExercice.repetitionNumberObjectiv,
    },
  });

  const onSubmit: SubmitHandler<WorkoutExercice> = async (
    data: WorkoutExercice,
  ) => {
    let updateEqualWeight = false;
    if (data.weight !== weightObjectiv) {
      updateEqualWeight = await confirmationService.confirm({
        title: Messages.t('workout.updateWeight.title'),
        message: Messages.t('workout.updateWeight.text'),
      });
    }
    setSubmitting(true);
    updateWorkoutExercice.mutateAsync({
      data,
      workoutId,
      workoutSerieId: workoutExercice.workoutSerieID,
      workoutExericeId: workoutExercice.id,
      updateEqualWeight,
    })
      .then(() => {
        reset(data);
        setIsFilled(true);
      })
      .catch((error: FetchError) => {
        if (error.json_response) {
          setApiErrors(error.json_response);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <Controller
            name="weight"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                type="number"
                error={errors}
                disabled={isFilled}
                control={controller}
                label={Messages.t('field.weight')}
              />
            )}
          />
          <Controller
            name="repetitionNumber"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                type="number"
                error={errors}
                control={controller}
                label={Messages.t('field.repetitionNumber')}
              />
            )}
          />
          {
            !isFilled && (
              <SpinButton
                editing
                className="submit-button"
                spin={submitting}
                title={Messages.t('formButton.confirm')}
              />
            )
          }
        </div>
      </form>
    </div>
  );
}
