import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import { useHistory } from 'react-router-dom';
import {
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import SpinButton from 'theme/SpinButton';
import { FetchError } from 'network/Errors';
import ActivityForm from 'pages/swole-app/activities/activity/activityForm/ActivityForm';
import { useActivityBackend } from 'network/queries/ActivityQueries';
import { Routes } from 'routes/RoutesUtils';
import {
  ACTIVITIES_DETAILS,
  ID,
  ACTIVITIES,
} from 'routes/Routes';
import {
  ActivityType,
  ActivityCreation,
} from 'types/types';

const SHOW_ACTIVITY_DETAILS_TYPE: ActivityType[] = [ActivityType.ActivityTypeWorkout];
export default function ActivityCreationPage() {
  const history = useHistory();
  const { createActivity } = useActivityBackend();

  const [submitting, setSubmitting] = useState(false);
  const [apiErrors, setApiErrors] = useState({});

  const form = useForm<ActivityCreation>();

  const {
    handleSubmit,
    reset,
  } = form;
  const onSubmit: SubmitHandler<ActivityCreation> = async (
    data: ActivityCreation,
  ) => {
    setSubmitting(true);
    data.startDate = (data.startDate as unknown as Date).toISOString();
    data.endDate = (data.endDate as unknown as Date).toISOString();
    createActivity.mutateAsync(data)
      .then((result) => {
        reset({});
        if (SHOW_ACTIVITY_DETAILS_TYPE.includes(result.type)) {
          history.replace(Routes.withPath(ACTIVITIES_DETAILS, [{ label: ID, value: result.id }]));
        } else {
          history.push(ACTIVITIES);
        }
      })
      .catch((error: FetchError) => {
        if (error.json_response) {
          setApiErrors(error.json_response);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="page-body">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ActivityForm form={form} apiErrors={apiErrors} />
        <SpinButton
          editing
          className="submit-button"
          spin={submitting}
          title={Messages.t('formButton.confirm')}
        />
      </form>
    </div>
  );
}
