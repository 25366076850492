import React, { useState } from 'react';
import DialogWrapper from 'lib/DialogWrapper';
import foodBasketService, { FoodBasketContainer } from 'services/FoodBasketService';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import Messages from 'services/i18n/Messages';
import Button from 'theme/Button';
import FoodBasketLine from 'pages/swole-app/manage/foods/FoodBasketLine';
import { useMealHistoryBackend } from 'network/queries/MealsHistoryQueries';
import {
  useForm,
  Controller,
  SubmitHandler,
} from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';
import SpinButton from 'theme/SpinButton';
import DateTimePickerWrapper from 'lib/form/DateTimePickerWrapper';
import { useMealBackend } from 'network/queries/MealsQueries';
import { FetchError } from 'network/Errors';
import {
  MealFoodDetail,
  UserMealFood,
} from 'types/types';
import { useRecurrentMealsBackend } from 'network/queries/RecurrentMealQueries';
import SelectWrapper from 'lib/form/SelectWrapper';

type Props = {
  foodBasket: FoodBasketContainer,
  onClose: () => void,
};

type CreateMeal = {
  name?: string,
  date?: Date,
  mealName?: string,
  onlyCreateRecipe?: boolean,
  createRecipe?: boolean,
  portions?: number,
  recurrentMealId?: string,
};

export default function ConfirmFoodBasketModal({ foodBasket, onClose }: Props) {
  const [apiErrors, setApiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const { createUserMeal } = useMealHistoryBackend();
  const { updateUserMeal } = useMealBackend();
  const { getUserRecurrentMeals } = useRecurrentMealsBackend();
  const { data: recurrentMeals } = getUserRecurrentMeals();

  const basket = foodBasket.basket || [];
  const onSubmit: SubmitHandler<CreateMeal> = async (
    data: CreateMeal,
  ) => {
    setSubmitting(true);
    if (data.recurrentMealId === '-1') {
      data.recurrentMealId = undefined;
    }
    if (foodBasket.originalId) {
      updateUserMeal.mutateAsync({
        id: foodBasket.originalId,
        name: foodBasket.originalName || '',
        portions: data.portions || 1,
        meal: {
          foods: basket.map((food) => ({
            foodReferenceID: food.food.id,
            weight: food.quantity,
          }) as MealFoodDetail),
        },
      })
        .then(() => {
          foodBasketService.delete();
          onClose();
        })
        .catch((error: FetchError) => {
          if (error.json_response) {
            setApiErrors(error.json_response);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      createUserMeal.mutateAsync({
        date: foodBasket.fixDate || data.date?.toISOString(),
        mealName: data.mealName || data.name || foodBasket.originalName,
        name: data.createRecipe ? data.name : undefined,
        recipePortions: data.createRecipe ? data.portions : undefined,
        onlyCreateRecipe: data.createRecipe ? data.onlyCreateRecipe : undefined,
        recurrentMealId: foodBasket.fixRecurrentMealId || data.recurrentMealId,
        foods: basket.map((food) => ({
          foodReferenceID: food.food.id,
          weight: food.quantity,
        }) as UserMealFood),
      })
        .then(() => {
          foodBasketService.delete();
          onClose();
        })
        .catch((error: FetchError) => {
          if (error.json_response) {
            setApiErrors(error.json_response);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateMeal>({
    defaultValues: {
      date: new Date(),
      createRecipe: !!foodBasket.originalId,
      onlyCreateRecipe: !!foodBasket.originalId,
    },
  });
  const formValues = watch();

  const isBasketSameWithOriginal = foodBasketService.isBasketSameWithOriginal(foodBasket);
  return (
    <DialogWrapper open onClose={onClose}>
      <DialogTitle>{foodBasket.originalName || Messages.t('food.basket.modal.title')}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {
            basket.map((basketFood) => (
              <FoodBasketLine key={basketFood.food.id} foodBasket={basketFood} />
            ))
          }
          {
            !isBasketSameWithOriginal && (
              <>
                <Controller
                  name="createRecipe"
                  control={control}
                  render={(controller) => (
                    <CheckboxWrapper
                      apiErrors={apiErrors}
                      disabled={!!foodBasket.originalId}
                      control={controller}
                      label={(foodBasket.originalFoodBasket?.length || 0) > 0 ? Messages.t('field.createOtherRecipe') : Messages.t('field.createRecipe')}
                      error={errors}
                    />
                  )}
                />
                {
                  formValues.createRecipe && (
                    <>
                      <Controller
                        name="onlyCreateRecipe"
                        control={control}
                        render={(controller) => (
                          <CheckboxWrapper
                            apiErrors={apiErrors}
                            disabled={!!foodBasket.originalId}
                            control={controller}
                            label={Messages.t('field.onlyCreateRecipe')}
                            error={errors}
                          />
                        )}
                      />
                      <Controller
                        name="portions"
                        control={control}
                        render={(controller) => (
                          <TextFieldWrapper
                            apiErrors={apiErrors}
                            type="number"
                            control={controller}
                            label={Messages.t('field.portion')}
                            error={errors}
                          />
                        )}
                      />
                      <Controller
                        name="name"
                        control={control}
                        render={(controller) => (
                          <TextFieldWrapper
                            apiErrors={apiErrors}
                            requierd
                            error={errors}
                            control={controller}
                            label={Messages.t('field.name')}
                          />
                        )}
                      />
                    </>
                  )
                }
              </>
            )
          }
          {
            !formValues.onlyCreateRecipe && !foodBasket.fixDate && (
              <Controller
                name="date"
                control={control}
                render={(controller) => (
                  <DateTimePickerWrapper
                    apiErrors={apiErrors}
                    control={controller}
                    label={Messages.t('field.date')}
                    error={errors}
                  />
                )}
              />
            )
          }
          {
            !!recurrentMeals && !foodBasket.fixRecurrentMealId && (
              <Controller
                name="recurrentMealId"
                control={control}
                render={(controller) => (
                  <SelectWrapper
                    apiErrors={apiErrors}
                    control={controller}
                    values={[
                      {
                        key: '-1',
                        label: Messages.t('meal.extra'),
                      },
                      ...recurrentMeals.map((recurrentMeal) => ({
                        key: recurrentMeal.id,
                        label: recurrentMeal.name,
                      })),
                    ]}
                    label={Messages.t('field.meal')}
                    error={errors}
                  />
                )}
              />
            )
          }
          {
            (!formValues.createRecipe || isBasketSameWithOriginal) && (
              <Controller
                name="mealName"
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    requierd
                    error={errors}
                    control={controller}
                    label={Messages.t('field.name')}
                  />
                )}
              />
            )
          }
        </DialogContent>
        <DialogActions>
          <Button color="error">{Messages.t('formButton.cancel')}</Button>
          <SpinButton
            editing
            className="submit-button"
            spin={submitting}
            disabled={!basket || basket.length === 0}
          >
            {foodBasket.originalName ? Messages.t('formButton.save') : Messages.t('formButton.add')}
          </SpinButton>
        </DialogActions>
      </form>
    </DialogWrapper>
  );
}
