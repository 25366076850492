import React, {
  MouseEventHandler,
  PointerEventHandler,
  ReactNode,
  TouchEventHandler,
} from 'react';
import {
  Dialog,
  IconButton,
} from '@mui/material';
import {
  Cancel,
  Close,
} from '@mui/icons-material';

type Props = {
  children: ReactNode,
  open: boolean,
  onClose: () => void,
  hideCloseBtn?: boolean,
  className?: string,
  onPointerUp?: PointerEventHandler<HTMLDivElement>,
  onMouseUp?: MouseEventHandler<HTMLDivElement>,
  onTouchEnd?: TouchEventHandler<HTMLDivElement>,
  darkCloseBtn?: boolean,
};

export default function DialogWrapper(
  {
    children,
    open,
    onClose,
    hideCloseBtn,
    className = '',
    onPointerUp,
    onMouseUp,
    onTouchEnd,
    darkCloseBtn,
  }: Props,
) {
  return (
    <Dialog
      open={open}
      className={`dialog-wrapper ${className}`}
      onClose={onClose}
      onPointerUp={onPointerUp}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
    >
      {
        !hideCloseBtn && (
          <div className="close-btn-container">
            <IconButton onClick={onClose}>
              {darkCloseBtn ? <Cancel /> : <Close />}
            </IconButton>
          </div>
        )
      }
      <>
        {children}
      </>
    </Dialog>
  );
}
