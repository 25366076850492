import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { RouterPrompt } from 'lib/RouterPrompt';
import SpinButton from 'theme/SpinButton';
import { FetchError } from 'network/Errors';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { useExerciceBackend } from 'network/queries/ExercicesQueries';
import { ChevronLeft } from '@mui/icons-material';
import { Exercice } from 'types/types';

export default function ExercicesManage() {
  const location = useLocation();
  const history = useHistory();
  const { getExercices, createExercice } = useExerciceBackend();
  const { data: exercices } = getExercices();

  const [submitting, setSubmitting] = useState(false);
  const [apiErrors, setApiErrors] = useState({});

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<Exercice>();

  const onSubmit: SubmitHandler<Exercice> = async (
    data: Exercice,
  ) => {
    setSubmitting(true);
    createExercice.mutateAsync(data)
      .then(() => {
        reset({});
      })
      .catch((error: FetchError) => {
        if (error.json_response) {
          setApiErrors(error.json_response);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="page-body">
      <h2 role="presentation" onClick={() => history.goBack()}><ChevronLeft /> {Messages.t('manage.workouts')}</h2>
      {
        exercices && Object.values(exercices)?.map((exercice) => (
          <div key={exercice.id}>
            {exercice.name}
          </div>
        ))
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <RouterPrompt
          whenPageClose={isDirty}
          when={(newPathName) => isDirty && !newPathName.startsWith(location.pathname.split('/').slice(0, -1).join('/'))}
        />
        <Controller
          name="name"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('field.name')}
            />
          )}
        />
        <SpinButton
          editing
          className="submit-button"
          spin={submitting}
          title={Messages.t('formButton.confirm')}
        />
      </form>
    </div>
  );
}
