import React, { useState } from 'react';
import {
  Controller,
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';
import { useRecurrentMealsBackend } from 'network/queries/RecurrentMealQueries';
import {
  RecurrentMeal,
  MealIcon,
} from 'types/types';
import SelectWrapper from 'lib/form/SelectWrapper';
import { getEnumValue } from 'lib/type/enum';

type Props = {
  recurrentMeal?: RecurrentMeal,
};
export default function RecurrentMealForm({ recurrentMeal }: Props) {
  const { createRecurrentMeal, updateRecurrentMeal } = useRecurrentMealsBackend();
  const [apiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RecurrentMeal>({
    defaultValues: recurrentMeal,
  });

  const onSubmit: SubmitHandler<RecurrentMeal> = async (
    data: RecurrentMeal,
  ) => {
    setSubmitting(true);
    if (recurrentMeal) {
      updateRecurrentMeal.mutateAsync({
        id: recurrentMeal.id,
        data: { name: data.name, icon: data.icon },
      })
        .finally(() => setSubmitting(false));
    } else {
      createRecurrentMeal.mutateAsync(data)
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          rules={{ required: true }}
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              type="text"
              control={controller}
              label={Messages.t('field.name')}
            />
          )}
        />
        <Controller
          name="icon"
          control={control}
          render={(controller) => (
            <SelectWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('field.imageUrl')}
              values={getEnumValue(MealIcon).map((icon) => ({ key: icon, label: icon }))}
            />
          )}
        />
        <SpinButton
          spin={submitting}
        >
          {recurrentMeal ? Messages.t('formButton.edit') : Messages.t('formButton.add')}
        </SpinButton>
      </form>
    </>
  );
}
