// eslint-disable-next-line import/no-extraneous-dependencies
import * as H from 'history';

export type Route = string;
export type PathParamString = string;
export type QueryParamString = string;

interface PathParam {
  label: PathParamString,
  value: string | number,
}

export interface QueryParam {
  label: QueryParamString,
  value?: string | string[],
}

export class Routes {
  public static withPath(url: Route, pathParams: PathParam[]): Route {
    let urlFinal = url;
    pathParams.forEach((pathParam) => {
      const tmpUrlFinal = urlFinal.replace(pathParam.label, pathParam.value.toString());
      if (tmpUrlFinal === urlFinal) {
        // eslint-disable-next-line no-console
        console.warn('Warning: Trying to add a path param that doesn\'t exist on this url, ', url, pathParam.label);
      }
      urlFinal = tmpUrlFinal;
    });
    return urlFinal;
  }

  public static removeParamFromQuery(location: H.Location, queryParam: string): string {
    const url = `${location.pathname}`;
    const params: Record<string, string> = {};
    const paramObj = new URLSearchParams(location.search);
    paramObj.delete(queryParam);
    for (const entry of paramObj.entries()) {
      // eslint-disable-next-line prefer-destructuring
      params[entry[0]] = entry[1];
    }
    return Routes.updateUrlWithObject(url, params);
  }

  public static updatePathWithQuery(location: H.Location, queryParams: QueryParam[]): string {
    const url = `${location.pathname}`;
    const params: Record<string, string | string[]> = {};
    for (const entry of new URLSearchParams(location.search).entries()) {
      // eslint-disable-next-line prefer-destructuring
      params[entry[0]] = entry[1];
    }
    for (const queryParam of queryParams) {
      if (queryParam.value) {
        params[queryParam.label] = queryParam.value;
      }
    }
    return Routes.updateUrlWithObject(url, params);
  }

  public static updateUrlWithQuery(url: string, queryParams: QueryParam[]): string {
    const params: Record<string, string | string[]> = {};
    for (const queryParam of queryParams) {
      if (queryParam.value) {
        params[queryParam.label] = queryParam.value;
      }
    }
    return Routes.updateUrlWithObject(url, params);
  }

  public static retrieveInPath(url: string, pathParam: string, partialPath: string): string {
    const arr = partialPath.split(pathParam);
    const urlPartial = url.replace(arr[0], '');
    return urlPartial.split('/')[0];
  }

  public static locationMatchWithPath(location: H.Location, path: string): boolean {
    const pathArr = path.split('/');
    const locationArr = location.pathname.split('/');
    if (pathArr.length !== locationArr.length) {
      return false;
    }
    return pathArr.every((str, index) => {
      if (str.startsWith(':')) {
        return true;
      }
      return str === locationArr[index];
    });
  }

  private static updateUrlWithObject(url: string, params: { [key: string]: string | string[] }) {
    const queryParamsString = Object.keys(params)
      .map((key) => (
        typeof params[key] === 'string'
          ? ({ key, val: params[key] })
          : (params[key] as string[]).map((val) => ({
            key,
            val,
          }))))
      .flat()
      .map(({ key, val }) => `${key}=${val}`)
      .join('&');

    return `${url}${queryParamsString ? '?' : ''}${queryParamsString}`;
  }
}
