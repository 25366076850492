// file = Html5QrcodePlugin.jsx
import React, {
  useEffect,
  useState,
} from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { Html5QrcodeScannerConfig } from 'html5-qrcode/es2015/html5-qrcode-scanner';
import {
  QrcodeErrorCallback,
  QrcodeSuccessCallback,
} from 'html5-qrcode/es2015';

const qrcodeRegionId = 'html5qr-code-full-region';

const createConfig = (props: Partial<Html5QrcodeScannerConfig>) => {
  const config: Html5QrcodeScannerConfig = {
    fps: 10,
  };
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  return config;
};
type Props = {
  fps?: number,
  qrbox?: number
  aspectRatio?: number,
  disableFlip?: boolean,
  qrCodeSuccessCallback: QrcodeSuccessCallback,
  qrCodeErrorCallback?: QrcodeErrorCallback,
  verbose?: boolean,
  pause?: boolean,
};

export default function BarCodeDetector(
  {
    fps,
    qrbox,
    aspectRatio,
    disableFlip,
    qrCodeSuccessCallback,
    qrCodeErrorCallback,
    verbose,
    pause,
  }: Props,
) {
  const [qrReader, setqrReader] = useState<Html5QrcodeScanner>();

  useEffect(() => {
    // when component mounts
    const config = createConfig({
      fps,
      qrbox,
      aspectRatio,
      disableFlip,
    });
    // Suceess callback is required.

    const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
    html5QrcodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback);
    // cleanup function when component will unmount
    setqrReader(html5QrcodeScanner);
    return () => {
      html5QrcodeScanner.clear().catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Failed to clear html5QrcodeScanner. ', error);
      });
    };
  }, []);
  useEffect(() => {
    if (qrReader) {
      qrReader.pause(pause);
    }
  }, [pause]);

  return (
    <div id={qrcodeRegionId} />
  );
}
