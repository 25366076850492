import React, {
  useState,
  useRef,
} from 'react';
import Messages from 'services/i18n/Messages';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  ChevronLeft,
  QrCodeScanner,
} from '@mui/icons-material';

import { useFoodReferenceBackend } from 'network/queries/FoodReferenceQueries';
import InfiniteScroll from 'lib/InfiniteScroll';
import FoodCard from 'pages/swole-app/manage/foods/FoodCard';
import FiltersUtils from 'services/filters/FiltersUtils';
import { Routes } from 'routes/RoutesUtils';
import Button from 'theme/Button';
import BarcodeReaderModal from 'pages/swole-app/manage/foods/BarcodeReaderModal';
import {
  MANAGE_FOODS_PAGE,
  ID,
} from 'routes/Routes';
import FoodFilters, { FoodSearchFilter } from 'pages/swole-app/manage/foods/FoodFilters';
import { OpenFoodReference } from 'types/types';

export default function Foods() {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultFilter = FiltersUtils.getObjectFromQueryParam<FoodSearchFilter>({
    search: '',
    brand: [],
    type: [],
    group1: [],
    group2: [],
  }, queryParams);
  const [openBarcodeReader, setOpenBarcodeReader] = useState(false);

  const { getFoodReferenceByName } = useFoodReferenceBackend();

  const {
    data: foodPages,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = getFoodReferenceByName(
    defaultFilter,
    (!!defaultFilter.search && defaultFilter.search.length > 2)
    || defaultFilter.brand?.length > 0
    || defaultFilter.type?.length > 0
    || defaultFilter.group1?.length > 0
    || defaultFilter.group2?.length > 0,
  );

  const foods: OpenFoodReference[] | undefined = foodPages?.pages
    .map((result) => result.data || [] as OpenFoodReference[])?.flat();

  const scrollableRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="page-body">
      <h2 role="presentation" onClick={() => history.goBack()}><ChevronLeft /> {Messages.t('manage.meal')}</h2>
      <Button variant="icon" roundedIcon onClick={() => setOpenBarcodeReader(true)}>
        <QrCodeScanner />
      </Button>
      {
        openBarcodeReader && (
          <BarcodeReaderModal
            onValidate={(id) => history.push(
              Routes.withPath(MANAGE_FOODS_PAGE, [{ label: ID, value: id }]),
            )}
            onClose={() => setOpenBarcodeReader(false)}
          />
        )
      }
      <div className="food-list" ref={scrollableRef}>
        <div>
          <FoodFilters />
        </div>
        <div className="food-list-container">
          {
            foods && foods.length > 0 && (
              <InfiniteScroll
                isLoading={isLoading || isFetchingNextPage}
                next={fetchNextPage}
                size={foods.length}
                hasNext={!!hasNextPage}
                scrollableDivRef={scrollableRef}
                childClassName="food-card-container"
              >
                {
                  foods && foods
                    .map((food) => (
                      <FoodCard key={food.id} food={food} />
                    ))
                }
              </InfiniteScroll>
            )
          }
        </div>
      </div>
    </div>
  );
}
