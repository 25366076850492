import React, {
  MouseEvent,
  ReactNode,
} from 'react';
import { Link } from 'react-router-dom';
import {
  Button as MUIButton,
  IconButton,
} from '@mui/material';

type Props = {
  type?: 'submit'
  variant?: 'text' | 'outlined' | 'contained' | 'icon'
  roundedIcon?: boolean,
  children: ReactNode
  color?: 'inherit' | 'primary' | 'secondary' | 'third' | 'fourth' | 'success' | 'error' | 'info' | 'warning'
  className?: string
  preventDefault?: boolean
  startIcon?: ReactNode,
  endIcon?: ReactNode,
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean,
  to?: string,
};

export default function Button(
  {
    color,
    variant,
    children,
    roundedIcon,
    onClick,
    startIcon,
    endIcon,
    className,
    preventDefault,
    disabled,
    type,
    to,
  }: Props,
) {
  const getContent = () => {
    if (variant === 'icon') {
      return (
        <IconButton
          type={type}
          className={`mui-button ${className} ${roundedIcon ? 'rounded-button' : ''}`}
          onClick={(e) => {
            if (!to) {
              if (preventDefault) {
                e.stopPropagation();
                e.preventDefault();
              }
              e.stopPropagation();
              e.preventDefault();
              if (onClick) {
                onClick(e);
              }
            }
          }}
        >
          {children}
        </IconButton>
      );
    }
    return (
      <MUIButton
        type={type}
        disabled={disabled}
        className={`mui-button ${className} ${color} ${roundedIcon ? 'rounded-button' : ''}`}
        color={(color === 'third' || color === 'fourth') ? 'secondary' : color || 'primary'}
        startIcon={startIcon}
        endIcon={endIcon}
        variant={variant || 'contained'}
        onClick={(e) => {
          if (!to) {
            if (preventDefault) {
              e.preventDefault();
            }
            if (onClick) {
              onClick(e);
            }
          }
        }}
      >
        {children}
      </MUIButton>
    );
  };
  if (to) {
    return <Link to={to}>{getContent()}</Link>;
  }
  return getContent();
}
