import Messages from 'services/i18n/Messages';

export class UnitsService {
  public static getWeightLabel(weightInKg?: number): string {
    if (!weightInKg) {
      return Messages.t('global.unknown');
    }
    return `${weightInKg} Kg`;
  }
}
