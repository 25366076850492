import React from 'react';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';
import { detectMobile } from 'services/utils/utils';

type Props = {
  label?: string,
  icon: React.ReactNode
  path: string
  exact?: boolean
  matchingPath?: string
  disableActiveStyle?: boolean
};

function NavItem(
  {
    label,
    icon,
    path,
    exact,
    matchingPath,
    disableActiveStyle,
  }: Props,
) {
  const isMobile = detectMobile();
  const location = useLocation();
  const matchPath = exact
    ? (matchingPath || path) === location.pathname
    : location.pathname.startsWith(matchingPath || path);
  return (
    <Link to={path} className={`nav-item ${matchPath && !disableActiveStyle ? 'active' : ''}`}>
      {icon}
      {
        label && (
          <span>{label}</span>
        )
      }
      {
        matchPath && !isMobile && <ChevronRight />
      }
    </Link>
  );
}

export default NavItem;
