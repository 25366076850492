import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import { ToastContainer } from 'react-toastify';
import { FirebaseAppProvider } from 'reactfire';
import { fr } from 'date-fns/locale';
import Messages from 'services/i18n/Messages';
import frTranslation from 'services/i18n/fr';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-phone-number-input/style.css';

import 'scss/app.scss';

import { BrowserRouter as Router } from 'react-router-dom';
import {
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCgWiu_2gGLhXeYPQvogOf6BoW0_nK0ai8',
  authDomain: 'swole-eca36.firebaseapp.com',
  projectId: 'swole-eca36',
  storageBucket: 'swole-eca36.appspot.com',
  messagingSenderId: '437306105801',
  appId: '1:437306105801:web:188aa9d9d57a3fecef5218',
};

Messages.setAvailableTranslation([frTranslation]);

const queryClient = new QueryClient();

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '::before': {
            display: 'none',
          },
          '::after': {
            display: 'none',
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'filled',
      },
    },
  },
  palette: {
    primary: {
      light: '#3d3d3d',
      main: '#373737',
      dark: '#313131',
    },
    secondary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#f5f5f5',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Router
      getUserConfirmation={() => {
        /* Empty callback to block the default browser prompt */
      }}
    >
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
          <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <QueryClientProvider client={queryClient}>
              <ToastContainer />
              <App />
            </QueryClientProvider>
          </FirebaseAppProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
