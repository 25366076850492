import React from 'react';
import { useParams } from 'react-router-dom';
import { useWorkoutBackend } from 'network/queries/WorkoutQueries';
import WorkoutDetails from 'pages/swole-app/workouts/workout/WorkoutDetails';

type Param = {
  id: string,
};
export default function WorkoutContainer() {
  const { id } = useParams<Param>();

  const workoutQueries = useWorkoutBackend();
  const { getWorkout } = workoutQueries;
  const { data: workout, isFetching } = getWorkout(id);

  if (workout) {
    return <WorkoutDetails isFetching={isFetching} workout={workout} />;
  }
  return (<></>);
}
