import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessions/sessionManager';
import { FetchError } from 'network/Errors';
import {
  UserInformations,
  UserDailyNeed,
} from 'types/types';

export function useUserInformationsBackend() {
  const queryClient = useQueryClient();

  const GetUserInformations = (enabled = true) => useQuery<UserInformations>(
    ['getUserInformation', sessionManager.getSession()?.user_id],
    async () => {
      const response: UserInformations = await backend.fetchJson(
        '/api/users/informations',
      );
      return response;
    },
    { enabled },
  );

  const GetUserDailyNeed = (enabled = true) => useQuery<UserDailyNeed>(
    ['getUserDailyNeed', sessionManager.getSession()?.user_id],
    async () => {
      const response: UserDailyNeed = await backend.fetchJson(
        '/api/users/daily-needs',
      );
      return response;
    },
    { enabled },
  );

  const UpdateUserInformations = useMutation<UserInformations, FetchError, {
    data: Partial<UserInformations>,
  }>(
    async (
      {
        data,
      },
    ) => {
      const response = await backend.patch('/api/users/informations', data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['GetUserWeightHistory', sessionManager.getSession()?.user_id]);
        queryClient.invalidateQueries(['getUserDailyNeed', sessionManager.getSession()?.user_id]);
        queryClient.refetchQueries(['getUserInformation', sessionManager.getSession()?.user_id]);
      },
    },
  );

  return {
    getUserDailyNeed: GetUserDailyNeed,
    getUserInformations: GetUserInformations,
    updateUserInformations: UpdateUserInformations,
  };
}
