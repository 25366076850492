import backend from 'network/BackendFetchAdapter';
import {
  FoodBrand,
  FoodType,
  FoodGroup1,
  FoodGroup2,
  OpenFoodReference,
} from 'types/types';

export const foodReferenceApi = {
  searchfoodBrand: (search: string): Promise<FoodBrand[]> => backend.fetchJson(`/api/food-references/food-brand?name=${search}`),
  searchfoodType: (search: string): Promise<FoodType[]> => backend.fetchJson(`/api/food-references/food-type?name=${search}`),
  searchfoodGroup1: (search: string): Promise<FoodGroup1[]> => backend.fetchJson(`/api/food-references/food-group1?name=${search}`),
  searchfoodGroup2: (search: string): Promise<FoodGroup2[]> => backend.fetchJson(`/api/food-references/food-group2?name=${search}`),
  getFoodByCode: (code: string): Promise<OpenFoodReference> => backend.fetchJson(`/api/food-references/code/${code}`),
};
