import React, { ReactNode } from 'react';
import {
  ToolbarProps,
  Views,
} from 'react-big-calendar';
import {
  ChevronLeft,
  ChevronRight,
  List,
  CalendarViewMonth,
} from '@mui/icons-material';
import Button from 'theme/Button';

interface Props extends ToolbarProps<Event> {
  filter?: ReactNode;
}

export default function CalendarToolbar(
  {
    view,
    onNavigate,
    onView,
    label,
    filter,
  }: Props,
) {
  return (
    <div className="calendar-toolbar">
      <div className="calendar-view-selector">
        <Button variant="icon" onClick={() => onView(Views.AGENDA)}>
          <List />
        </Button>
        <Button variant="icon" onClick={() => onView(Views.MONTH)}>
          <CalendarViewMonth />
        </Button>
        {filter}
      </div>
      {
        view !== Views.AGENDA && (
          <div className="navigation-actions">
            <Button variant="icon" onClick={() => onNavigate('PREV')}>
              <ChevronLeft />
            </Button>
            <div className="toolbar-dates">
              {label}
            </div>
            <Button variant="icon" onClick={() => onNavigate('NEXT')}>
              <ChevronRight />
            </Button>
          </div>
        )
      }
    </div>
  );
}
