import { QueryParam } from 'routes/RoutesUtils';

export default class FiltersUtils {
  public static getQueryParamsFromObject(
    object: { [key: string]: string | string[] | number },
  ): QueryParam[] {
    return Object.keys(object).map((key) => {
      const value = object[key];
      if (!value && value !== '') {
        return null;
      }
      return ({
        label: key,
        value,
      });
    }).flat().filter((param) => param !== null) as QueryParam[];
  }

  public static getObjectFromQueryParam<T extends { [key: string]: string | string[] | number }>(
    mappingObject: T,
    queryParams: URLSearchParams,
  ): T {
    Object.keys(mappingObject).forEach((key) => {
      const value = queryParams.get(key);
      if (value === '') {
        // @ts-ignore
        mappingObject[key] = null;
        return;
      }
      if (!value && value !== '') {
        delete mappingObject[key];
        return;
      }
      if (typeof mappingObject[key] === 'string') {
        // @ts-ignore
        mappingObject[key] = value;
      } else {
        const values = queryParams.getAll(key);
        // @ts-ignore
        mappingObject[key] = values;
      }
    });
    return mappingObject;
  }
}
