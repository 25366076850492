import React, {
  useState,
  MouseEvent,
} from 'react';
import {
  BakeryDining,
  Cake,
  DinnerDining,
  LunchDining,
  RamenDining,
  RestaurantMenu,
  Tapas,
  SoupKitchen,
  SkipNext,
  QuestionMark,
  Add,
} from '@mui/icons-material';
import {
  MissingMealDay,
  MealIcon,
} from 'types/types';
import Button from 'theme/Button';
import { useMealHistoryBackend } from 'network/queries/MealsHistoryQueries';
import confirmationService from 'services/ConfirmationService';
import Messages from 'services/i18n/Messages';
import UnknowMealModal from 'pages/swole-app/manage/missingMeals/UnknowMealModal';
import {
  Menu,
  MenuItem,
} from '@mui/material';
import AddMealModal from 'pages/swole-app/manage/missingMeals/AddMealModal';
import foodBasketService from 'services/FoodBasketService';
import {
  useHistory,
} from 'react-router-dom';
import { MANAGE_FOODS } from 'routes/Routes';

type Props = {
  missingMeal: MissingMealDay,
};

export default function MissingMealTile({ missingMeal }: Props) {
  const history = useHistory();
  const { createUserMeal } = useMealHistoryBackend();
  const [openModal, setOpenModal] = useState(false);
  const [addMealModal, setAddMealModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const getMealIcon = (icon?: MealIcon) => {
    switch (icon) {
      case MealIcon.MealIconBakery:
        return <BakeryDining />;
      case MealIcon.MealIconCake:
        return <Cake />;
      case MealIcon.MealIconDinner:
        return <DinnerDining />;
      case MealIcon.MealIconLunch:
        return <LunchDining />;
      case MealIcon.MealIconRamen:
        return <RamenDining />;
      case MealIcon.MealIconSoupe:
        return <SoupKitchen />;
      case MealIcon.MealIconTapas:
        return <Tapas />;
      default:
        return <RestaurantMenu />;
    }
  };

  const skipMeal = async () => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('meal.skip'),
      message: Messages.t('meal.skip.details'),
    });
    if (isConfirmed) {
      createUserMeal.mutateAsync({
        date: missingMeal.date,
        mealSkipped: true,
        recurrentMealId: missingMeal.id,
      });
    }
  };

  const addMeal = async () => {
    foodBasketService.init(
      [],
      undefined,
      undefined,
      missingMeal.date,
      missingMeal.id,
      missingMeal.name,
    );
    history.push(MANAGE_FOODS);
  };

  return (
    <>
      {
        openModal && (
          <UnknowMealModal missingMeal={missingMeal} onClose={() => setOpenModal(false)} />
        )
      }
      {
        addMealModal && (
          <AddMealModal missingMeal={missingMeal} onClose={() => setOpenModal(false)} />
        )
      }
      <div
        className="activity-tile"
      >
        <div className="tile-icon">{getMealIcon(missingMeal.icon)}</div>
        <div className="activity-data">
          <div>
            {missingMeal.name}
          </div>
        </div>
        <Button variant="icon" onClick={() => setOpenModal(true)}>
          <QuestionMark />
        </Button>
        <Button variant="icon" onClick={skipMeal}>
          <SkipNext />
        </Button>
        <Button variant="icon" onClick={handleClick}>
          <Add />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MenuItem
            onClick={() => setAddMealModal(true)}
          >
            {Messages.t('meal.existing')}
          </MenuItem>
          <MenuItem
            onClick={addMeal}
          >
            {Messages.t('meal.new')}
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}
