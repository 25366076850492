export const ID = ':id';
export const SERIE_ID = ':serieId';
export const ADD = 'add';

export const HOME = '/';
export const ACTIVITIES = '/activities';
export const ACTIVITIES_CREATE = `${ACTIVITIES}/${ADD}`;
export const ACTIVITIES_DETAILS = `${ACTIVITIES}/${ID}`;

export const HISTORY_MEAL = `${ACTIVITIES}/meals`;

export const LOG_IN = '/login';
export const SIGN_IN = '/signin';
export const WORKOUTS = '/workouts';
export const WORKOUT_DETAIL = `${ACTIVITIES}/${ID}`;
export const WORKOUT_DETAIL_SERIES = `${WORKOUT_DETAIL}/series/${SERIE_ID}`;

export const MANAGE = '/manage';

export const PROFILE = '/profile';

export const MANAGE_EXERCICE = '/manage/exercices';
export const MANAGE_WORKOUT = `${MANAGE}/workouts`;
export const MANAGE_MEAL = `${MANAGE}/meal`;
export const MANAGE_FOODS = `${MANAGE}/foods`;

export const MANAGE_MISSING_MEALS = `${MANAGE}/missing-meals`;

export const MANAGE_FOODS_PAGE = `${MANAGE_FOODS}/${ID}`;
export const MANAGE_MEAL_ADD = `${MANAGE_MEAL}/${ADD}`;
export const MANAGE_MEAL_EDIT = `${MANAGE_MEAL}/${ID}`;
export const MANAGE_WORKOUT_ADD = `${MANAGE_WORKOUT}/${ADD}`;
export const MANAGE_WORKOUT_EDIT = `${MANAGE_WORKOUT}/${ID}`;
export const MANAGE_WORKOUT_SERIE_EDIT = `${MANAGE_WORKOUT_EDIT}/series/${SERIE_ID}`;
