import {
  FetchError,
  BackendAuthenticationError,
} from 'network/Errors';
import sessionManager from 'services/sessions/sessionManager';
// eslint-disable-next-line import/no-cycle
import sessionService from 'services/sessions/sessionService';
import { NotificationService } from 'lib/notification';
import Messages from 'services/i18n/Messages';

export default class FetchAdapter {
  // eslint-disable-next-line class-methods-use-this
  async fetch(url: RequestInfo, params?: RequestInit) {
    const response = await window.fetch(url, params);
    if (!response.ok) {
      // eslint-disable-next-line no-console
      console.log(url);
      // eslint-disable-next-line no-console
      console.log(params);
      if ((response.status === 403 || response.status === 401)
        && sessionManager.getRefreshToken()) {
        sessionService.refreshSession(sessionManager.getRefreshToken());
      }
      let jsonResponse: { [key: string]: string[] } | { errorMessage: string[] } | null = null;
      try {
        jsonResponse = await response.json();
      } catch (e) {
        // ignore
      }
      if (response.status === 403) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw new BackendAuthenticationError(jsonResponse);
      } else {
        const { status } = response;
        if (status === 500) {
          NotificationService.notifyError(Messages.t('notifications.error'));
        }
        if (jsonResponse?.errorMessage) {
          NotificationService.notifyError(Messages.getError(jsonResponse?.errorMessage[0]));
        }
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw new FetchError(jsonResponse, response.status);
      }
    }

    return response;
  }

  async fetchJson(url: RequestInfo, params?: RequestInit) {
    const response = await this.fetch(url, params);
    return response.json();
  }
}
