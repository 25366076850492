import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessions/sessionManager';
import { FetchError } from 'network/Errors';
import {
  Activity,
  ActivityCreation,
} from 'types/types';

export type ActivityFilter = {
  dateRange?: string[],
  type?: string[],
  size?: number,
};
const PAGE_SIZE = 20;

export function useActivityBackend() {
  const queryClient = useQueryClient();
  const GetUserActivities = (
    search: ActivityFilter,
    enabled = true,
  ) => useInfiniteQuery<Activity[]>(
    ['GetActivities', JSON.stringify(search), sessionManager.getSession()?.user_id],
    async ({ pageParam = [] }) => {
      const response: Activity[] = await backend.fetchJson(
        '/api/activities',
        {
          size: PAGE_SIZE,
          ...search,
          page: pageParam,
        },
      );
      return response;
    },
    {
      enabled,
      getNextPageParam: (
        lastPage,
        allPages,
      ) => lastPage && lastPage?.length === PAGE_SIZE && allPages?.length,
    },
  );

  const GetUserActivity = (
    activityId: string,
    enabled = true,
  ) => useQuery<Activity>(
    ['GetActivity', activityId, sessionManager.getSession()?.user_id],
    async () => {
      const response: Activity = await backend.fetchJson(
        `/api/activities/${activityId}`,
      );
      return response;
    },
    { enabled },
  );

  const DeleteActivity = useMutation<void, FetchError, { activityId: string }>(
    async ({ activityId }) => {
      const response = await backend.delete(`/api/activities/${activityId}`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['GetActivities', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const CreateActivity = useMutation<Activity, FetchError, ActivityCreation>(
    async (data) => {
      const response = await backend.post('/api/activities', data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['GetActivities']);
      },
    },
  );

  const UpdateActivity = useMutation<Activity, FetchError, { id: string, data: Partial<Activity> }>(
    async ({ id, data }) => {
      const response = await backend.patch(`/api/activities/${id}`, data);
      return response;
    }, {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries(['GetActivities', sessionManager.getSession()?.user_id]);
        queryClient.invalidateQueries(['GetActivity', id, sessionManager.getSession()?.user_id]);
      },
    },
  );

  return {
    getUserActivities: GetUserActivities,
    deleteActivity: DeleteActivity,
    createActivity: CreateActivity,
    getUserActivity: GetUserActivity,
    updateActivity: UpdateActivity,
  };
}
