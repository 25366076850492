import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FetchError } from 'network/Errors';
import ActivityForm from 'pages/swole-app/activities/activity/activityForm/ActivityForm';
import { useActivityBackend } from 'network/queries/ActivityQueries';
import {
  Activity,
  ActivityCreation,
} from 'types/types';

type Props = {
  activity: Activity,
};
export default function ActivityEdit({ activity }: Props) {
  const { updateActivity } = useActivityBackend();

  const [apiErrors, setApiErrors] = useState({});

  const form = useForm<ActivityCreation>({ defaultValues: activity });
  const submitActivity = (data: Partial<Activity>) => {
    updateActivity.mutateAsync({
      id: activity.id,
      data,
    }).catch((error: FetchError) => {
      if (error.json_response) {
        setApiErrors(error.json_response);
      }
    });
  };

  return (
    <form>
      <ActivityForm autoSaveSubmit={submitActivity} form={form} apiErrors={apiErrors} />
    </form>
  );
}
