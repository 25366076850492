import Messages from 'services/i18n/Messages';
import {
  observable,
  WritableObservable,
} from 'micro-observables';
import {
  enUS,
  fr,
} from 'date-fns/esm/locale';

const LANGUAGE_KEY = 'currentLanguage';
type AvailableLanguage = 'fr' | 'en';

class LocalService {
  private readonly dateLocals = { fr, en: enUS };

  private readonly local: WritableObservable<AvailableLanguage> = observable('fr');

  private availableLocal: string[] = [];

  getDateLocal() {
    const local = this.dateLocals[this.local.get()];
    return local || fr;
  }

  getLocal() {
    return this.local.readOnly();
  }

  setAvailableLocal(locals: string[]) {
    this.availableLocal = locals;
  }

  getAvailableLocal() {
    return this.availableLocal;
  }

  // eslint-disable-next-line class-methods-use-this
  getdefaultLocal(): string {
    let currentLocal = localStorage.get(LANGUAGE_KEY);
    if (!currentLocal) {
      currentLocal = document.documentElement.getAttribute('lang');
    }
    return currentLocal;
  }

  toogleLanguage(local: AvailableLanguage) {
    let updateLocal = local;
    if (!this.availableLocal.includes(updateLocal)) {
      updateLocal = 'en';
    } else {
      localStorage.set(LANGUAGE_KEY, local);
    }
    Messages.updateTraductionWithLocal(updateLocal);
    if (document) {
      document.documentElement.setAttribute('lang', updateLocal);
    }
    this.local.set(updateLocal);
  }
}

const localService = new LocalService();
export default localService;
