import React, {
  useCallback,
  useState,
} from 'react';
import firebase from 'firebase/compat/app';
import { useAuth } from 'reactfire';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import 'firebase/compat/auth';
import sessionService from 'services/sessions/sessionService';
import SpinButton from 'theme/SpinButton';
import Messages from 'services/i18n/Messages';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { LogInFormType } from 'types/forms/LogInFormType';
import { authentificationApi } from 'network/api/AuthentificationApi';
import { NotificationService } from 'lib/notification';
import { Link } from 'react-router-dom';
import { SIGN_IN } from 'routes/Routes';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as firebaseui from 'firebaseui';

export default function LogInForm() {
  const auth = useAuth();
  const [ssoConnection, setSsoConnection] = useState(false);
  const [mailError, setMailError] = useState('');
  const [tokenError] = useState('');

  const [pwdError, setPwdError] = useState('');

  const uiCallback = useCallback((ui) => {
    ui.disableAutoSignIn();
  }, []);

  const uiConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (authResult) => {
        setSubmitting(true);
        sessionService.getSessionFromFirebase(authResult.user.accessToken).catch(() => {
          setMailError(Messages.t('error.invalidMail'));
        }).finally(() => setSubmitting(false));
        return false;
      },
    },
  };
  const [apiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<LogInFormType>();

  const formField = watch();
  const onSubmit: SubmitHandler<LogInFormType> = (formData: LogInFormType) => {
    setSubmitting(true);
    if (ssoConnection) {
      authentificationApi.recoverToken(formField)
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.mailSend'));
        })
        .catch(() => NotificationService.notifyError(Messages.t('notifications.error')))
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      sessionService.logIn(formData).catch(() => setPwdError(Messages.t('error.passwordInvalid'))).finally(() => {
        setSubmitting(false);
      });
    }
  };

  return (
    <div className="login-page">
      <h1>{Messages.t('application.name')}</h1>
      <div className="login-card">
        <form className="signed-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="mail-error">{tokenError}</div>
          <Controller
            name="mail"
            rules={{ required: true }}
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="text"
                control={controller}
                label={Messages.t('field.mail')}
              />
            )}
          />
          {
            !ssoConnection && (
              <Controller
                name="password"
                rules={{ required: true }}
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    type="password"
                    control={controller}
                    label={Messages.t('field.password')}
                  />
                )}
              />
            )
          }
          <SpinButton
            editing
            className="connection-button"
            spin={submitting}
            title={
              ssoConnection
                ? Messages.t('formButton.sendConnectionMail')
                : Messages.t('formButton.connect')
            }
          />
          <div className="mail-error">{pwdError}</div>
        </form>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={auth}
          uiCallback={uiCallback}
        />
        <div className="mail-error">{mailError}</div>
        <button
          type="button"
          className="button-as-link"
          disabled={submitting}
          onClick={() => setSsoConnection((prevState) => !prevState)}
        >
          {
            ssoConnection
              ? Messages.t('formButton.passwordConnection')
              : Messages.t('formButton.ssoConnection')
          }
        </button>
        <h5>{Messages.t('generics.or')}</h5>
        <Link className="link-as-button secondary" to={SIGN_IN}>{Messages.t('formButton.createAccount')}</Link>
      </div>
    </div>
  );
}
