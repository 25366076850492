import React from 'react';
import {
  MANAGE_EXERCICE,
  MANAGE_MEAL,
  MANAGE_WORKOUT,
  MANAGE_FOODS,
  MANAGE_MISSING_MEALS,
} from 'routes/Routes';
import { Link } from 'react-router-dom';
import Messages from 'services/i18n/Messages';
import Header from 'pages/common/Header';
import {
  DirectionsRun,
  FitnessCenter,
  RestaurantMenu,
  Fastfood,
  PlaylistAddCheck,
} from '@mui/icons-material';

export default function Manage() {
  return (
    <>
      <Header hideBackButton label={Messages.t('manage.title')} />
      <div className="page-body">
        <div className="tiles-container">
          <Link to={MANAGE_WORKOUT} className="link-tiles">
            <div className="tile-icon green"><DirectionsRun /></div>
            <div>{Messages.t('manage.workouts')}</div>
          </Link>
          <Link to={MANAGE_EXERCICE} className="link-tiles">
            <div className="tile-icon red"><FitnessCenter /></div>
            <div> {Messages.t('manage.exercices')}</div>
          </Link>
          <Link to={MANAGE_MEAL} className="link-tiles">
            <div className="tile-icon purple"><RestaurantMenu /></div>
            <div>{Messages.t('manage.meal')}</div>
          </Link>
          <Link to={MANAGE_FOODS} className="link-tiles">
            <div className="tile-icon yellow"><Fastfood /></div>
            <div>{Messages.t('manage.foods')}</div>
          </Link>
          <Link to={MANAGE_MISSING_MEALS} className="link-tiles">
            <div className="tile-icon pink"><PlaylistAddCheck /></div>
            <div>{Messages.t('manage.missingMeals')}</div>
          </Link>
          <div className="link-filler" />
        </div>
      </div>
    </>
  );
}
