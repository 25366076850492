import { nullOrUndefined } from 'services/utils/utils';

export default class JsonUtils {
  public static cleanJson<T>(object: T) {
    if (nullOrUndefined(object)) {
      return object;
    }
    Object.keys(object).forEach((key) => {
      // @ts-ignore
      if (typeof object[key] === 'boolean') {
        return;
      }
      // @ts-ignore
      const number = Number(object[key] as any);
      if (Number.isInteger(number)) {
        // @ts-ignore
        object[key] = number;
      }
    });
    return object;
  }
}
