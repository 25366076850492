import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessions/sessionManager';
import { FetchError } from 'network/Errors';
import {
  MealDetail,
  UserMealTemplate,
  UserMealFood,
  MealTemplateDetail,
} from 'types/types';

export function useMealBackend() {
  const queryClient = useQueryClient();

  const GetUserMealsDetails = (mealId: string, enabled = true) => useQuery<MealDetail>(
    ['GetUserMealsDetails', mealId, sessionManager.getSession()?.user_id],
    async () => {
      const response: MealDetail = await backend.fetchJson(
        `/api/meals/${mealId}`,
      );
      return response;
    },
    { enabled },
  );

  const GetUserMeals = (enabled = true) => useQuery<UserMealTemplate[]>(
    ['GetUserMeals', sessionManager.getSession()?.user_id],
    async () => {
      const response: UserMealTemplate[] = await backend.fetchJson(
        '/api/meals',
      );
      return response;
    },
    { enabled },
  );

  const DeleteUserMeal = useMutation<UserMealFood, FetchError, {
    mealId: string,
  }>(
    async (
      {
        mealId,
      },
    ) => {
      const response = await backend.delete(`/api/meals/${mealId}`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['GetUserMeals', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const UpdateUserMeal = useMutation<UserMealTemplate, FetchError, Partial<MealTemplateDetail>>(
    async (data) => {
      const response = await backend.put(`/api/meals/${data.id}`, data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['GetUserMeals', sessionManager.getSession()?.user_id]);
      },
    },
  );
  return {
    getUserMeals: GetUserMeals,
    getUserMealsDetails: GetUserMealsDetails,
    deleteUserMeal: DeleteUserMeal,
    updateUserMeal: UpdateUserMeal,
  };
}
