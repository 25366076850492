/* eslint-disable react/jsx-first-prop-new-line, react/jsx-max-props-per-line */
import React, {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

type Props = {
  children: ReactNode,
  size: number,
  hasNext: boolean
  next: () => void,
  scrollableDivRef?: MutableRefObject<HTMLDivElement | null>,
  childClassName?: string,
  isLoading: boolean
};

export default function InfiniteScroll(
  {
    children,
    next,
    size,
    hasNext,
    scrollableDivRef,
    childClassName,
    isLoading,
  }: Props,
) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [isLoadingState, _setIsLoading] = useState(false);

  const lastEltRef = React.useRef(null);
  const isLoadingRef = React.useRef(isLoadingState);
  const hasNextRef = React.useRef(hasNext);
  hasNextRef.current = hasNext;
  const setIsLoading = (data: any) => {
    isLoadingRef.current = data;
    _setIsLoading(data);
  };
  const setLastElt = (data: any) => {
    lastEltRef.current = data;
  };

  useEffect(() => {
    if (!isLoading) {
      if (isLoadingRef.current && lastEltRef.current) {
        setIsLoading(false);
        handleScroll();
      }

      if (size === 0 && hasNext) {
        setIsLoading(true);
        next();
      }
    }
  }, [isLoading, size, hasNext]);

  const handleScroll = () => {
    const current = lastEltRef.current as HTMLDivElement | null;
    const boundingClientRect = current?.getBoundingClientRect();
    checkIfNeedUpdate(boundingClientRect);
  };

  useEffect(() => {
    if (scrollableDivRef && scrollableDivRef.current) {
      scrollableDivRef.current.addEventListener('scroll', handleScroll);
    } else {
      document.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollableDivRef && scrollableDivRef.current) {
        scrollableDivRef.current.removeEventListener('scroll', handleScroll);
      } else {
        document.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollableDivRef?.current]);

  const checkIfNeedUpdate = (boundingClientRect: DOMRect | undefined) => {
    const containerTop = (scrollableDivRef?.current?.getBoundingClientRect().top || 0)
      + (scrollableDivRef?.current?.getBoundingClientRect().height || 0);
    const top = (boundingClientRect?.top || 0)
      - (containerTop || 0)
      + (boundingClientRect?.height || 0);
    if (boundingClientRect
      && (top < (boundingClientRect.height * 5))
      && hasNextRef.current
      && !isLoadingRef.current) {
      setIsLoading(true);
      next();
    }
  };

  const lastEltRefCallback = useCallback((node: HTMLDivElement | null) => {
    setLastElt(node);
    const boundingClientRect = node?.getBoundingClientRect();
    checkIfNeedUpdate(boundingClientRect);
  }, []);

  const childrens = React.Children.toArray(children);
  return (
    <>
      {
        childrens.map((child, index) => (
          // Key exist on child but is missing in the type
          <div
            // @ts-ignore
            key={child.key}
            className={childClassName || ''}
            ref={index === childrens.length - 1 ? lastEltRefCallback : null}
          >
            {child}
          </div>
        ))
      }
    </>
  );
}
