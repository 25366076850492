import React, { useState } from 'react';
import WorkoutExercices from 'pages/swole-app/workouts/workout/exercices/WorkoutExercices';
import ExpendableContainer from 'lib/ExpendableContainer';
import { useExerciceBackend } from 'network/queries/ExercicesQueries';
import Messages from 'services/i18n/Messages';
import {
  Controller,
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import SelectWrapper from 'lib/form/SelectWrapper';
import SpinButton from 'theme/SpinButton';
import Button from 'theme/Button';
import { useWorkoutBackend } from 'network/queries/WorkoutQueries';
import { FetchError } from 'network/Errors';
import { WORKOUTS } from 'routes/Routes';
import { useHistory } from 'react-router-dom';
import { Delete } from '@mui/icons-material';
import confirmationService from 'services/ConfirmationService';
import { NotificationService } from 'lib/notification';
import SortableList from 'lib/SortableList';
import {
  WorkoutTemplateSerie,
  WorkoutSerieDetailled,
  WorkoutDetailled,
} from 'types/types';

type Props = {
  workout: WorkoutDetailled,
  isFetching: boolean,
};

// @ts-ignore
export default function WorkoutDetails({ workout, isFetching }: Props) {
  const { getExercices } = useExerciceBackend();
  const history = useHistory();
  const { data: exercices } = getExercices();
  const {
    endWorkout,
    createWorkoutSerie,
    deleteWorkoutSerie,
    updateWorkoutSerieOrder,
  } = useWorkoutBackend();

  const [submitting, setSubmitting] = useState(false);

  const [apiErrors, setApiErrors] = useState({});
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<WorkoutTemplateSerie>();

  const onSubmit: SubmitHandler<WorkoutTemplateSerie> = async (data: WorkoutTemplateSerie) => {
    setSubmitting(true);
    data.order = (workout.series?.length || 0) + 1;
    createWorkoutSerie.mutateAsync({ data, workoutId: workout.id })
      .then(() => {
        reset({});
      })
      .catch((error: FetchError) => {
        if (error.json_response) {
          setApiErrors(error.json_response);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const changeOrder = async (data: WorkoutSerieDetailled[]) => updateWorkoutSerieOrder
    .mutateAsync({
      data: data.map((serie) => ({
        id: serie.id,
        order: serie.order,
      })),
      workoutId: workout.id,
    });

  const endWorkoutSession = () => {
    setSubmitting(false);
    endWorkout.mutateAsync({ workoutId: workout.id }).then(() => {
      history.push(WORKOUTS);
    }).finally(() => setSubmitting(false));
  };

  const deleteSerie = async (workoutId: string, serieId: string) => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('serie.delete'),
      message: Messages.t('global.delete'),
    });
    if (isConfirmed) {
      setSubmitting(true);
      deleteWorkoutSerie.mutateAsync({ workoutId, serieId })
        .then(() => NotificationService.notifySuccess(Messages.t('notifications.delete')))
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <div>
      {
        workout.series && (
          <SortableList
            list={workout.series}
            renderItem={(serie) => (
              <ExpendableContainer
                showDragHandle
                isFetching={isFetching}
                defaultHidden
                label={exercices?.[serie.exerciceID]?.name || ''}
                action={(
                  <Button
                    variant="icon"
                    disabled={submitting}
                    onClick={(e) => {
                      e.preventDefault();
                      deleteSerie(workout.id, serie.id);
                    }}
                  >
                    <Delete />
                  </Button>
                )}
              >
                <WorkoutExercices serie={serie} />
              </ExpendableContainer>
            )}
            onChange={changeOrder}
          />
        )
      }
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h5>{Messages.t('field.name')}</h5>
          {
            exercices && (
              <Controller
                name="exerciceID"
                control={control}
                render={(controller) => (
                  <SelectWrapper
                    apiErrors={apiErrors}
                    error={errors}
                    control={controller}
                    values={Object.values(exercices)
                      .map((exercice) => ({ key: exercice.id, label: exercice.name }))}
                    label={Messages.t('field.name')}
                  />
                )}
              />
            )
          }
          <SpinButton
            editing
            className="submit-button"
            spin={submitting}
            title={Messages.t('formButton.confirm')}
          />
        </form>
      </div>
      <Button disabled={submitting} onClick={endWorkoutSession}>FIN</Button>
    </div>
  );
}
