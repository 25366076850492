import React from 'react';
import {
  ACTIVITIES,
  HOME,
  MANAGE,
  PROFILE,
  WORKOUTS,
} from 'routes/Routes';
import Home from 'pages/swole-app/home/Home';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import WorkoutRouter from 'pages/swole-app/workouts/WorkoutRouter';
import ManageRouter from 'pages/swole-app/manage/ManageRouter';
import ProfilePage from 'pages/swole-app/profile/ProfilePage';
import ActivitiesRouter from 'pages/swole-app/activities/ActivitiesRouter';

export default function UserRouter() {
  return (
    <Switch>
      <Route exact path={HOME} component={Home} />
      <Route path={ACTIVITIES} component={ActivitiesRouter} />
      <Route path={WORKOUTS} component={WorkoutRouter} />
      <Route path={MANAGE} component={ManageRouter} />
      <Route path={PROFILE} component={ProfilePage} />
      <Route render={() => <Redirect to={HOME} />} />
    </Switch>
  );
}
