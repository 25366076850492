import React, { ReactNode } from 'react';
import {
  DragDropContext,
  Droppable,
  DropResult,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';

export type Sortable = { id: string, order: number };

type Props<T extends Sortable> = {
  list: T[],
  renderItem: (item: T) => ReactNode
  onChange: (items: T[]) => void
};

function reorder<T extends Sortable>(
  list: T[],
  startIndex: number,
  endIndex: number,
): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.forEach((elt, index) => {
    elt.order = index;
  });
  return result;
}

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // styles we need to apply on draggables
  ...draggableStyle,
});

export default function SortableList<T extends Sortable>(
  {
    list,
    renderItem,
    onChange,
  }: Props<T>,
) {
  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const orderedItem = reorder(
      list,
      result.source.index,
      result.destination.index,
    );

    onChange(orderedItem);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(providedDropable) => (
          <div
            {...providedDropable.droppableProps}
            ref={providedDropable.innerRef}
          >
            {
              list.sort((a, b) => a.order - b.order).map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className="serie-container"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      // @ts-ignore
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      {renderItem(item)}
                    </div>
                  )}
                </Draggable>
              ))
            }
            {providedDropable.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
