import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
} from 'react-hook-form/dist/types/controller';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import {
  Autocomplete,
  TextField,
  Checkbox,
} from '@mui/material';
import {
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
} from '@mui/icons-material';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import Messages from 'services/i18n/Messages';

type Props<T> = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  error?: FieldErrors,
  label: string,
  placeholder: string,
  multiple?: boolean,
  apiErrors?: { [key: string]: string[] }
  getPropositions: (value: string) => Promise<T[]>
  getSelectedLabels?: (value: string) => Promise<T>
};
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxOutlined fontSize="small" />;
export default function AutoCompleteWrapper<T extends { id: string, name: string }>(
  {
    control,
    error,
    apiErrors,
    label,
    placeholder,
    getPropositions,
    getSelectedLabels,
    multiple,
  }: Props<T>,
) {
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [elements, setElements] = useState<T[]>([]);
  //
  // eslint-disable-next-line no-undef
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const clearTimer = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
    }
  }, [timer]);

  useEffect(() => clearTimer,
    []);
  const { field } = control;
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);

  const value = multiple ? field.value?.map((elt: string) => ({ name: elt })) : field.value;
  return (
    <div className="material-select-wrapper">
      <Autocomplete
        {...field}
        multiple={multiple}
        value={value || (multiple ? [] : null)}
        disableCloseOnSelect
        onChange={(e, val) => {
          if (!val) {
            field.onChange(null);
          } else if (getSelectedLabels) {
            if (multiple) {
              field.onChange(val?.map((elt: T) => elt.id));
            } else {
              field.onChange(val?.id);
            }
          } else if (multiple) {
            field.onChange(val?.map((elt: T) => elt.name));
          } else {
            field.onChange(val?.name);
          }
        }}
        isOptionEqualToValue={(option, v) => option.name === v.name}
        loading={loading}
        limitTags={2}
        id="multiple-limit-tags"
        filterOptions={(options) => options}
        options={elements}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name || Messages.t('field.empty')}
          </li>
        )}
        defaultValue={[]}
        onInputChange={(e, v, reason) => {
          if (reason === 'input') {
            clearTimer();
            setSearchValue(v);
            setTimer(setTimeout(() => {
              setLoading(true);
              getPropositions(v).then((elts) => {
                if (!elts) {
                  setElements([]);
                } else {
                  setElements(elts);
                }
                setLoading(false);
              });
            }, 500));
          }
        }}
        inputValue={searchValue}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            error={!!errorMessage}
            helperText={errorMessage}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        )}
      />
    </div>
  );
}
