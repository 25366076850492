import React, {
  useState,
  ReactNode,
} from 'react';

type Props = {
  src?: string
  alt: string
  alternativElement?: ReactNode
  imagePlaceHolder?: string
};

export default function Image(
  {
    src,
    alt,
    alternativElement,
    imagePlaceHolder,
  }: Props,
) {
  const [hasError, setHasError] = useState(false);
  if (hasError || !src) {
    if (alternativElement) {
      return <>{alternativElement}</>;
    }
    return (<img src={imagePlaceHolder} alt={alt} />);
  }
  return (
    <img onError={() => setHasError(true)} src={src} alt={alt} />
  );
}
