import {
  observable,
  WritableObservable,
} from 'micro-observables';
import { Enum } from 'lib/type/enum';
import { OpenFoodReference } from 'types/types';

export type FoodBasketType = 'portion' | 'quantity';

export const foodBasketTypeEnum: Enum<FoodBasketType> = {
  portion: 'portion',
  quantity: 'quantity',
};

export type FoodBasket = {
  food: OpenFoodReference,
  portion?: number,
  quantity?: number,
  type: FoodBasketType,
};

export type FoodBasketContainer = {
  basket?: FoodBasket[],
  originalFoodBasket?: FoodBasket[],
  originalId?: string,
  originalName?: string,
  fixDate?: string,
  fixRecurrentMealId?: string,
  recurrentMealName?: string,
};
const BASKET_KEY = 'BASKET_KEY';

class FoodBasketService {
  private readonly basket: WritableObservable<FoodBasketContainer> = observable(
    FoodBasketService.initialValue(),
  );

  private static initialValue() {
    const strObj = localStorage.getItem(BASKET_KEY);
    if (!strObj) {
      return {};
    }
    const obj = JSON.parse(strObj);
    return obj;
  }

  // eslint-disable-next-line class-methods-use-this
  public isBasketSameWithOriginal(foodBasket: FoodBasketContainer) {
    if (!foodBasket.originalFoodBasket || !foodBasket.basket) {
      return false;
    }
    const basketDict: Record<string, number> = foodBasket.basket
      .reduce((acc, val) => ({ ...acc, [val.food.id]: val.quantity }), {});
    for (let i = 0; i < foodBasket.originalFoodBasket.length; i++) {
      const quantity = basketDict[foodBasket.originalFoodBasket[i].food.id];
      if (!quantity || quantity !== foodBasket.originalFoodBasket[i].quantity) {
        return false;
      }
    }
    return true;
  }

  getBasket() {
    return this.basket.readOnly();
  }

  init(
    foodBasket?: FoodBasket[],
    originalName?: string,
    originalId?: string,
    fixDate?: string,
    fixRecurrentMealId?: string,
    recurrentMealName?: string,
  ) {
    if (!foodBasket) {
      return;
    }
    foodBasket.forEach((food) => {
      food.portion = (food.quantity || 100) / (food.food.servingQuantity || 100);
    });
    this.basket.update(() => {
      localStorage.setItem(BASKET_KEY, JSON.stringify(foodBasket));
      return {
        basket: foodBasket,
        originalFoodBasket: JSON.parse(JSON.stringify(foodBasket)),
        originalName,
        originalId,
        fixDate,
        fixRecurrentMealId,
        recurrentMealName,
      };
    });
  }

  add(foodBasket: FoodBasket) {
    if (foodBasket.type === foodBasketTypeEnum.portion && foodBasket.portion) {
      foodBasket.quantity = (foodBasket.food.servingQuantity || 100) * foodBasket.portion;
    } else {
      foodBasket.portion = (foodBasket.quantity || 100) / (foodBasket.food.servingQuantity || 100);
    }
    this.basket.update((basket) => {
      const currentBasket = basket.basket || [];
      const currentBasketFood = basket
        .basket?.filter((food) => food.food.id === foodBasket.food.id)?.[0];
      if (currentBasketFood) {
        currentBasketFood.quantity = (foodBasket.quantity || 0) + (currentBasketFood.quantity || 0);
        currentBasketFood.portion = (foodBasket.portion || 0) + (currentBasketFood.portion || 0);
        currentBasketFood.type = foodBasket.type;
      } else {
        currentBasket.push(foodBasket);
      }
      localStorage.setItem(BASKET_KEY, JSON.stringify(currentBasket));
      return {
        ...basket,
        basket: currentBasket,
      };
    });
  }

  delete() {
    this.basket.set({});
    localStorage.removeItem(BASKET_KEY);
  }

  update(foodBasket: FoodBasket) {
    if (foodBasket.type === foodBasketTypeEnum.portion && foodBasket.portion) {
      foodBasket.quantity = (foodBasket.food.servingQuantity || 100) * foodBasket.portion;
    } else {
      foodBasket.portion = (foodBasket.quantity || 100) / (foodBasket.food.servingQuantity || 100);
    }
    this.basket.update((currentBasket) => {
      const basketRes = { ...currentBasket };
      if (!basketRes.basket) {
        basketRes.basket = [];
      }
      const currentBasketFood = basketRes
        .basket?.filter((food) => food.food.id === foodBasket.food.id)?.[0];
      if (!!currentBasketFood) {
        currentBasketFood.quantity = foodBasket.quantity;
        currentBasketFood.portion = foodBasket.portion;
        currentBasketFood.type = foodBasket.type;
        if ((currentBasketFood?.portion || 0) <= 0) {
          basketRes.basket = basketRes
            .basket?.filter((food) => food.food.id !== foodBasket.food.id);
        }
      } else {
        basketRes.basket.push(foodBasket);
      }
      localStorage.setItem(BASKET_KEY, JSON.stringify(basketRes));
      return basketRes;
    });
  }
}

const foodBasketService = new FoodBasketService();
export default foodBasketService;
