import React from 'react';
import Messages from 'services/i18n/Messages';
import Header from 'pages/common/Header';
import UserDailyNeedTile from 'pages/swole-app/home/dailyNeed/UserDailyNeedTile';
import Meals from 'pages/swole-app/home/meal/Meals';
import WeightTile from 'pages/swole-app/home/tiles/WeightTile';
import MissingMealsTile from 'pages/swole-app/home/tiles/MissingMealsTile';

export default function Home() {
  return (
    <>
      <Header hideBackButton label={Messages.t('home.welcome')} />
      <div className="page-body">
        <UserDailyNeedTile />
        <Meals />
        <WeightTile />
        <MissingMealsTile />
      </div>
    </>
  );
}
