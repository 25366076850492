import React from 'react';
import NavBar from 'pages/swole-app/layout/NavBar';
import UserRouter from 'pages/swole-app/UserRouter';

function UserRoot() {
  return (
    <div className="swole-root">
      <NavBar />
      <div className="page-content">
        <UserRouter />
      </div>
    </div>
  );
}

export default UserRoot;
