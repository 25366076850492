import React from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
} from 'react-hook-form/dist/types/controller';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import useAutoSave from 'lib/form/autosave';
import { UseFormTrigger } from 'react-hook-form';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import { DatePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  label?: string
  error: FieldErrors,
  apiErrors?: { [key: string]: string[] }
  autoSaveSubmit?: (value: Date | undefined) => void,
  trigger?: UseFormTrigger<any>,
  submitting?: boolean,
};

export default function DatePickerWrapper(
  {
    control,
    label,
    error,
    apiErrors,
    autoSaveSubmit,
    trigger,
    submitting,
  }: Props,
) {
  const { field } = control;
  const {
    // hasSaveResult,
    // getSaveResult,
    handleChange,
  } = useAutoSave(control, autoSaveSubmit, trigger, submitting);

  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);

  const value = field.value || null; // force null instead of undefined
  return (
    <div className="material-textfield-wrapper">
      <DatePicker
        {...field}
        value={parseISO(value)}
        onChange={(val) => {
          handleChange(val || undefined);
        }}
        slotProps={{
          field: { clearable: true },
          textField: {
            error: !!errorMessage,
            helperText: errorMessage,
            fullWidth: true,
          },
        }}
        label={label || undefined}
        // renderInput={(params: any) => (
        //   <TextField
        //     {...params}
        //     error={!!errorMessage}
        //     helperText={errorMessage}
        //     fullWidth
        //     variant="outlined"
        //     InputProps={{
        //       ...params.InputProps,
        //       startAdornment: (hasSaveResult)
        //         && <InputAdornment position="end">{getSaveResult()}</InputAdornment>,
        //     }}
        //   />
      />
    </div>
  );
}
