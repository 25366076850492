import React, { useState } from 'react';
import {
  DialogTitle,
  DialogActions,
} from '@mui/material';
import Messages from 'services/i18n/Messages';
import DialogWrapper from 'lib/DialogWrapper';
import Button from 'theme/Button';
import BarCodeDetector from 'pages/common/BarCodeDetector';
import { foodReferenceApi } from 'network/api/foodReferenceApi';
import { NotificationService } from 'lib/notification';
import FoodCard from 'pages/swole-app/manage/foods/FoodCard';
import { OpenFoodReference } from 'types/types';

type Props = {
  onClose: () => void,
  onValidate: (id: string) => void
  validateLabel?: string;
};

export default function BarcodeReaderModal(
  {
    validateLabel,
    onValidate,
    onClose,
  }: Props,
) {
  const [submitting, setSubmitting] = useState(false);
  const [food, setFood] = useState<OpenFoodReference>();
  const [code, setCode] = useState<string>();

  const onNewScanResult = (decodedText: string) => {
    setCode(decodedText);
    setSubmitting(true);
    foodReferenceApi.getFoodByCode(decodedText)
      .then((product) => setFood(product))
      .catch(() => {
        NotificationService.notifyError(Messages.t('notifications.notFound'));
      }).finally(() => setSubmitting(false));
  };
  return (
    <DialogWrapper open onClose={onClose}>
      <DialogTitle>{Messages.t('food.scanner')}</DialogTitle>
      <div className="bar-code-container">
        {
          code ? (
            <>
              <div>{code}</div>
              {
                food ? <FoodCard food={food} /> : (
                  <>
                    {
                      submitting ? (<div> loading</div>) : (<div>Not found</div>)
                    }
                  </>
                )
              }
              <Button onClick={() => setCode(undefined)}>{Messages.t('food.newScan')}</Button>
            </>
          ) : (
            <BarCodeDetector
              fps={10}
              qrbox={250}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResult}
            />
          )
        }
      </div>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>{Messages.t('global.cancel')}</Button>
        <Button
          disabled={!!food}
          onClick={() => onValidate(food?.id || '')}
        >
          {validateLabel || Messages.t('global.start')}
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
}
