import React from 'react';
import { useObservable } from 'micro-observables';
import foodBasketService from 'services/FoodBasketService';
import Image from 'theme/Image';
import {
  Fastfood,
  DeleteOutlined,
} from '@mui/icons-material';
import Button from 'theme/Button';
import Messages from 'services/i18n/Messages';
import ConfirmFoodBasketModal from 'pages/swole-app/manage/foods/ConfirmFoodBasketModal';
import useQueryParamState from 'lib/hooks/useQueryParamState';
import { SHOW_MEAL_CONFIRM } from 'routes/QueryParams';

export default function FoodBasketWrapper() {
  const [showConfirmModal, setShowConfirmModal] = useQueryParamState(false, SHOW_MEAL_CONFIRM);
  const basket = useObservable(foodBasketService.getBasket());
  if (!basket || !basket.basket || (basket.basket.length === 0 && !basket.recurrentMealName)) {
    return null;
  }

  return (
    <>
      {
        showConfirmModal && basket.basket && (
          <ConfirmFoodBasketModal
            foodBasket={basket}
            onClose={() => setShowConfirmModal(false)}
          />
        )
      }
      <div className="basket-container">
        <div className="basket-content">
          <div className="food-content">
            <div>
              {basket.recurrentMealName}
            </div>
            {
              basket.basket?.slice(0, 2).map((foodBasket) => (
                <div key={foodBasket.food.id} className="food-line">
                  <div className="food-card-image-container">
                    <Image alt="product" src={foodBasket.food.imageSmallURL} alternativElement={<Fastfood />} />
                  </div>
                  <div className="food-card-data">
                    <div className="food-name">
                      {
                        foodBasket.food.productName || foodBasket.food.genericName
                      } | {foodBasket.portion}
                    </div>
                  </div>
                </div>
              ))
            }
            {
              basket.basket && basket.basket.length > 2 && <div>+{basket.basket.length - 2}</div>
            }
          </div>
          <div>
            <Button disabled={!basket.basket || basket.basket.length === 0} onClick={() => setShowConfirmModal(true)}>{Messages.t('formButton.save')}</Button>
          </div>
          <div>
            <Button onClick={() => foodBasketService.delete()} variant="icon"><DeleteOutlined /></Button>
          </div>
        </div>
      </div>
    </>
  );
}
