import React, { useEffect, useState } from 'react';
import AuthentificationRoot from 'pages/authentification/AuthentificationRoot';
import { AuthProvider, useFirebaseApp } from 'reactfire';
import { getAuth } from 'firebase/auth';
import { useObservable } from 'micro-observables';
import sessionManager from 'services/sessions/sessionManager';
import sessionService from 'services/sessions/sessionService';
import localService from 'services/i18n/LocalService';
import UserRoot from 'pages/swole-app/UserRoot';
import {
  Route,
  Switch,
} from 'react-router-dom';

import confirmationService from 'services/ConfirmationService';
import ConfirmationModalBase from 'lib/ConfirmationModalBase';
import PwaInstallation from 'lib/PwaInstallation';

function App() {
  const confirmationModal = useObservable(confirmationService.getModal());
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const authToken = useObservable(sessionManager.getCurrentTokenObservable());
  const [isLoading, setIsLoading] = useState(true);
  useObservable(localService.getLocal());
  useEffect(() => {
    const refreshToken = sessionManager.getRefreshToken();
    if (refreshToken) {
      sessionService.refreshSession(refreshToken).then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, []);

  const auth = getAuth(app);
  if (isLoading) {
    return null;
  }

  return (
    <div className="app">
      {
        confirmationModal && (
          <ConfirmationModalBase
            title={confirmationModal.title}
            message={confirmationModal.message}
            actionMessage={confirmationModal.actionMessage}
            actionColor={confirmationModal.actionColor}
          />
        )
      }
      <PwaInstallation isLoading={isLoading} setIsLoading={setIsLoading} />
      <AuthProvider sdk={auth}>
        {
          !authToken
            ? <AuthentificationRoot />
            : (
              <Switch>
                <Route component={UserRoot} />
              </Switch>
            )
        }
      </AuthProvider>
    </div>
  );
}

export default App;
