import React from 'react';
import {
  MANAGE_FOODS,
  MANAGE_FOODS_PAGE,
} from 'routes/Routes';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Foods from 'pages/swole-app/manage/foods/Foods';
import FoodPage from 'pages/swole-app/manage/foods/FoodPage';
import FoodBasketWrapper from 'pages/swole-app/manage/foods/FoodBasketWrapper';

export default function FoodRouter() {
  return (
    <>
      <FoodBasketWrapper />
      <Switch>
        <Route exact path={MANAGE_FOODS} component={Foods} />
        <Route exact path={MANAGE_FOODS_PAGE} component={FoodPage} />
        <Route render={() => <Redirect to={MANAGE_FOODS} />} />
      </Switch>
    </>
  );
}
