import React from 'react';
import NavItem from 'pages/swole-app/layout/NavItem';
import Messages from 'services/i18n/Messages';

import {
  ACTIVITIES,
  HOME,
  ID,
  MANAGE,
  PROFILE,
  WORKOUT_DETAIL,
  WORKOUTS,
} from 'routes/Routes';
import {
  FitnessCenter,
  HomeOutlined,
  LeaderboardSharp,
  MenuBook,
  PersonOutlined,
} from '@mui/icons-material';
import { Routes } from 'routes/RoutesUtils';
import { useWorkoutBackend } from 'network/queries/WorkoutQueries';

function NavBar() {
  const workoutQueries = useWorkoutBackend();
  const { getCurrentWorkouts } = workoutQueries;
  const { data: currentWorkouts } = getCurrentWorkouts();
  return (
    <nav className="nav-bar">
      <div className="nav-content">
        <div className="nav-bar-header">
          {Messages.t('application.name')}
        </div>
        <NavItem icon={<HomeOutlined />} exact path={HOME} />
        <NavItem icon={<LeaderboardSharp />} path={ACTIVITIES} />
        <div className="highlighted-nav-item">
          <NavItem
            disableActiveStyle
            icon={<FitnessCenter />}
            path={
              currentWorkouts && currentWorkouts.length > 0
                ? Routes.withPath(WORKOUT_DETAIL, [{ label: ID, value: currentWorkouts[0].id }])
                : WORKOUTS
            }
          />
        </div>
        <NavItem icon={<MenuBook />} path={MANAGE} />
        <NavItem icon={<PersonOutlined />} path={PROFILE} />

      </div>
    </nav>
  );
}

export default NavBar;
