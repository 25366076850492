import React, { useState } from 'react';
import { useWorkoutTemplateBackend } from 'network/queries/WorkoutTemplateQueries';
import Messages from 'services/i18n/Messages';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { RouterPrompt } from 'lib/RouterPrompt';
import { NotificationService } from 'lib/notification';
import SpinButton from 'theme/SpinButton';
import { FetchError } from 'network/Errors';
import { useExerciceBackend } from 'network/queries/ExercicesQueries';
import SelectWrapper from 'lib/form/SelectWrapper';
import { Routes } from 'routes/RoutesUtils';
import {
  ID,
  MANAGE_WORKOUT_SERIE_EDIT,
  SERIE_ID,
} from 'routes/Routes';
import {
  Delete,
  DragHandle,
} from '@mui/icons-material';
import confirmationService from 'services/ConfirmationService';
import SortableList from 'lib/SortableList';
import { WorkoutTemplateSerie } from 'types/types';
import Button from 'theme/Button';

type Param = {
  id: string,
};

export default function WorkoutEdit() {
  const { id } = useParams<Param>();
  const [apiErrors, setApiErrors] = useState({});
  const location = useLocation();
  const { getExercices } = useExerciceBackend();
  const {
    createWorkoutTemplateSerie,
    getWorkoutTemplateSerie,
    deleteWorkoutTemplateSerie,
    updateWorkoutSerieOrder,
  } = useWorkoutTemplateBackend();
  const { data: series } = getWorkoutTemplateSerie(id);
  const { data: exercices } = getExercices();

  const [submitting, setSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<WorkoutTemplateSerie>();

  const changeOrder = async (data: WorkoutTemplateSerie[]) => updateWorkoutSerieOrder
    .mutateAsync({
      data: data.map((serie) => ({
        id: serie.id,
        order: serie.order,
      })),
      workoutId: id,
    });
  const onSubmit: SubmitHandler<WorkoutTemplateSerie> = async (data: WorkoutTemplateSerie) => {
    setSubmitting(true);
    data.order = (series?.length || 0) + 1;
    createWorkoutTemplateSerie.mutateAsync({ data, workoutTemplateId: id })
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        reset({});
      })
      .catch((error: FetchError) => {
        if (error.json_response) {
          setApiErrors(error.json_response);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const deleteWorkoutSerie = async (workoutTemplateId: string, workoutTemplateSerieId: string) => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('serie.delete'),
      message: Messages.t('global.delete'),
    });
    if (isConfirmed) {
      setSubmitting(true);
      deleteWorkoutTemplateSerie.mutateAsync({ workoutTemplateId, workoutTemplateSerieId })
        .then(() => NotificationService.notifySuccess(Messages.t('notifications.delete')))
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <div className="page-body">
      <h1>{Messages.t('manage.workouts')}</h1>
      {
        series && (
          <SortableList
            list={series}
            renderItem={(serie) => (
              <div className="serie-container">
                <DragHandle />
                <Link
                  className="serie-link"
                  key={serie.id}
                  to={Routes.withPath(
                    MANAGE_WORKOUT_SERIE_EDIT,
                    [
                      { label: ID, value: id },
                      { label: SERIE_ID, value: serie.id },
                    ],
                  )}
                >
                  <div>
                    <div>Exercice</div>
                    {exercices?.[serie.exerciceID]?.name}
                  </div>
                  <Button
                    variant="icon"
                    disabled={submitting}
                    onClick={(e) => {
                      e.preventDefault();
                      deleteWorkoutSerie(id, serie.id);
                    }}
                  >
                    <Delete />
                  </Button>
                </Link>
              </div>
            )}
            onChange={changeOrder}
          />
        )
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <RouterPrompt
          whenPageClose={isDirty}
          when={(newPathName) => isDirty && !newPathName.startsWith(location.pathname.split('/').slice(0, -1).join('/'))}
        />
        <h5>{Messages.t('field.name')}</h5>
        {
          exercices && (
            <Controller
              name="exerciceID"
              control={control}
              render={(controller) => (
                <SelectWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  values={Object.values(exercices)
                    .map((exercice) => ({ key: exercice.id, label: exercice.name }))}
                  label={Messages.t('field.name')}
                />
              )}
            />
          )
        }
        <SpinButton
          editing
          className="submit-button"
          spin={submitting}
          title={Messages.t('formButton.confirm')}
        />
      </form>
    </div>
  );
}
