import React, {
  useState,
  useRef,
} from 'react';
import {
  useActivityBackend,
  ActivityFilter,
} from 'network/queries/ActivityQueries';
import {
  Calendar,
  Views,
  EventProps,
  View,
} from 'react-big-calendar';
import moment from 'moment';
import confirmationService from 'services/ConfirmationService';
import { NotificationService } from 'lib/notification';
import Messages from 'services/i18n/Messages';
import {
  isSameDay,
  format,
} from 'date-fns';
import StringUtils from 'services/utils/StringUtils';
import localService from 'services/i18n/LocalService';
import {
  Components,
  RbcEvent,
} from 'lib/ReactBigCalendar';
import AgendaView from 'lib/calendar/AgendaView';
import MomentLocalizer from 'lib/calendar/MomentLocalizer';
import ReactBigCalendareUtils from 'services/utils/ReactBigCalendareUtils';
import CalendarToolbar from 'lib/calendar/CalendarToolbar';
import Button from 'theme/Button';
import {
  Add,
  FitnessCenter,
  Sports,
  RestaurantMenu,
  SportsMma,
  SportsTennis,
  SelfImprovement,
  Landscape,
  DownhillSkiing,
} from '@mui/icons-material';
import {
  ACTIVITIES_CREATE,
  ID,
  ACTIVITIES_DETAILS,
} from 'routes/Routes';
import ActivityTile from 'pages/swole-app/activities/activityList/ActivityTile';
import { Routes } from 'routes/RoutesUtils';
import FiltersUtils from 'services/filters/FiltersUtils';
import { useLocation } from 'react-router-dom';
import ActivityFilters from 'pages/swole-app/activities/activityList/ActivityFilters';
import { getEnumValue } from 'lib/type/enum';
import {
  ActivityType,
  Activity,
} from 'types/types';

export default function Activities() {
  const location = useLocation();
  const { getUserActivities, deleteActivity } = useActivityBackend();
  const queryParams = new URLSearchParams(location.search);
  const defaultFilter = FiltersUtils.getObjectFromQueryParam<ActivityFilter>({
    dateRange: [],
    type: [],
  }, queryParams);

  const [submitting, setSubmitting] = useState(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [view, setView] = useState<View>(Views.AGENDA);

  if (!defaultFilter?.type || defaultFilter?.type.length === 0) {
    defaultFilter.type = getEnumValue(ActivityType)
      .filter((activiy) => activiy !== ActivityType.ActivityTypeMeal);
  }

  if (view === Views.MONTH) {
    defaultFilter.dateRange = [startDate?.toISOString() || '', endDate?.toISOString() || ''];
  }
  const {
    data: activitiesPage,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = getUserActivities(defaultFilter);

  const globalizeLocalizer = MomentLocalizer(moment);
  const [displayedDate, setDisplayedDate] = useState(new Date());
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  const deleteSelectedActivity = async (activityId: string) => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('workout.delete'),
      message: Messages.t('global.delete'),
    });
    if (isConfirmed) {
      setSubmitting(true);
      deleteActivity.mutateAsync({ activityId })
        .then(() => NotificationService.notifySuccess(Messages.t('notifications.delete')))
        .finally(() => setSubmitting(false));
    }
  };

  const activities: Activity[] | undefined = activitiesPage?.pages
    .map((result) => result || [] as Activity[])?.flat();

  const getActivityTile = (activity?: Activity) => {
    if (!activity) {
      return <div />;
    }
    let icon = <Sports />;
    switch (activity.type) {
      case ActivityType.ActivityTypeWorkout:
        icon = <FitnessCenter />;
        break;
      case ActivityType.ActivityTypeMeal:
        icon = <RestaurantMenu />;
        break;
      case ActivityType.ActivityTypeClimbing:
        icon = <Landscape />;
        break;
      case ActivityType.ActivityTypeBoxe:
        icon = <SportsMma />;
        break;
      case ActivityType.ActivityTypeSki:
        icon = <DownhillSkiing />;
        break;
      case ActivityType.ActivityTypeYoga:
        icon = <SelfImprovement />;
        break;
      case ActivityType.ActivityTypeTennis:
        icon = <SportsTennis />;
        break;
      default:
        icon = <Sports />;
    }

    return (
      <ActivityTile
        icon={icon}
        path={Routes.withPath(ACTIVITIES_DETAILS, [{ label: ID, value: activity.id }])}
        date={activity.startDate}
        endDate={activity.endDate}
        label={Messages.t(`activity.type.${activity.type}`, { name: activity.name || '' })}
        onDelete={() => deleteSelectedActivity(activity.id)}
        disabled={submitting}
      />
    );
  };

  return (
    <div className="page-body" ref={scrollableRef}>
      <div className="activity-page">
        <div className="activity-add-container">
          <Button className="activity-add_button" to={ACTIVITIES_CREATE} roundedIcon variant="icon">
            <Add />
          </Button>
        </div>
        <ActivityFilters />
        <Calendar
          scrollableDivRef={scrollableRef}
          isLoading={isLoading}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          onNavigate={(date) => {
            setDisplayedDate(date);
          }}
          onRangeChange={(range, v) => {
            if (v) {
              setView(v);
            }
            if ((range as { start: Date, end: Date }).start) {
              setStartDate((range as { start: Date, end: Date }).start);
              setEndDate((range as { start: Date, end: Date }).end);
            }
          }}
          defaultView={view}
          localizer={globalizeLocalizer}
          step={60}
          views={{
            agenda: AgendaView,
            month: true,
          }}
          // @ts-ignore
          components={{
            toolbar: (e) => (
              <CalendarToolbar
                {...e}
              />
            ),
            month: {
              event: (e: EventProps<RbcEvent<Activity>>) => {
                if (e.event.start?.getMonth() !== displayedDate.getMonth()) {
                  return <></>;
                }
                return <div className="agenda-month-day" />;
              },
            },
            agenda: {
              time: (timeProps) => (
                getActivityTile(timeProps.event.resource)
              ),
              event: () => null,
              date: (e) => (
                <div>
                  {
                    isSameDay(new Date(), e.day)
                      ? Messages.t('dates.today')
                      : StringUtils.capitalizeAllLetter(format(e.day, 'EEEE dd MMMM', { locale: localService.getDateLocal() }))
                  }
                </div>
              ),
            },
          } as Components<RbcEvent<Activity>, {}>}
          formats={ReactBigCalendareUtils.getFormats()}
          events={
            (activities || []).map((activity) => (
              {
                resource: activity,
                title: null,
                allDay: false,
                start: new Date(activity.startDate),
                end: new Date(activity.startDate),
              }
            ))
          }
          messages={
            {
              noEventsInRange: Messages.t('calendar.visit.noEvent'),
            }
          }
          culture="fr"
          selectable
        />
      </div>
    </div>
  );
}
