// eslint-disable-next-line import/no-cycle
import FetchAdapter from 'network/FetchAdapter';
import settings from 'services/settings';
import sessionManager from 'services/sessions/sessionManager';
import JsonUtils from 'services/utils/JsonUtils';

class BackendFetchAdapter {
  private fetchAdapter;

  private backendEndpoint;

  private getToken;

  constructor(getToken: () => string | undefined) {
    this.fetchAdapter = new FetchAdapter();
    this.backendEndpoint = settings.backendEndpoint;
    this.getToken = getToken;
  }

  async fetch(path: string, funcParams?: any) {
    const url = `${this.backendEndpoint}${path}`;
    const token = this.getToken();
    let params = funcParams || {};

    if (token !== null) {
      params = {
        ...params,
        headers: {
          ...(params.headers || {}),
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return this.fetchAdapter.fetch(url, params);
  }

  async fetchJson(
    path: string,
    queryParams?: Record<string, string | string[] | number | boolean | undefined>,
    params?: any,
  ) {
    if (queryParams) {
      // eslint-disable-next-line no-param-reassign
      path = `${path}?${Object.keys(queryParams)
        .filter((key) => !!queryParams[key])
        .map((key) => (
          typeof queryParams[key] === 'string' || typeof queryParams[key] === 'number' || typeof queryParams[key] === 'boolean'
            ? ({ key, val: queryParams[key] })
            : (queryParams[key] as string[]).map((val) => ({
              key,
              val,
            }))))
        .flat()
        .map(({ key, val }) => `${key}=${typeof val === 'string' ? encodeURIComponent(val as string) : val}`)
        .join('&')}`;
    }
    const response = await this.fetch(path, params);
    if (response.headers.get('Content-Type') === 'application/json') {
      return response.json();
    }
    return response;
  }

  post<T>(path: string, jsonBody?: T, queryParams?: Record<string, string>) {
    return this.fetchJson(path, queryParams, buildParamsJsonBody('POST', JsonUtils.cleanJson(jsonBody)));
  }

  patch(path: string, jsonBody?: any, queryParams?: Record<string, string>) {
    return this.fetchJson(path, queryParams, buildParamsJsonBody('PATCH', JsonUtils.cleanJson(jsonBody)));
  }

  put(path: string, jsonBody?: any, queryParams?: Record<string, string>) {
    return this.fetchJson(path, queryParams, buildParamsJsonBody('PUT', JsonUtils.cleanJson(jsonBody)));
  }

  delete(path: string, jsonBody?: any, queryParams?: Record<string, string>) {
    return this.fetchJson(path, queryParams, buildParamsJsonBody('DELETE', JsonUtils.cleanJson(jsonBody)));
  }
}

const backend = new BackendFetchAdapter(() => sessionManager.getCurrentToken());
export default backend;

function buildParamsJsonBody(method: any, jsonBody: any) {
  return {
    method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(jsonBody || {}),
  };
}
