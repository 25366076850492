import React from 'react';
import {
  WORKOUT_DETAIL,
  WORKOUTS,
} from 'routes/Routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import Workouts from 'pages/swole-app/workouts/workoutList/Workouts';
import WorkoutContainer from 'pages/swole-app/workouts/workout/WorkoutContainer';

export default function WorkoutRouter() {
  return (
    <Switch>
      <Route exact path={WORKOUTS} component={Workouts} />
      <Route path={WORKOUT_DETAIL} component={WorkoutContainer} />
      <Route render={() => <Redirect to={WORKOUTS} />} />
    </Switch>
  );
}
