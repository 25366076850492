import React from 'react';
import Messages from 'services/i18n/Messages';
import {
  Controller,
  UseFormReturn,
} from 'react-hook-form';
import { RouterPrompt } from 'lib/RouterPrompt';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import SelectWrapper from 'lib/form/SelectWrapper';
import DateTimePickerWrapper from 'lib/form/DateTimePickerWrapper';
import { useWorkoutTemplateBackend } from 'network/queries/WorkoutTemplateQueries';
import { useLocation } from 'react-router-dom';
import {
  addMinutes,
  differenceInMinutes,
} from 'date-fns';
import {
  ActivityCreation,
  Activity,
  ActivityType,
} from 'types/types';
import { getEnumValue } from 'lib/type/enum';

type Props = {
  form: UseFormReturn<ActivityCreation>
  apiErrors: { [key: string]: string[] }
  autoSaveSubmit?: (value: Partial<Activity>) => void,

};
export default function ActivityForm({ form, apiErrors, autoSaveSubmit }: Props) {
  const location = useLocation();
  const { getWorkoutTemplate } = useWorkoutTemplateBackend();
  const { data: workoutTemplates } = getWorkoutTemplate();

  const {
    control,
    watch,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = form;

  const values = watch();

  const autoSaveSubmitFunc = (value: Partial<Activity>) => {
    if (autoSaveSubmit) {
      autoSaveSubmit(value);
    }
    reset({ ...values, ...value });
  };

  return (
    <>
      <RouterPrompt
        whenPageClose={isDirty}
        when={(newPathName) => isDirty && !newPathName.startsWith(location.pathname.split('/').slice(0, -1).join('/'))}
      />
      {
        !autoSaveSubmit && (
          <>
            <Controller
              name="type"
              control={control}
              render={(controller) => (
                <SelectWrapper
                  apiErrors={apiErrors}
                  error={errors}
                  control={controller}
                  values={
                    getEnumValue(ActivityType)
                      .map((activity) => ({
                        key: activity,
                        label: Messages.t(`activity.type.${activity}`, { name: '' }),
                      }))
                  }
                  label={Messages.t('field.type')}
                />
              )}
            />
            {
              values.type === ActivityType.ActivityTypeWorkout && workoutTemplates && (
                <Controller
                  name="workoutTemplateID"
                  control={control}
                  render={(controller) => (
                    <SelectWrapper
                      apiErrors={apiErrors}
                      error={errors}
                      control={controller}
                      values={
                        workoutTemplates
                          .map((workout) => ({ key: workout.id, label: workout.name }))
                      }
                      label={Messages.t('field.type')}
                    />
                  )}
                />
              )
            }
          </>
        )
      }
      {
        !!autoSaveSubmit && <div>{Messages.t(`activity.type.${values.type}`, { name: '' })}</div>
      }
      <Controller
        name="startDate"
        control={control}
        render={(controller) => (
          <DateTimePickerWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            onChange={(startDate) => {
              if (!startDate) {
                return;
              }
              let minutesDelta = 60;
              const start = new Date(values.startDate);
              if (values.endDate) {
                const end = new Date(values.endDate);
                minutesDelta = differenceInMinutes(end, start);
              }
              const endStr = addMinutes(startDate, minutesDelta).toISOString();
              setValue('endDate', endStr);
              if (autoSaveSubmit) {
                autoSaveSubmitFunc({
                  startDate: startDate.toISOString(),
                  endDate: endStr,
                });
              }
            }}
            label={Messages.t('field.startDate')}
          />
        )}
      />
      <Controller
        name="endDate"
        control={control}
        render={(controller) => (
          <DateTimePickerWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            onChange={(endDate) => !!autoSaveSubmit
              && autoSaveSubmitFunc({ endDate: endDate?.toISOString() })}
            label={Messages.t('field.endDate')}
          />
        )}
      />
      <Controller
        name="comment"
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            type="textarea"
            rows={3}
            control={controller}
            autoSaveSubmit={(comment) => !!autoSaveSubmit && autoSaveSubmitFunc({ comment })}
            label={Messages.t('field.comment')}
          />
        )}
      />
      <Controller
        name="name"
        control={control}
        render={(controller) => (
          <TextFieldWrapper
            apiErrors={apiErrors}
            error={errors}
            control={controller}
            autoSaveSubmit={(name) => !!autoSaveSubmit && autoSaveSubmitFunc({ name })}
            label={Messages.t('field.name')}
          />
        )}
      />
    </>
  );
}
