import {
  useQuery,
  useInfiniteQuery,
} from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessions/sessionManager';
import { FoodSearchFilter } from 'pages/swole-app/manage/foods/FoodFilters';
import { PaginatedOpenFoodReference } from 'types/typesAlias';
import { OpenFoodReference } from 'types/types';

const PAGE_SIZE = 20;
// 1 hour in milli
const SEARCH_STALE_TIME = 1000 * 60 * 60;

export function useFoodReferenceBackend() {
  const GetFoodReferenceByName = (
    search: FoodSearchFilter,
    enabled = true,
  ) => useInfiniteQuery<PaginatedOpenFoodReference>(
    ['GetFoodReferenceByName', JSON.stringify(search), sessionManager.getSession()?.user_id],
    async ({ pageParam = [] }) => {
      const queryParams = {
        ...search,
        esIndexScore: pageParam.length > 1 ? pageParam[1] : undefined,
        esIndexScan: pageParam.length > 1 ? pageParam[0] : undefined,
        size: PAGE_SIZE,
      };
      const response: PaginatedOpenFoodReference = await backend.fetchJson(
        '/api/food-references/search', queryParams,
      );
      return response;
    },
    {
      staleTime: SEARCH_STALE_TIME,
      enabled,
      getNextPageParam: (
        lastPage,
      ) => lastPage?.data?.length === PAGE_SIZE && lastPage?.esIndex,
    },
  );

  const GetFoodReferenceById = (id: string, enabled = true) => useQuery<OpenFoodReference>(
    ['GetFoodReferenceById', id, sessionManager.getSession()?.user_id],
    async () => {
      const response: OpenFoodReference = await backend.fetchJson(
        `/api/food-references/${id}`,
      );
      return response;
    },
    { enabled },
  );

  const GetFoodReferenceByCode = (code: string, enabled = true) => useQuery<OpenFoodReference>(
    ['GetFoodReferenceByCode', code, sessionManager.getSession()?.user_id],
    async () => {
      const response: OpenFoodReference = await backend.fetchJson(
        `/api/food-references/code/${code}`,
      );
      return response;
    },
    { enabled },
  );

  return {
    getFoodReferenceByName: GetFoodReferenceByName,
    getFoodReferenceById: GetFoodReferenceById,
    getFoodReferenceByCode: GetFoodReferenceByCode,
  };
}
