import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessions/sessionManager';
import { FetchError } from 'network/Errors';
import {
  MealHistoryDetail,
  MealHistoryCreation,
  MissingMealDay,
  MealHistory,
} from 'types/types';

export function useMealHistoryBackend() {
  const queryClient = useQueryClient();

  const GetUserMeals = (
    startDate?: string,
    endDate?: string,
    enabled = true,
  ) => useQuery<MealHistoryDetail[]>(
    ['GetUserMealsHistory', sessionManager.getSession()?.user_id],
    async () => {
      const response: MealHistoryDetail[] = await backend.fetchJson(
        '/api/meals-history',
        {
          startDate,
          endDate,
        },
      );
      return response;
    },
    { enabled },
  );

  const GetUserMissingMeals = (
    enabled = true,
  ) => useQuery<MissingMealDay[]>(
    ['GetUserMissingMeals', sessionManager.getSession()?.user_id],
    async () => {
      const response: MissingMealDay[] = await backend.fetchJson(
        '/api/meals-history/missings',
      );
      return response;
    },
    { enabled },
  );

  const CreateUserMeal = useMutation<MealHistoryCreation, FetchError, MealHistoryCreation>(
    async (data) => {
      const response = await backend.post('/api/meals-history', data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['GetUserMealsHistory', sessionManager.getSession()?.user_id]);
        queryClient.invalidateQueries(['GetUserMissingMeals', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const UpdateUserMeal = useMutation<MealHistory, FetchError, { data: MealHistory, id: string }>(
    async ({ data, id }) => {
      const response = await backend.post(`/api/meals-history/${id}`, data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['GetUserMealsHistory', sessionManager.getSession()?.user_id]);
        queryClient.invalidateQueries(['GetUserMissingMeals', sessionManager.getSession()?.user_id]);
      },
    },
  );

  return {
    getUserMeals: GetUserMeals,
    createUserMeal: CreateUserMeal,
    getUserMissingMeals: GetUserMissingMeals,
    updateUserMeal: UpdateUserMeal,
  };
}
