import React, { useState } from 'react';
import { useWorkoutBackend } from 'network/queries/WorkoutQueries';
import WorkoutExerciceDetail from 'pages/swole-app/workouts/workout/exercices/WorkoutExerciceDetail';
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';
import { FetchError } from 'network/Errors';
import ExerciceTile from 'pages/swole-app/workouts/workout/exercices/ExerciceTile';
import { nullOrUndefined } from 'services/utils/utils';
import {
  WorkoutSerieDetailled,
  WorkoutExercice,
} from 'types/types';

type Props = {
  serie: WorkoutSerieDetailled
};
export default function WorkoutExercices({ serie }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const [apiErrors, setApiErrors] = useState({});

  const { createWorkoutExercice } = useWorkoutBackend();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<WorkoutExercice>();

  const exercices = serie.exercices?.sort((a, b) => a.order - b.order);

  let currentExercice: WorkoutExercice | undefined;
  if (exercices) {
    for (let i = 0; i < exercices.length; i++) {
      if (nullOrUndefined(exercices[i].repetitionNumber)) {
        currentExercice = exercices[i];
        break;
      }
    }
  }
  const onSubmit: SubmitHandler<WorkoutExercice> = async (
    data: WorkoutExercice,
  ) => {
    setSubmitting(true);
    data.order = (serie.exercices?.length || 0) + 1;
    createWorkoutExercice.mutateAsync({
      data,
      workoutId: serie.workoutID,
      workoutSerieId: serie.id,
    })
      .then(() => {
        reset(data);
      })
      .catch((error: FetchError) => {
        if (error.json_response) {
          setApiErrors(error.json_response);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <div className="exercice-tile-container">
        {
          exercices && exercices.map((exercice) => (
            <ExerciceTile
              key={exercice.id}
              workoutExercice={exercice}
              workoutId={serie.workoutID}
            />
          ))
        }
      </div>
      <div>
        {
          // trick to reload form on new currentExercice
          currentExercice && [currentExercice].map((exercice) => (
            <WorkoutExerciceDetail
              key={exercice.id}
              workoutExercice={exercice}
              workoutId={serie.workoutID}
            />
          ))
        }
        {
          !currentExercice && (
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <Controller
                    name="weight"
                    control={control}
                    render={(controller) => (
                      <TextFieldWrapper
                        apiErrors={apiErrors}
                        type="number"
                        error={errors}
                        control={controller}
                        label={Messages.t('field.weight')}
                      />
                    )}
                  />
                  <Controller
                    name="repetitionNumber"
                    control={control}
                    render={(controller) => (
                      <TextFieldWrapper
                        apiErrors={apiErrors}
                        type="number"
                        error={errors}
                        control={controller}
                        label={Messages.t('field.repetitionNumber')}
                      />
                    )}
                  />
                  <SpinButton
                    editing
                    className="submit-button"
                    spin={submitting}
                    title={Messages.t('formButton.confirm')}
                  />
                </div>
              </form>
            </div>

          )
        }
      </div>
    </div>
  );
}
