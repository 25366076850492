import React, { useState } from 'react';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';

import { useUserInformationsBackend } from 'network/queries/UserInformationsQueries';
import { FetchError } from 'network/Errors';
import DatePickerWrapper from 'lib/form/DatePickerWrapper';
import SelectWrapper from 'lib/form/SelectWrapper';
import {
  UserInformations,
  UserGender,
  UserActivityIntensity,
} from 'types/types';

type Props = {
  userInformations: UserInformations;
};
export default function UserInformationsForm({ userInformations }: Props) {
  const { updateUserInformations } = useUserInformationsBackend();
  const [apiErrors, setApiErrors] = useState({});
  const {
    control,
    formState: { errors },
  } = useForm<UserInformations>({ defaultValues: userInformations });
  const submitUserInformations = (data: Partial<UserInformations>) => {
    updateUserInformations.mutateAsync({
      data,
    }).catch((error: FetchError) => {
      if (error.json_response) {
        setApiErrors(error.json_response);
      }
    });
  };
  return (
    <>
      <form>
        <Controller
          name="weight"
          rules={{ required: true }}
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              autoSaveSubmit={(weight) => submitUserInformations({ weight: parseInt(weight, 10) })}
              apiErrors={apiErrors}
              error={errors}
              type="number"
              control={controller}
              suffix={Messages.t('field.weight.unit')}
              label={Messages.t('field.weight')}
            />
          )}
        />
        <Controller
          name="height"
          rules={{ required: true }}
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              autoSaveSubmit={(height) => submitUserInformations({ height: parseInt(height, 10) })}
              apiErrors={apiErrors}
              error={errors}
              type="number"
              control={controller}
              suffix={Messages.t('field.height.unit')}
              label={Messages.t('field.height')}
            />
          )}
        />
        <Controller
          name="birthday"
          rules={{ required: true }}
          control={control}
          render={(controller) => (
            <DatePickerWrapper
              autoSaveSubmit={(birthday) => submitUserInformations({
                birthday: birthday?.toISOString(),
              })}
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('field.birthday')}
            />
          )}
        />
        <Controller
          name="gender"
          rules={{ required: true }}
          control={control}
          render={(controller) => (
            <SelectWrapper
              values={Object.values(UserGender).map((value) => ({
                key: value, label: Messages.t(`gender.${value}`),
              }))}
              onChange={(gender: UserGender) => submitUserInformations({ gender })}
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('field.gender')}
            />
          )}
        />
        <Controller
          name="activityIntensity"
          rules={{ required: true }}
          control={control}
          render={(controller) => (
            <SelectWrapper
              values={Object.values(UserActivityIntensity).map((value) => ({
                key: value, label: Messages.t(`activityIntensity.${value}`),
              }))}
              onChange={(activityIntensity) => submitUserInformations({ activityIntensity })}
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('field.activityIntensity')}
            />
          )}
        />
      </form>
    </>
  );
}
