import React from 'react';
import Messages from 'services/i18n/Messages';
import { OpenFoodReference } from 'types/types';

type Props = {
  food: OpenFoodReference,
};

export default function HealthProduct({ food }: Props) {
  return (
    <div>
      {
        food.nutriscoreGrade && (
          <div>{Messages.t('product.health.nutriscore', { nutriscore: food.nutriscoreGrade.toUpperCase() })}</div>
        )
      }
    </div>
  );
}
