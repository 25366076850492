import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import { MANAGE_FOODS } from 'routes/Routes';
import {
  ChevronLeft,
  Delete,
  Edit,
  Add,
} from '@mui/icons-material';
import { useMealBackend } from 'network/queries/MealsQueries';
import { mealApi } from 'network/api/MealApi';
import foodBasketService, { foodBasketTypeEnum } from 'services/FoodBasketService';
import FoodBasketWrapper from 'pages/swole-app/manage/foods/FoodBasketWrapper';
import { Routes } from 'routes/RoutesUtils';
import { SHOW_MEAL_CONFIRM } from 'routes/QueryParams';
import Button from 'theme/Button';

export default function MealsManage() {
  const history = useHistory();
  const location = useLocation();
  const [submitting, setSubmitting] = useState(false);
  const { getUserMeals, deleteUserMeal } = useMealBackend();
  const { data: meals } = getUserMeals();

  const deleteMeal = async (mealId: string) => {
    setSubmitting(true);
    deleteUserMeal.mutateAsync({ mealId })
      .finally(() => setSubmitting(false));
  };

  const addMeal = async (mealId: string) => {
    setSubmitting(true);
    mealApi.getMealDetail(mealId)
      .then((mealDetail) => {
        foodBasketService.init(
          mealDetail.meal?.foods?.map((food) => ({
            food: food.foodReference || { id: '', isGeneric: false },
            type: foodBasketTypeEnum.quantity,
            quantity: food.weight,
          })),
        );
        history.replace(Routes.updatePathWithQuery(location, [{ label: SHOW_MEAL_CONFIRM, value: 'true' }]));
      }).finally(() => setSubmitting(false));
  };

  const editMeal = async (mealId: string) => {
    setSubmitting(true);
    mealApi.getMealDetail(mealId)
      .then((mealDetail) => {
        foodBasketService.init(
          mealDetail.meal?.foods?.map((food) => ({
            food: food.foodReference || { id: '', isGeneric: false },
            type: foodBasketTypeEnum.quantity,
            quantity: food.weight,
          })),
          mealDetail.name,
          mealDetail.id,
        );
        history.push(MANAGE_FOODS);
      }).finally(() => setSubmitting(false));
  };

  return (
    <>
      <FoodBasketWrapper />
      <div className="page-body">
        <h2 role="presentation" onClick={() => history.goBack()}><ChevronLeft /> {Messages.t('manage.meal')}</h2>
        {
          meals?.map((meal) => (
            <div
              key={meal.id}
              className="serie-container"
            >
              <div
                role="presentation"
                className="serie-link"
                onClick={() => editMeal(meal.id)}
              >
                <div>
                  {meal.name}
                </div>
                <Button variant="icon">
                  <Edit />
                </Button>
                <Button
                  variant="icon"
                  disabled={submitting}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    deleteMeal(meal.id);
                  }}
                >
                  <Delete />
                </Button>
                <Button
                  variant="icon"
                  disabled={submitting}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    addMeal(meal.id);
                  }}
                >
                  <Add />
                </Button>
              </div>
            </div>
          ))
        }
      </div>
    </>
  );
}
