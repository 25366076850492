import React from 'react';
import Messages from 'services/i18n/Messages';
import Header from 'pages/common/Header';
import ExpendableContainer from 'lib/ExpendableContainer';
import sessionService from 'services/sessions/sessionService';
import { PowerSettingsNewOutlined } from '@mui/icons-material';
import { useUserInformationsBackend } from 'network/queries/UserInformationsQueries';
import UserInformationsForm from 'pages/swole-app/profile/forms/UserInformationsForm';
import RecurrentMeals from 'pages/swole-app/profile/RecurrentMeals';
import { useRecurrentMealsBackend } from 'network/queries/RecurrentMealQueries';

export default function ProfilePage() {
  const { getUserInformations } = useUserInformationsBackend();
  const { data: userInformation } = getUserInformations();
  const { getUserRecurrentMeals } = useRecurrentMealsBackend();
  const { data: recurrentMeals, isLoading } = getUserRecurrentMeals();
  return (
    <>
      <Header hideBackButton label={Messages.t('profile.title')} />
      <div className="page-body">
        {
          userInformation && (
            <ExpendableContainer isFetching={false} label={Messages.t('profile.myData')}>
              <UserInformationsForm userInformations={userInformation} />
            </ExpendableContainer>
          )
        }
        {
          !isLoading && (
            <ExpendableContainer isFetching={false} label={Messages.t('profile.recurrentMeal')}>
              <RecurrentMeals recurrentMeals={recurrentMeals || []} />
            </ExpendableContainer>
          )
        }
        <div>
          <button
            type="button"
            className="link-as-button secondary settings-button"
            onClick={() => sessionService.logOut()}
          >
            <PowerSettingsNewOutlined />
            {Messages.t('button.logout')}
          </button>
        </div>
      </div>
    </>
  );
}
