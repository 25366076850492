import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import React from 'react';
import Button from 'theme/Button';

type Props = {
  label: string,
  hideBackButton?: boolean
};
export default function Header({ label, hideBackButton }: Props) {
  const history = useHistory();

  return (
    <header className="page-header">
      {
        !hideBackButton && <Button variant="icon" onClick={() => history.goBack()}><ArrowBack /></Button>
      }
      <div className="page-title">{label}</div>
    </header>
  );
}
