import React, { useState } from 'react';
import ExerciceModal from 'pages/swole-app/workouts/workout/exercices/ExerciceModal';
import { WorkoutExercice } from 'types/types';

type Props = {
  workoutId: string,
  workoutExercice: WorkoutExercice,
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ExerciceTile({ workoutExercice, workoutId }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const weight = workoutExercice.weight || workoutExercice.weightObjectiv || 0;
  const repetitions = workoutExercice.repetitionNumber || workoutExercice.repetitionNumberObjectiv;

  return (
    <>
      {
        openModal && (
          <ExerciceModal
            workoutExercice={workoutExercice}
            workoutId={workoutId}
            onClose={() => setOpenModal(false)}
          />
        )
      }
      <button
        onClick={() => setOpenModal(true)}
        type="button"
        className={`exercice-tile ${
          workoutExercice.repetitionNumber
            ? (
              workoutExercice.repetitionNumber >= workoutExercice.repetitionNumberObjectiv
              && (workoutExercice.weight || 0) >= (workoutExercice.weightObjectiv || 0))
              ? 'validate-exercice'
              : 'failed-exercice'
            : ''}`}
      >
        <div className="exercice-weight">
          {weight} Kg
        </div>
        <div className="exercice-reps">
          X {repetitions}
        </div>
      </button>
    </>
  );
}
