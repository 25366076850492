import React from 'react';
import foodBasketService, {
  FoodBasket,
  foodBasketTypeEnum,
} from 'services/FoodBasketService';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import Image from 'theme/Image';
import { Fastfood } from '@mui/icons-material';
import SelectWrapper from 'lib/form/SelectWrapper';

type Props = {
  foodBasket: FoodBasket,
};
export default function FoodBasketLine({ foodBasket }: Props) {
  const {
    control,
  } = useForm<FoodBasket>({ defaultValues: foodBasket });

  return (
    <>
      <div className="form-row">
        <div className="food-line">
          <div className="food-card-image-container">
            <Image alt="product" src={foodBasket.food.imageSmallURL} alternativElement={<Fastfood />} />
          </div>
          <div className="food-card-data">
            <div className="food-name">
              {
                foodBasket.food.productName || foodBasket.food.genericName
              }
            </div>
            {
              foodBasket.type === foodBasketTypeEnum.portion && (
                <Controller
                  name="portion"
                  control={control}
                  render={(controller) => (
                    <TextFieldWrapper
                      apiErrors={{}}
                      type="number"
                      control={controller}
                      label={Messages.t('field.portion')}
                      onChange={(value) => foodBasketService.update({
                        ...foodBasket,
                        portion: Number(value),
                      })}
                    />
                  )}
                />
              )
            }
            {
              foodBasket.type === foodBasketTypeEnum.quantity && (
                <Controller
                  name="quantity"
                  control={control}
                  render={(controller) => (
                    <TextFieldWrapper
                      apiErrors={{}}
                      type="number"
                      control={controller}
                      label={Messages.t('field.quantity')}
                      onChange={(value) => foodBasketService.update({
                        ...foodBasket,
                        quantity: Number(value),
                      })}
                    />
                  )}
                />
              )
            }
            <Controller
              name="type"
              control={control}
              render={(controller) => (
                <SelectWrapper
                  apiErrors={{}}
                  values={Object.values(foodBasketTypeEnum)
                    .map((key) => ({ key, label: key }))}
                  control={controller}
                  label={Messages.t('field.weight')}
                  error={{}}
                  onChange={(value) => foodBasketService.update({
                    ...foodBasket,
                    type: value,
                  })}
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}
