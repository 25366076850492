import React, { useState } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
} from 'react-hook-form/dist/types/controller';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import { DateTimePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';

type Props = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  },
  label?: string
  error: FieldErrors,
  apiErrors?: { [key: string]: string[] }
  disableBefore?: Date
  disableAfter?: Date
  disablePast?: boolean,
  defaultDateMonth?: Date,
  onChange?: (newDate?: Date) => void
};

export default function DateTimePickerWrapper(
  {
    control,
    label,
    error,
    apiErrors,
    disablePast,
    disableAfter,
    onChange,
    disableBefore,
    defaultDateMonth,
  }: Props,
) {
  const { field } = control;
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);
  const [open, setOpen] = useState(false);

  const value = field.value || null; // force null instead of undefined
  return (
    <div className="material-textfield-wrapper">
      <DateTimePicker
        {...field}
        onChange={(e) => {
          field.onChange(e);
          if (onChange) {
            onChange(e || undefined);
          }
        }}
        value={parseISO(value)}
        minutesStep={15}
        openTo="hours"
        defaultCalendarMonth={defaultDateMonth}
        disablePast={disablePast}
        slotProps={{
          field: { clearable: true },
          textField: {
            error: !!errorMessage,
            helperText: errorMessage,
            fullWidth: true,
            onClick: () => setOpen(true),
          },
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        maxDateTime={disableAfter}
        minDateTime={disableBefore}
        ampm={false}
        label={label || undefined}
        // renderInput={(params: any) => (
        //   <TextField
        //     {...params}
        //     error={!!errorMessage}
        //     helperText={errorMessage}
        //     fullWidth
        //     onClick={() => setOpen(true)}
        //     variant="filled"
        //     InputProps={{
        //       disableUnderline: true,
        //       ...params.InputProps,
        //     }}
        //   />
        // )}
      />
    </div>
  );
}
