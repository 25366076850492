import React from 'react';
import RecurrentMealForm from 'pages/swole-app/profile/forms/RecurrentMealForm';
import { RecurrentMeal } from 'types/types';

type Props = {
  recurrentMeals: RecurrentMeal[],
};

export default function RecurrentMeals({ recurrentMeals }: Props) {
  return (
    <>
      {
        recurrentMeals.map((recurrentMeal) => (
          <RecurrentMealForm recurrentMeal={recurrentMeal} key={recurrentMeal.id} />
        ))
      }
      <RecurrentMealForm />
    </>
  );
}
