import { IconButton } from '@mui/material';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import DateUtils from 'services/utils/DateUtils';
import { Delete } from '@mui/icons-material';

type Props = {
  icon: ReactNode,
  path: string
  date: string,
  endDate?: string,
  label: string,
  onDelete: () => void,
  disabled: boolean
};
export default function ActivityTile(
  {
    icon,
    path,
    onDelete,
    date,
    endDate,
    label,
    disabled,
  }: Props,
) {
  return (
    <div className="activity-tile-container">
      <Link
        className="activity-tile"
        to={path}
      >
        <div className="tile-icon">{icon}</div>
        <div className="activity-data">
          <div>
            {`${DateUtils.getHoursString(new Date(date))} - ${endDate ? DateUtils.getHoursString(new Date(endDate)) : ''}`}
          </div>
          <div>
            {label}
          </div>
        </div>
        <IconButton
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            onDelete();
          }}
        >
          <Delete />
        </IconButton>
      </Link>
    </div>
  );
}
