import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessions/sessionManager';
import { FetchError } from 'network/Errors';
import { booleanToString } from 'lib/form/FormUtils';
import { Sortable } from 'lib/SortableList';
import {
  WorkoutTemplate,
  WorkoutTemplateSerie,
  WorkoutTemplateExercice,
} from 'types/types';

export function useWorkoutTemplateBackend() {
  const queryClient = useQueryClient();

  const GetWorkoutTemplate = (enabled = true) => useQuery<WorkoutTemplate[]>(
    ['getWorkoutTemplates', sessionManager.getSession()?.user_id],
    async () => {
      const response: WorkoutTemplate[] = await backend.fetchJson(
        '/api/workout-templates',
      );
      return response;
    },
    { enabled },
  );

  const UpdateWorkoutSerieOrder = useMutation<void, FetchError, {
    workoutId: string,
    data: Sortable[],
  }>(
    async ({ workoutId, data }) => {
      const response = await backend.put(`/api/workout-templates/${workoutId}/series/order`, data);
      return response;
    }, {
      onSuccess: (_, { workoutId }) => {
        queryClient.refetchQueries(['getWorkoutTemplateSeries', workoutId, sessionManager.getSession()?.user_id]);
      },
    },
  );

  const CreateWorkoutTemplate = useMutation<WorkoutTemplate, FetchError, { name: string }>(
    async (data) => {
      const response = await backend.post('/api/workout-templates', data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getWorkoutTemplates', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const DeleteWorkoutTemplate = useMutation<void, FetchError, { workoutTemplateId: string }>(
    async ({ workoutTemplateId }) => {
      const response = await backend.delete(`/api/workout-templates/${workoutTemplateId}`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getWorkoutTemplates', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const GetWorkoutTemplateSerie = (
    workoutTemplateId: string,
    enabled = true,
  ) => useQuery<WorkoutTemplateSerie[]>(
    ['getWorkoutTemplateSeries', workoutTemplateId, sessionManager.getSession()?.user_id],
    async () => {
      const response: WorkoutTemplateSerie[] = await backend.fetchJson(
        `/api/workout-templates/${workoutTemplateId}/series`,
      );
      return response;
    },
    { enabled },
  );

  const DeleteWorkoutTemplateSerie = useMutation<void, FetchError, {
    workoutTemplateId: string,
    workoutTemplateSerieId: string,
  }>(
    async ({ workoutTemplateId, workoutTemplateSerieId }) => {
      const response = await backend.delete(`/api/workout-templates/${workoutTemplateId}/series/${workoutTemplateSerieId}`);
      return response;
    }, {
      onSuccess: (_, { workoutTemplateId }) => {
        queryClient.refetchQueries(['getWorkoutTemplateSeries', workoutTemplateId, sessionManager.getSession()?.user_id]);
      },
    },
  );

  const CreateWorkoutTemplateSerie = useMutation<WorkoutTemplateSerie, FetchError, {
    data: Partial<WorkoutTemplateSerie>,
    workoutTemplateId: string,
  }>(
    async ({ data, workoutTemplateId }) => {
      const response = await backend.post(`/api/workout-templates/${workoutTemplateId}/series`, data);
      return response;
    }, {
      onSuccess: (_, { workoutTemplateId }) => {
        queryClient.refetchQueries(['getWorkoutTemplateSeries', workoutTemplateId, sessionManager.getSession()?.user_id]);
      },
    },
  );

  const GetWorkoutTemplateExercice = (
    workoutTemplateId: string,
    workoutTemplateSerieId: string,
    enabled = true,
  ) => useQuery<WorkoutTemplateExercice[]>(
    ['getWorkoutTemplateExercices', workoutTemplateId, workoutTemplateSerieId, sessionManager.getSession()?.user_id],
    async () => {
      const response: WorkoutTemplateExercice[] = await backend.fetchJson(
        `/api/workout-templates/${workoutTemplateId}/series/${workoutTemplateSerieId}/exercices`,
      );
      return response;
    },
    { enabled },
  );

  const DeleteWorkoutTemplateExercice = useMutation<void, FetchError, {
    workoutTemplateId: string,
    workoutTemplateSerieId: string,
    workoutTemplateExerciceId: string,
  }>(
    async ({ workoutTemplateId, workoutTemplateSerieId, workoutTemplateExerciceId }) => {
      const response = await backend.delete(`/api/workout-templates/${workoutTemplateId}/series/${workoutTemplateSerieId}/exercices/${workoutTemplateExerciceId}`);
      return response;
    }, {
      onSuccess: (_, { workoutTemplateId, workoutTemplateSerieId }) => {
        queryClient.refetchQueries(['getWorkoutTemplateExercices', workoutTemplateId, workoutTemplateSerieId, sessionManager.getSession()?.user_id]);
      },
    },
  );

  const CreateWorkoutTemplateExercice = useMutation<WorkoutTemplateExercice, FetchError, {
    data: Partial<WorkoutTemplateExercice>,
    workoutTemplateId: string,
    workoutTemplateSerieId: string,
  }>(
    async ({ data, workoutTemplateId, workoutTemplateSerieId }) => backend
      .post(`/api/workout-templates/${workoutTemplateId}/series/${workoutTemplateSerieId}/exercices`, data), {
      onSuccess: (_, { workoutTemplateId, workoutTemplateSerieId }) => {
        queryClient.refetchQueries(['getWorkoutTemplateExercices', workoutTemplateId, workoutTemplateSerieId, sessionManager.getSession()?.user_id]);
      },
    },
  );

  const UpdateWorkoutTemplateExercice = useMutation<WorkoutTemplateExercice, FetchError, {
    data: Partial<WorkoutTemplateExercice>,
    workoutId: string,
    workoutSerieId: string,
    workoutExericeId: string,
    updateEqualWeight?: boolean
  }>(
    async (
      {
        data,
        workoutId,
        workoutSerieId,
        workoutExericeId,
        updateEqualWeight,
      },
    ) => {
      const response = await backend.patch(`/api/workout-templates/${workoutId}/series/${workoutSerieId}/exercices/${workoutExericeId}`, data, { updateEqualWeight: booleanToString(updateEqualWeight) || 'false' });
      return response;
    }, {
      onSuccess: (_, { workoutId, workoutSerieId }) => {
        queryClient.refetchQueries(['getWorkoutTemplateExercices', workoutId, workoutSerieId, sessionManager.getSession()?.user_id]);
      },
    },
  );

  return {
    getWorkoutTemplate: GetWorkoutTemplate,
    createWorkoutTemplate: CreateWorkoutTemplate,
    getWorkoutTemplateSerie: GetWorkoutTemplateSerie,
    updateWorkoutSerieOrder: UpdateWorkoutSerieOrder,
    createWorkoutTemplateSerie: CreateWorkoutTemplateSerie,
    getWorkoutTemplateExercice: GetWorkoutTemplateExercice,
    createWorkoutTemplateExercice: CreateWorkoutTemplateExercice,
    deleteWorkoutTemplate: DeleteWorkoutTemplate,
    deleteWorkoutTemplateSerie: DeleteWorkoutTemplateSerie,
    deleteWorkoutTemplateExercice: DeleteWorkoutTemplateExercice,
    updateWorkoutTemplateExercice: UpdateWorkoutTemplateExercice,
  };
}
