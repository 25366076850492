import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessions/sessionManager';

import { FetchError } from 'network/Errors';
import { Exercice } from 'types/types';

export function useExerciceBackend() {
  const queryClient = useQueryClient();

  const GetExercices = (enabled = true) => useQuery<{ [key: string]: Exercice }>(
    ['getExercices', sessionManager.getSession()?.user_id],
    async () => {
      const response: Exercice[] = await backend.fetchJson(
        '/api/exercices',
      );
      return response.reduce((acc: Record<string, Exercice>, value) => {
        acc[value.id] = value;
        return acc;
      }, {});
    },
    { enabled, staleTime: Infinity },
  );

  const CreateExercice = useMutation<Exercice, FetchError, Exercice>(
    async (data) => {
      const response = await backend.post('/api/exercices', data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getExercices', sessionManager.getSession()?.user_id]);
      },
    },
  );

  return {
    getExercices: GetExercices,
    createExercice: CreateExercice,
  };
}
