import React, {
  useState,
  useEffect,
} from 'react';
import Messages from 'services/i18n/Messages';
import {
  useLocation,
  useHistory,
} from 'react-router-dom';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import {
  Controller,
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import FiltersUtils from 'services/filters/FiltersUtils';
import { Routes } from 'routes/RoutesUtils';
import Button from 'theme/Button';
import { Drawer } from '@mui/material';
import AutoCompleteWrapper from 'lib/form/AutoCompleteWrapper';
import { foodReferenceApi } from 'network/api/foodReferenceApi';
import { Tune } from '@mui/icons-material';

export type FoodSearchFilter = {
  search: string,
  brand: string[],
  group1: string[],
  group2: string[],
  type: string[],
};

export default function FoodFilters() {
  const location = useLocation();
  const history = useHistory();
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const defaultFilter = FiltersUtils.getObjectFromQueryParam<FoodSearchFilter>({
    search: '',
    brand: [],
    type: [],
    group1: [],
    group2: [],
  }, queryParams);
  const {
    control,
    handleSubmit,
    watch,
  } = useForm<FoodSearchFilter>(
    {
      defaultValues: {
        ...defaultFilter,
      },
    },
  );

  const values = watch();

  useEffect(() => {
    history.replace(Routes.updatePathWithQuery(
      location,
      FiltersUtils.getQueryParamsFromObject({ ...values }),
    ));
  }, [
    values.brand, values.group1, values.group2, values.type,
  ]);

  const onSubmit: SubmitHandler<FoodSearchFilter> = async (
    data: FoodSearchFilter,
  ) => {
    history.replace(Routes.updatePathWithQuery(
      location,
      FiltersUtils.getQueryParamsFromObject({ ...data }),
    ));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <Controller
          name="search"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={{}}
              error={{}}
              type="search"
              control={controller}
              label={Messages.t('field.search')}
            />
          )}
        />
        <Button roundedIcon variant="icon" onClick={() => setShowMoreFilters(true)}><Tune /></Button>
        <Drawer anchor="right" open={showMoreFilters} onClose={() => setShowMoreFilters(false)}>
          <Controller
            name="brand"
            control={control}
            render={(controller) => (
              <AutoCompleteWrapper
                apiErrors={{}}
                error={{}}
                multiple
                getPropositions={foodReferenceApi.searchfoodBrand}
                control={controller}
                label={Messages.t('field.brand')}
                placeholder={Messages.t('field.brand')}
              />
            )}
          />
          <Controller
            name="group1"
            control={control}
            render={(controller) => (
              <AutoCompleteWrapper
                apiErrors={{}}
                error={{}}
                multiple
                getPropositions={foodReferenceApi.searchfoodGroup1}
                control={controller}
                label={Messages.t('field.group1')}
                placeholder={Messages.t('field.group1')}
              />
            )}
          />
          <Controller
            name="group2"
            control={control}
            render={(controller) => (
              <AutoCompleteWrapper
                apiErrors={{}}
                error={{}}
                multiple
                getPropositions={foodReferenceApi.searchfoodGroup2}
                control={controller}
                label={Messages.t('field.group2')}
                placeholder={Messages.t('field.group2')}
              />
            )}
          />
          <Controller
            name="type"
            control={control}
            render={(controller) => (
              <AutoCompleteWrapper
                apiErrors={{}}
                error={{}}
                multiple
                getPropositions={foodReferenceApi.searchfoodType}
                control={controller}
                label={Messages.t('field.type')}
                placeholder={Messages.t('field.type')}
              />
            )}
          />
        </Drawer>
      </div>
    </form>
  );
}
