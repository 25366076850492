import React, {
  useEffect,
  useState,
} from 'react';
import {
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';

type Props = {
  startDate: Date,
};

export default function Timer(
  {
    startDate,
  }: Props,
) {
  const [wakeLock, setWakeLock] = useState();

  const getTimerString = (): string => {
    const now = new Date();
    const hourDiff = differenceInHours(startDate, now);
    const minuteDiff = differenceInMinutes(startDate, now);
    const secondeDiff = differenceInSeconds(startDate, now);
    return `${Math.abs(hourDiff).toString().padStart(2, '0')}:${(Math.abs(minuteDiff) % 60).toString().padStart(2, '0')}:${(Math.abs(secondeDiff) % 60).toString().padStart(2, '0')}`;
  };

  const [timerText, setTimerText] = useState(getTimerString());

  useEffect(() => {
    // @ts-ignore
    if (navigator.wakeLock) {
      // @ts-ignore
      // eslint-disable-next-line no-console
      navigator.wakeLock.request('screen').then((lock) => setWakeLock(lock)).catch(console.error);
    }
    return () => {
      // @ts-ignore
      wakeLock?.release();
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTimerText(getTimerString());
    }, 500);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="timer-container">
      {timerText}
    </div>
  );
}
