export default class ArrayUtils {
  static removeDuplicates(array: string[]) {
    return Object.keys(array.reduce((acc: Record<string, string>, value) => {
      acc[value.toLowerCase()] = value.toLowerCase();
      return acc;
    }, {}));
  }

  static sum(array: number[]): number {
    return array.reduce((acc, val) => acc + val, 0) || 0;
  }
}
