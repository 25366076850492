import { MealDetailHistory } from 'types/types';

export type MealMacro = {
  kcal: number;
  proteins: number;
  lipids: number;
  carbs: number;
};
export default class MealUtils {
  public static computeMealMacro(meal?: MealDetailHistory, portion = 1): MealMacro {
    const res: MealMacro = {
      kcal: 0,
      proteins: 0,
      lipids: 0,
      carbs: 0,
    };
    meal?.foods?.forEach((food) => {
      res.kcal += (food.weight / 100) * (food?.foodReference?.['energy-kcal100g'] || 0);
      res.proteins += (food.weight / 100) * (food?.foodReference?.proteins100g || 0);
      res.lipids += (food.weight / 100) * (food?.foodReference?.fat100g || 0);
      res.carbs += (food.weight / 100) * (food?.foodReference?.carbohydrates100g || 0);
    });
    res.kcal *= portion;
    res.proteins *= portion;
    res.lipids *= portion;
    res.carbs *= portion;
    return res;
  }
}
