import React from 'react';
import { useMealHistoryBackend } from 'network/queries/MealsHistoryQueries';
import { startOfDay } from 'date-fns';
import MealUtils, { MealMacro } from 'services/utils/MealUtils';
import ArrayUtils from 'services/utils/ArrayUtils';
import DateUtils from 'services/utils/DateUtils';
import { MealHistoryDetail } from 'types/types';

interface MealWithMacro extends MealHistoryDetail {
  macro: MealMacro;
}

export default function Meals() {
  const { getUserMeals } = useMealHistoryBackend();
  const { data: meals } = getUserMeals(startOfDay(new Date()).toISOString());
  const mealsWithMacro: MealWithMacro[] | undefined = meals?.map((meal) => ({
    ...meal,
    macro: MealUtils.computeMealMacro(meal.meal, 1),
  }));
  return (
    <>
      {
        mealsWithMacro?.map((meal) => (
          <div className="serie-container">
            <div className="serie-link">
              <div>
                <div>
                  {
                    DateUtils.getHoursString(new Date(meal.date))
                  }
                </div>
                <div>
                  {meal.macro.kcal.toFixed(0)} kcal; p: {meal.macro.proteins.toFixed(0)} g; l: {
                  meal.macro.lipids.toFixed(0)
                } g; g: {meal.macro.carbs.toFixed(0)} g
                </div>
              </div>
            </div>
          </div>
        ))
      }
      {
        mealsWithMacro && (
          <div>
            Total : {ArrayUtils.sum(mealsWithMacro.map((meal) => meal.macro.kcal)).toFixed(0)} kcal;
            P: {ArrayUtils.sum(mealsWithMacro.map((meal) => meal.macro.proteins)).toFixed(0)} g
            L: {ArrayUtils.sum(mealsWithMacro.map((meal) => meal.macro.lipids)).toFixed(0)} g
            G: {ArrayUtils.sum(mealsWithMacro.map((meal) => meal.macro.carbs)).toFixed(0)} g
          </div>
        )
      }
    </>
  );
}
