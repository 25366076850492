import React from 'react';
import {
  ACTIVITIES,
  ACTIVITIES_CREATE,
  ACTIVITIES_DETAILS,
} from 'routes/Routes';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import Activities from 'pages/swole-app/activities/activityList/Activities';
import Header from 'pages/common/Header';
import Messages from 'services/i18n/Messages';
import { Routes } from 'routes/RoutesUtils';
import ActivityCreationPage from 'pages/swole-app/activities/activity/ActivityCreationPage';
import ActivityDetails from 'pages/swole-app/activities/activity/ActivityDetails';

export default function ActivitiesRouter() {
  const location = useLocation();
  return (
    <>
      <Header hideBackButton={Routes.locationMatchWithPath(location, ACTIVITIES)} label={Messages.t('history.title')} />
      <Switch>
        <Route exact path={ACTIVITIES} component={Activities} />
        <Route exact path={ACTIVITIES_CREATE} component={ActivityCreationPage} />
        <Route path={ACTIVITIES_DETAILS} component={ActivityDetails} />
        <Route render={() => <Redirect to={ACTIVITIES} />} />
      </Switch>
    </>

  );
}
