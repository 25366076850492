import React from 'react';

type Props = {
  label: string,
  value100g?: number,
  servingQuantity?: number,
  unit?: string,
};

function roundValue(value: number) {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

export default function NutritionnalRow(
  {
    label,
    servingQuantity,
    value100g,
    unit,
  }: Props,
) {
  if (!value100g) {
    return null;
  }

  return (
    <div className="nutritional-row">
      <div>
        {label}:
      </div>
      <div>
        {`${roundValue(value100g)} ${unit || 'g'}`}
      </div>
      {
        servingQuantity && (
          <div>
            {`${roundValue(value100g / servingQuantity)} ${unit || 'g'}`}
          </div>
        )
      }
    </div>
  );
}
