import {
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessions/sessionManager';
import { FetchError } from 'network/Errors';
import { booleanToString } from 'lib/form/FormUtils';
import { Sortable } from 'lib/SortableList';
import {
  WorkoutDetailled,
  Workout,
  WorkoutCreation,
  WorkoutSerie,
  WorkoutExercice,
} from 'types/types';

export function useWorkoutBackend() {
  const queryClient = useQueryClient();

  const GetWorkout = (workoutId: string, enabled = true) => useQuery<WorkoutDetailled>(
    ['getWorkout', workoutId, sessionManager.getSession()?.user_id],
    async () => {
      const response: WorkoutDetailled = await backend.fetchJson(
        `/api/workouts/${workoutId}`,
      );
      return response;
    },
    { enabled },
  );

  const GetWorkouts = (enabled = true) => useQuery<Workout[]>(
    ['getWorkouts', sessionManager.getSession()?.user_id],
    async () => {
      const response: Workout[] = await backend.fetchJson(
        '/api/workouts',
      );
      return response;
    },
    { enabled },
  );

  const GetCurrentWorkouts = (enabled = true) => useQuery<Workout[]>(
    ['getCurrentWorkouts', sessionManager.getSession()?.user_id],
    async () => {
      const response: Workout[] = await backend.fetchJson(
        '/api/workouts/currents',
      );
      return response;
    },
    { enabled },
  );

  const StartWorkout = useMutation<Workout, FetchError, WorkoutCreation>(
    async (data) => {
      const response = await backend.post('/api/workouts/start', data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getCurrentWorkouts', sessionManager.getSession()?.user_id]);
        queryClient.invalidateQueries(['getWorkouts', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const EndWorkout = useMutation<Workout, FetchError, { workoutId: string }>(
    async ({ workoutId }) => {
      const response = await backend.post(`/api/workouts/${workoutId}/end`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getCurrentWorkouts', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const DeleteWorkout = useMutation<void, FetchError, { workoutId: string }>(
    async ({ workoutId }) => {
      const response = await backend.delete(`/api/workouts/${workoutId}`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['getWorkouts', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const DeleteWorkoutSerie = useMutation<void, FetchError, { workoutId: string, serieId: string }>(
    async ({ workoutId, serieId }) => {
      const response = await backend.delete(`/api/workouts/${workoutId}/series/${serieId}`);
      return response;
    }, {
      onSuccess: (_, { workoutId }) => {
        queryClient.refetchQueries(['getWorkout', workoutId, sessionManager.getSession()?.user_id]);
      },
    },
  );

  const UpdateWorkoutSerieOrder = useMutation<void, FetchError, {
    workoutId: string,
    data: Sortable[],
  }>(
    async ({ workoutId, data }) => {
      const response = await backend.put(`/api/workouts/${workoutId}/series/order`, data);
      return response;
    }, {
      onSuccess: (_, { workoutId }) => {
        queryClient.refetchQueries(['getWorkout', workoutId, sessionManager.getSession()?.user_id]);
      },
    },
  );

  const GetWorkoutSeries = (
    workoutId: string,
    enabled = true,
  ) => useQuery<WorkoutSerie[]>(
    ['getWorkoutSeries', workoutId, sessionManager.getSession()?.user_id],
    async () => {
      const response: WorkoutSerie[] = await backend.fetchJson(
        `/api/workouts/${workoutId}/series`,
      );
      return response;
    },
    { enabled },
  );

  const GetWorkoutSerie = (
    workoutId: string,
    serieId: string,
    enabled = true,
  ) => useQuery<WorkoutSerie>(
    ['getWorkoutSerie', workoutId, serieId, sessionManager.getSession()?.user_id],
    async () => {
      const response: WorkoutSerie = await backend.fetchJson(
        `/api/workouts/${workoutId}/series/${serieId}`,
      );
      return response;
    },
    { enabled },
  );

  const GetWorkoutExercices = (
    workoutId: string,
    workoutSerieId: string,
    enabled = true,
  ) => useQuery<WorkoutExercice[]>(
    ['getWorkoutExercices', workoutId, workoutSerieId, sessionManager.getSession()?.user_id],
    async () => {
      const response: WorkoutExercice[] = await backend.fetchJson(
        `/api/workouts/${workoutId}/series/${workoutSerieId}/exercices`,
      );
      return response;
    },
    { enabled },
  );

  const UpdateWorkoutExercice = useMutation<WorkoutExercice, FetchError, {
    data: Partial<WorkoutExercice>,
    workoutId: string,
    workoutSerieId: string,
    workoutExericeId: string,
    updateEqualWeight?: boolean,
  }>(
    async (
      {
        data,
        workoutId,
        workoutSerieId,
        workoutExericeId,
        updateEqualWeight,
      },
    ) => {
      const response = await backend.patch(`/api/workouts/${workoutId}/series/${workoutSerieId}/exercices/${workoutExericeId}`, data, { updateEqualWeight: booleanToString(updateEqualWeight) || 'false' });
      return response;
    }, {
      onSuccess: (_, { workoutId }) => {
        queryClient.refetchQueries(['getWorkout', workoutId, sessionManager.getSession()?.user_id]);
      },
    },
  );

  const DeleteWorkoutExercice = useMutation<void, FetchError, {
    workoutId: string,
    workoutSerieId: string,
    workoutExericeId: string,
  }>(
    async (
      {
        workoutId,
        workoutSerieId,
        workoutExericeId,
      },
    ) => {
      const response = await backend.delete(`/api/workouts/${workoutId}/series/${workoutSerieId}/exercices/${workoutExericeId}`);
      return response;
    }, {
      onSuccess: (_, { workoutId }) => {
        queryClient.refetchQueries(['getWorkout', workoutId, sessionManager.getSession()?.user_id]);
      },
    },
  );

  const CreateWorkoutExercice = useMutation<WorkoutExercice, FetchError, {
    data: Partial<WorkoutExercice>,
    workoutId: string,
    workoutSerieId: string,
    updateEqualWeight?: boolean,
  }>(
    async (
      {
        data,
        workoutId,
        workoutSerieId,
        updateEqualWeight,
      },
    ) => {
      const response = await backend.post(`/api/workouts/${workoutId}/series/${workoutSerieId}/exercices`, data, { updateEqualWeight: booleanToString(updateEqualWeight) || 'false' });
      return response;
    }, {
      onSuccess: (_, { workoutId }) => {
        queryClient.refetchQueries(['getWorkout', workoutId, sessionManager.getSession()?.user_id]);
      },
    },
  );

  const CreateWorkoutSerie = useMutation<WorkoutSerie, FetchError, {
    data: Partial<WorkoutSerie>,
    workoutId: string,
  }>(
    async (
      {
        data,
        workoutId,
      },
    ) => {
      const response = await backend.post(`/api/workouts/${workoutId}/series`, data);
      return response;
    }, {
      onSuccess: (_, { workoutId }) => {
        queryClient.refetchQueries(['getWorkout', workoutId, sessionManager.getSession()?.user_id]);
      },
    },
  );

  return {
    startWorkout: StartWorkout,
    getCurrentWorkouts: GetCurrentWorkouts,
    getWorkouts: GetWorkouts,
    deleteWorkoutSerie: DeleteWorkoutSerie,
    updateWorkoutSerieOrder: UpdateWorkoutSerieOrder,
    getWorkout: GetWorkout,
    getWorkoutSeries: GetWorkoutSeries,
    getWorkoutSerie: GetWorkoutSerie,
    getWorkoutExercices: GetWorkoutExercices,
    updateWorkoutExercice: UpdateWorkoutExercice,
    endWorkout: EndWorkout,
    createWorkoutExercice: CreateWorkoutExercice,
    createWorkoutSerie: CreateWorkoutSerie,
    deleteWorkout: DeleteWorkout,
    deleteWorkoutExercice: DeleteWorkoutExercice,
  };
}
