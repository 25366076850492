import React from 'react';

import { useUserInformationsBackend } from 'network/queries/UserInformationsQueries';
import Messages from 'services/i18n/Messages';

export default function UserDailyNeedTile() {
  const { getUserDailyNeed } = useUserInformationsBackend();
  const { data: userDailyNeeds } = getUserDailyNeed();
  if (userDailyNeeds) {
    return (
      <>
        <div>{Messages.t('userInfos.kcal', { value: userDailyNeeds.kCals })}</div>
        <div>{Messages.t('userInfos.protein', { value: userDailyNeeds.proteins })}</div>
        <div>{Messages.t('userInfos.lipid', { value: userDailyNeeds.lipids })}</div>
        <div>{Messages.t('userInfos.carb', { value: userDailyNeeds.carbs })}</div>
      </>
    );
  }
  return <>profile pas remplie TODO front</>;
}
