import Messages from 'services/i18n/Messages';
import { format } from 'date-fns';
import localService from 'services/i18n/LocalService';

export default class DateUtils {
  public static getPrettyDateDeltaFromNow(
    date: string | undefined | number,
    fullDate = false,
    showSince = false,
  ): string {
    if (!date) {
      return Messages.t('dates.publishedDate.unknown');
    }
    const deltaInSeconds = Math.round((Date.now() - (new Date(date)).getTime()) / 1000);
    if (deltaInSeconds < 60) {
      return Messages.t('dates.publishedDate.now');
    }
    const deltaInMinutes = deltaInSeconds / 60;
    if (deltaInMinutes < 60) {
      return `${showSince ? Messages.t('dates.publishedDate.sinceDate') : ''}${Messages.t('dates.publishedDate.minutes', { deltaInMinutes: Math.round(deltaInMinutes) })}`;
    }
    const deltaInHours = deltaInMinutes / 60;
    if (deltaInHours < 24) {
      return `${showSince ? Messages.t('dates.publishedDate.sinceDate') : ''}${Messages.t('dates.publishedDate.hours', { deltaInHours: Math.round(deltaInHours) })}`;
    }
    if (fullDate) {
      return format(new Date(date), 'dd/MM/yyyy');
    }
    const deltaInDays = deltaInHours / 24;
    if (deltaInDays < 30) {
      return `${showSince ? Messages.t('dates.publishedDate.sinceDate') : ''}${Messages.t('dates.publishedDate.days', { deltaInDays: Math.round(deltaInDays) })}`;
    }
    const deltaInMonths = deltaInDays / 30;
    if (deltaInMonths < 12) {
      return `${showSince ? Messages.t('dates.publishedDate.sinceDate') : ''}${Messages.t('dates.publishedDate.months', { deltaInMonths: Math.round(deltaInMonths) })}`;
    }
    return `${showSince ? Messages.t('dates.publishedDate.sinceDate') : ''}${Messages.t('dates.publishedDate.years', { deltaInYears: Math.round(deltaInMonths / 12) })}`;
  }

  public static dateIsNullOtooOld(date: string): boolean {
    if (!date) {
      return true;
    }
    const deltaInSeconds = Math.round((Date.now() - (new Date(date)).getTime()) / 1000);
    const deltaInYear = deltaInSeconds / (60 * 60 * 24 * 30 * 12);
    return deltaInYear > 10;
  }

  public static getPositiveDaysDelta(firstDate?: Date, lastDate?: Date): number {
    if (!firstDate || !lastDate) {
      return 0;
    }
    const deltaInSeconds = Math.round((lastDate.getTime() - firstDate.getTime()) / 1000);
    const deltaInDays = deltaInSeconds / (60 * 60 * 24);
    if (deltaInDays < 0) {
      return 0;
    }
    return Math.trunc(deltaInDays);
  }

  public static getTimeString(date?: string): string | undefined {
    if (!date) {
      return undefined;
    }
    // Aligne the date with the current timezone https://stackoverflow.com/questions/69230915/the-datetime-minvalue-is-converted-into-0001-01-01-080543-in-javascript
    const dateAtTimeZone = new Date();
    const originalDate = new Date(date);
    dateAtTimeZone.setUTCHours(originalDate.getUTCHours());
    dateAtTimeZone.setUTCMinutes(originalDate.getUTCMinutes());
    return `${dateAtTimeZone.getHours().toString().padStart(2, '0')}:${dateAtTimeZone.getMinutes().toString().padStart(2, '0')}`;
  }

  public static formatDay(date?: string): string | undefined {
    if (!date) {
      return undefined;
    }
    return format(new Date(date), 'dd MMMM yyyy', { locale: localService.getDateLocal() });
  }

  public static getDaysDelta(firstDate?: Date, lastDate?: Date): number {
    if (!firstDate || !lastDate) {
      return 0;
    }
    const deltaInSeconds = Math.round((lastDate.getTime() - firstDate.getTime()) / 1000);
    const deltaInDays = deltaInSeconds / (60 * 60 * 24);
    return Math.trunc(deltaInDays);
  }

  public static getHoursString(date?: Date): string {
    if (!date) {
      return '';
    }
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  }

  static computeDayLightOffsetDelta = (date: Date): number => new Date()
    .getTimezoneOffset() - new Date(
    date.getUTCFullYear(),
    date.getMonth(),
    date.getDate(),
  ).getTimezoneOffset();
}
