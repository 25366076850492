import React, { useState } from 'react';
import Button from 'theme/Button';
import { useMealHistoryBackend } from 'network/queries/MealsHistoryQueries';
import Messages from 'services/i18n/Messages';
import DialogWrapper from 'lib/DialogWrapper';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import SpinButton from 'theme/SpinButton';
import { FetchError } from 'network/Errors';
import {
  MissingMealDay,
  UserMealFood,
} from 'types/types';
import { useMealBackend } from 'network/queries/MealsQueries';
import { mealApi } from 'network/api/MealApi';
import SelectWrapper from 'lib/form/SelectWrapper';

type Props = {
  onClose: () => void
  missingMeal: MissingMealDay,
};

type AddMealCreate = {
  mealTemplateId: string
};

export default function AddMealModal({ missingMeal, onClose }: Props) {
  const { createUserMeal } = useMealHistoryBackend();
  const { getUserMeals } = useMealBackend();
  const { data: meals } = getUserMeals();
  const [submitting, setSubmitting] = useState(false);
  const [apiError, setApiErrors] = useState({});

  const onSubmit: SubmitHandler<AddMealCreate> = async (
    data: AddMealCreate,
  ) => {
    setSubmitting(true);
    mealApi.getMealDetail(data.mealTemplateId)
      .then((mealDetail) => createUserMeal
        .mutateAsync({
          date: missingMeal.date,
          mealName: mealDetail.name,
          recurrentMealId: missingMeal.id,
          foods: mealDetail.meal?.foods?.map((food) => ({
            foodReferenceID: food.foodReferenceID,
            weight: food.weight,
          }) as UserMealFood),
        }))
      .catch((error: FetchError) => {
        if (error.json_response) {
          setApiErrors(error.json_response);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddMealCreate>({
    defaultValues: {},
  });

  return (
    <DialogWrapper open onClose={onClose}>
      <DialogTitle>{Messages.t('meal.addMeal')}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {
            meals && (
              <Controller
                name="mealTemplateId"
                control={control}
                render={(controller) => (
                  // TODO transform to autocomplete
                  <SelectWrapper
                    apiErrors={apiError}
                    error={errors}
                    control={controller}
                    label={Messages.t('field.meal')}
                    values={meals.map((meal) => ({
                      key: meal.id,
                      label: meal.name,
                    }))}
                  />
                )}
              />
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="error">{Messages.t('formButton.cancel')}</Button>
          <SpinButton
            editing
            className="submit-button"
            spin={submitting}
          >
            {Messages.t('formButton.add')}
          </SpinButton>
        </DialogActions>
      </form>
    </DialogWrapper>
  );
}
