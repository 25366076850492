import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessions/sessionManager';
import { FetchError } from 'network/Errors';
import { RecurrentMeal } from 'types/types';

export function useRecurrentMealsBackend() {
  const queryClient = useQueryClient();
  const GetUserRecurrentMeals = (
    enabled = true,
  ) => useQuery<RecurrentMeal[]>(
    ['GetRecurrentMeals', sessionManager.getSession()?.user_id],
    async () => {
      const response: RecurrentMeal[] = await backend.fetchJson(
        '/api/recurrent-meals',
      );
      return response;
    },
    {
      enabled,
    },
  );

  const DeleteRecurrentMeal = useMutation<void, FetchError, { recurrentMealId: string }>(
    async ({ recurrentMealId }) => {
      const response = await backend.delete(`/api/recurrent-meals/${recurrentMealId}`);
      return response;
    }, {
      onSuccess: () => {
        queryClient.refetchQueries(['GetRecurrentMeals', sessionManager.getSession()?.user_id]);
      },
    },
  );

  const CreateRecurrentMeal = useMutation<RecurrentMeal, FetchError, RecurrentMeal>(
    async (data) => {
      const response = await backend.post('/api/recurrent-meals', data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['GetRecurrentMeals']);
      },
    },
  );

  const UpdateRecurrentMeal = useMutation<RecurrentMeal, FetchError, {
    id: string,
    data: Partial<RecurrentMeal>,
  }>(
    async ({ id, data }) => {
      const response = await backend.patch(`/api/recurrent-meals/${id}`, data);
      return response;
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['GetRecurrentMeals', sessionManager.getSession()?.user_id]);
      },
    },
  );

  return {
    updateRecurrentMeal: UpdateRecurrentMeal,
    createRecurrentMeal: CreateRecurrentMeal,
    deleteRecurrentMeal: DeleteRecurrentMeal,
    getUserRecurrentMeals: GetUserRecurrentMeals,
  };
}
