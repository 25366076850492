import React, { ReactNode } from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
} from 'react-hook-form/dist/types/controller';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { getFinalErrorMessage } from 'lib/form/FormUtils';
import { UseFormStateReturn } from 'react-hook-form/dist/types';
import Messages from 'services/i18n/Messages';
import { Modification } from 'lib/Modification';
import FieldHistory from 'lib/form/FieldHistory';

type Props<T> = {
  control: {
    field: ControllerRenderProps<any, any>,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  }, // TODO Better type
  label?: string,
  values: { key: T, label: string | ReactNode, preElement?: ReactNode }[],
  error: FieldErrors,
  apiErrors?: { [key: string]: string[] }
  onClick?: (value: string) => void
  onChange?: (value: T) => void
  multiple?: boolean,
  requierd?: boolean,
  disableFullwidth?: boolean,
  action?: () => void,
  disabled?: boolean,
  actionLabel?: string,
  noLabel?: boolean,
  canSelectAll?: boolean,
  disableAutoFormUpdate?: boolean,
  modifications?: { [field: string]: Modification[] },
};

export default function SelectWrapper<T extends string>(
  {
    control,
    label,
    values,
    error,
    apiErrors,
    onClick,
    requierd,
    onChange,
    modifications,
    multiple,
    disableFullwidth,
    action,
    actionLabel,
    disabled,
    noLabel,
    disableAutoFormUpdate,
    canSelectAll,
  }: Props<T>,
) {
  const { field } = control;
  const errorMessage = getFinalErrorMessage(field.name, error, apiErrors);
  const changeValue = (value: T) => {
    if (onChange) {
      onChange(value);
    }
    if (!disableAutoFormUpdate) {
      field.onChange(value);
    }
    if (onClick) {
      value.split(',').forEach((key) => onClick(key));
    }
  };
  const fieldName = field.name.split('.').slice(-1)[0];
  const fieldModification = modifications && modifications[fieldName];

  return (
    <div className={`material-select-wrapper ${disableFullwidth ? 'no-full-widht' : ''}`}>
      <FormControl
        fullWidth={!disableFullwidth}
        error={!!errorMessage}
        disabled={disabled}
      >
        {!noLabel && <InputLabel>{label}{requierd ? ' *' : ''}</InputLabel>}
        <Select
          {...field}
          onChange={(e) => {
            const { value } = e.target;
            if (value === 'action_reserved_long_name' || value.includes('select_action_reserved_long_name')) {
              return;
            }
            changeValue(value);
          }}
          value={multiple ? field.value?.filter((v: string) => v !== '') || [] : field.value || ''}
          multiple={multiple}
          renderValue={multiple ? (selected) => {
            if (values.filter((value) => selected.includes(value.key)).length > 0) {
              return values.filter((value) => selected.includes(value.key)).map((value) => value.label).join(', ');
            }
            return selected.join(', ');
          } : (
            modifications && modifications[fieldName] && (
              (selected) => (
                <div
                  className={`history-select-render ${(
                    fieldModification
                    && fieldModification.length > 0
                    && fieldModification
                      .sort((a, b) => b.createdAt.localeCompare(a.createdAt))[0].updatedByAgent)
                    ? 'danger-text' : ''}`}
                >
                  <span>{values.filter((value) => value.key === selected)[0].label}</span>
                  <FieldHistory
                    history={modifications[fieldName]}
                    mapValue={
                      (value) => (values
                        .filter((key) => key.key === value)[0]?.label || value)
                    }
                  />
                </div>
              )
            )
          )}
          label={`${label}${requierd ? ' *' : ''}`}
        >
          {
            multiple && canSelectAll && (
              <MenuItem>
                {
                  field.value && field.value !== '' ? (
                    <button
                      className="select-all-button"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // @ts-ignore
                        changeValue('');
                      }}
                    >
                      {Messages.t('formButton.selectNone')}
                    </button>
                  ) : (
                    <button
                      className="select-all-button"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // @ts-ignore
                        changeValue(values.map((value) => value.key).join(','));
                      }}
                    >
                      {Messages.t('formButton.selectAll')}
                    </button>
                  )
                }
              </MenuItem>
            )
          }
          {
            values.map((value) => (
              <MenuItem
                onClick={() => onClick && onClick(value.key)}
                key={value.key}
                value={value.key}
              >
                {
                  multiple && (
                    <Checkbox checked={!!field.value?.includes(value.key)} />
                  )
                }
                <ListItemText className="list-item-text">{value.preElement}{value.label}</ListItemText>
              </MenuItem>
            ))
          }
          {
            action && actionLabel && (
              <MenuItem
                className="select-action"
                onClick={action}
                value="action_reserved_long_name"
                key="action_reserved_long_name"
              >
                <ListItemText>{actionLabel}</ListItemText>
              </MenuItem>
            )
          }
        </Select>
        {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </div>
  );
}
