import React, {
  useCallback,
  useState,
} from 'react';
import firebase from 'firebase/compat/app';
import { useAuth } from 'reactfire';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import 'firebase/compat/auth';
import sessionService from 'services/sessions/sessionService';
import SpinButton from 'theme/SpinButton';
import Messages from 'services/i18n/Messages';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { passwordRegex } from 'lib/form/FormUtils';
import { SignInFormType } from 'types/forms/SignInFormType';
import { Link } from 'react-router-dom';
import { LOG_IN } from 'routes/Routes';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as firebaseui from 'firebaseui';

export default function SignInForm() {
  const auth = useAuth();
  const [mailError, setMailError] = useState('');
  const [tokenError] = useState('');

  const uiCallback = useCallback((ui) => {
    ui.disableAutoSignIn();
  }, []);

  const uiConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (authResult) => {
        setSubmitting(true);
        sessionService.createSessionFromFirebase(authResult.user.accessToken).catch(() => {
          setMailError(Messages.t('error.invalidMail'));
        }).finally(() => setSubmitting(false));
        return false;
      },
    },
  };
  const [apiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SignInFormType>();

  const formField = watch();

  const onSubmit: SubmitHandler<SignInFormType> = (formData: SignInFormType) => {
    setSubmitting(true);
    sessionService.signIn(formData)
      .catch(() => {
        setMailError(Messages.t('error.mailInvalide'));
        setSubmitting(false);
      });
  };

  return (
    <div className="login-page">
      <h1>{Messages.t('application.name')}</h1>
      <div className="login-card signin-card">
        <h3>{Messages.t('login.fillData')}</h3>
        <form className="signed-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="mail-error">{tokenError}</div>
          <Controller
            name="mail"
            rules={{ required: true }}
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="text"
                control={controller}
                label={Messages.t('field.email')}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: passwordRegex,
                message: Messages.t('form.error.password'),
              },
            }}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="password"
                control={controller}
                label={Messages.t('field.password')}
              />
            )}
          />
          <Controller
            name="passwordConfirm"
            control={control}
            rules={{
              required: true,
              validate: (v) => v === formField.password || Messages.t('field.notMatchingPassword'),
            }}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                error={errors}
                type="password"
                control={controller}
                label={Messages.t('field.passwordConfirm')}
              />
            )}
          />
          <SpinButton
            editing
            className="connection-button"
            spin={submitting}
            title={Messages.t('formButton.confirm')}
          />
        </form>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={auth}
          uiCallback={uiCallback}
        />
        <div className="mail-error">{mailError}</div>
        <h5>{Messages.t('generics.or')}</h5>
        <Link className="link-as-button secondary" to={LOG_IN}>{Messages.t('formButton.connect')}</Link>
      </div>
    </div>
  );
}
