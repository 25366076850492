import React, { useState } from 'react';
import { useWorkoutTemplateBackend } from 'network/queries/WorkoutTemplateQueries';
import Messages from 'services/i18n/Messages';
import {
  Link,
  useHistory,
} from 'react-router-dom';
import {
  ID,
  MANAGE_WORKOUT_ADD,
  MANAGE_WORKOUT_EDIT,
} from 'routes/Routes';
import { Routes } from 'routes/RoutesUtils';
import {
  ChevronLeft,
  Delete,
  Edit,
} from '@mui/icons-material';
import confirmationService from 'services/ConfirmationService';
import { NotificationService } from 'lib/notification';
import Button from 'theme/Button';

export default function Workouts() {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const workoutTemplateQueries = useWorkoutTemplateBackend();
  const { getWorkoutTemplate, deleteWorkoutTemplate } = workoutTemplateQueries;
  const { data: workoutTemplates } = getWorkoutTemplate();

  const deleteWorkout = async (workoutTemplateId: string) => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('serie.delete'),
      message: Messages.t('global.delete'),
    });
    if (isConfirmed) {
      setSubmitting(true);
      deleteWorkoutTemplate.mutateAsync({ workoutTemplateId })
        .then(() => NotificationService.notifySuccess(Messages.t('notifications.delete')))
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <div className="page-body">
      <h2 role="presentation" onClick={() => history.goBack()}><ChevronLeft /> {Messages.t('manage.workouts')}</h2>
      {
        workoutTemplates?.map((workoutTemplate) => (
          <div className="serie-container">
            <Link
              className="serie-link"
              key={workoutTemplate.id}
              to={Routes.withPath(
                MANAGE_WORKOUT_EDIT,
                [{ label: ID, value: workoutTemplate.id }],
              )}
            >
              <div>
                {workoutTemplate.name}
              </div>
              <Button variant="icon">
                <Edit />
              </Button>
              <Button
                variant="icon"
                disabled={submitting}
                onClick={(e) => {
                  e.preventDefault();
                  deleteWorkout(workoutTemplate.id);
                }}
              >
                <Delete />
              </Button>
            </Link>
          </div>
        ))
      }
      <Link to={MANAGE_WORKOUT_ADD} className="link-as-button">
        {Messages.t('workout.create')}
      </Link>
    </div>
  );
}
