import React, { useState } from 'react';
import { useWorkoutTemplateBackend } from 'network/queries/WorkoutTemplateQueries';
import Messages from 'services/i18n/Messages';
import { useLocation } from 'react-router-dom';
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import { RouterPrompt } from 'lib/RouterPrompt';
import { NotificationService } from 'lib/notification';
import SpinButton from 'theme/SpinButton';
import { FetchError } from 'network/Errors';
import { WorkoutTemplate } from 'types/types';

export default function WorkoutCreate() {
  const [apiErrors, setApiErrors] = useState({});
  const location = useLocation();
  const workoutTemplateQueries = useWorkoutTemplateBackend();
  const { createWorkoutTemplate } = workoutTemplateQueries;
  const [submitting, setSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<WorkoutTemplate>();

  const onSubmit: SubmitHandler<WorkoutTemplate> = async (data: WorkoutTemplate) => {
    setSubmitting(true);
    createWorkoutTemplate.mutateAsync(data)
      .then(() => {
        NotificationService.notifySuccess(Messages.t('notifications.update'));
        reset(data);
      })
      .catch((error: FetchError) => {
        if (error.json_response) {
          setApiErrors(error.json_response);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="page-body">
      <h1>{Messages.t('manage.workouts')}</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RouterPrompt
          whenPageClose={isDirty}
          when={(newPathName) => isDirty && !newPathName.startsWith(location.pathname.split('/').slice(0, -1).join('/'))}
        />
        <h5>{Messages.t('field.name')}</h5>
        <Controller
          name="name"
          control={control}
          render={(controller) => (
            <TextFieldWrapper
              apiErrors={apiErrors}
              error={errors}
              control={controller}
              label={Messages.t('field.name')}
            />
          )}
        />
        <SpinButton
          editing
          className="submit-button"
          spin={submitting}
          title={Messages.t('formButton.confirm')}
        />
      </form>
    </div>
  );
}
