import React from 'react';
import NutritionnalRow from 'pages/common/foods/nutritionalTab/NutritionnalRow';
import Messages from 'services/i18n/Messages';
import { OpenFoodReference } from 'types/types';

type Props = {
  food: OpenFoodReference,
};

export default function NutritionnalTab({ food }: Props) {
  return (
    <div className="nutritional-tab">
      <div className="nutritional-row">
        <div>
          {Messages.t('nutrition.tab')}
        </div>
        <div>
          {Messages.t('nutrition.val100g')}
        </div>
        {
          food.servingQuantity && (
            <div>
              {Messages.t('nutrition.valServing', { servingQuantity: food.servingQuantity })}
            </div>
          )
        }

      </div>
      <NutritionnalRow
        label={Messages.t('nutrition.kcal')}
        servingQuantity={food.servingQuantity}
        value100g={food['energy-kcal100g']}
        unit="kcal"
      />
      <NutritionnalRow
        label={Messages.t('nutrition.protein')}
        servingQuantity={food.servingQuantity}
        value100g={food.proteins100g}
      />
      <NutritionnalRow
        label={Messages.t('nutrition.lipid')}
        servingQuantity={food.servingQuantity}
        value100g={food.fat100g}
      />
      <NutritionnalRow
        label={Messages.t('nutrition.lipidSat')}
        servingQuantity={food.servingQuantity}
        value100g={food['saturated-fat100g']}
      />
      <NutritionnalRow
        label={Messages.t('nutrition.carb')}
        servingQuantity={food.servingQuantity}
        value100g={food.carbohydrates100g}
      />
      <NutritionnalRow
        label={Messages.t('nutrition.sugar')}
        servingQuantity={food.servingQuantity}
        value100g={food.sugars100g}
      />
      <NutritionnalRow
        label={Messages.t('nutrition.fiber')}
        servingQuantity={food.servingQuantity}
        value100g={food.fiber100g}
      />
      <NutritionnalRow
        label={Messages.t('nutrition.salt')}
        servingQuantity={food.servingQuantity}
        value100g={food.salt100g}
      />
      <NutritionnalRow
        label={Messages.t('nutrition.glycemicIndex')}
        servingQuantity={food.servingQuantity}
        value100g={food['glycemic-index100g']}
        unit=" "
      />
    </div>
  );
}
