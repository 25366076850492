import React from 'react';
import { Fastfood } from '@mui/icons-material';
import Image from 'theme/Image';
import { Link } from 'react-router-dom';
import {
  MANAGE_FOODS_PAGE,
  ID,
} from 'routes/Routes';
import { Routes } from 'routes/RoutesUtils';
import { OpenFoodReference } from 'types/types';

type Props = {
  food: OpenFoodReference,
};

export default function FoodCard({ food }: Props) {
  return (
    <Link to={Routes.withPath(MANAGE_FOODS_PAGE, [{ label: ID, value: food.id }])} className="food-card">
      <div className="food-card-image-container">
        <Image alt="product" src={food.imageSmallURL} alternativElement={<Fastfood />} />
      </div>
      <div className="food-card-data">
        <div className="food-name">{food.productName}</div>
        <div className="food-brand">{food.brands}</div>
        <div className="food-quantity">{food.quantity}</div>
      </div>
    </Link>
  );
}
