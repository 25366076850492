import {
  useLocation,
  useHistory,
} from 'react-router-dom';

import { Routes } from 'routes/RoutesUtils';

import { useEffect } from 'react';

export default function useQueryParamState(
  initialValue: boolean,
  queryParamKey: string,
): [boolean, (state: boolean) => void] {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    if (initialValue) {
      setState(true);
    }
  }, []);

  const setState = (state: boolean) => {
    if (state) {
      history.replace(Routes.updatePathWithQuery(
        location,
        [{ label: queryParamKey, value: 'true' }],
      ));
    } else {
      queryParams.delete(queryParamKey);
      history.replace(Routes.removeParamFromQuery(
        location,
        queryParamKey,
      ));
    }
  };

  const state = queryParams.get(queryParamKey) === 'true';
  return [state, setState];
}
