export type Enum<T extends string> = {
  [key in T]: key
};
// TODO type all enum with this

type StringValues<T> = {
  [K in keyof T]: T[K] extends string ? T[K] : never;
}[keyof T];

type EnumAsUnion<T> = `${StringValues<T>}`;

export const getEnumValue = <E>(e: E): EnumAsUnion<E>[] => Object.values(e);
