import { useQuery } from 'react-query';
import backend from 'network/BackendFetchAdapter';
import sessionManager from 'services/sessions/sessionManager';
import { Pagination } from 'types/api/Pagination';
import { WeightHistory } from 'types/types';

export function useWeghtHistoryBackend() {
  const GetUserWeights = (
    pagination: Pagination,
    enabled = true,
  ) => useQuery<WeightHistory[]>(
    ['GetUserWeightHistory', sessionManager.getSession()?.user_id],
    async () => {
      const response: WeightHistory[] = await backend.fetchJson(
        '/api/weight-history',
        { ...pagination }
        ,
      );
      return response;
    },
    { enabled },
  );

  return {
    getUserWeights: GetUserWeights,
  };
}
