import React, { useState } from 'react';
import Messages from 'services/i18n/Messages';
import { useUserInformationsBackend } from 'network/queries/UserInformationsQueries';
import { UnitsService } from 'services/UnitsService';
import Button from 'theme/Button';
import DialogWrapper from 'lib/DialogWrapper';
import {
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import {
  Controller,
  useForm,
  SubmitHandler,
} from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import SpinButton from 'theme/SpinButton';
import { FetchError } from 'network/Errors';
import { ChevronRight } from '@mui/icons-material';
import { useWeghtHistoryBackend } from 'network/queries/WeightHistoryQueries';
import DateUtils from 'services/utils/DateUtils';

export default function WeightTile() {
  const [apiErrors, setApiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const { getUserWeights } = useWeghtHistoryBackend();
  const { data: userWeights } = getUserWeights({ page: 0, size: 1 });
  const { updateUserInformations } = useUserInformationsBackend();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ weight: number }>();

  const onSubmit: SubmitHandler<{ weight: number }> = async (
    data: { weight: number },
  ) => {
    setSubmitting(true);
    updateUserInformations.mutateAsync({
      data,
    })
      .then(() => {
        reset();
        setShowModal(false);
      })
      .catch((error: FetchError) => {
        if (error.json_response) {
          setApiErrors(error.json_response);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <DialogWrapper open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle>{Messages.t('dashboard.weightTile.addWeight')}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <div className="form-row">
              <Controller
                name="weight"
                control={control}
                render={(controller) => (
                  <TextFieldWrapper
                    apiErrors={apiErrors}
                    type="number"
                    error={errors}
                    control={controller}
                    label={Messages.t('field.weight')}
                  />
                )}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowModal(false)}
              disabled={submitting}
            >
              {Messages.t('formButton.cancel')}
            </Button>
            <SpinButton
              editing
              className="submit-button"
              spin={submitting}
              title={Messages.t('formButton.confirm')}
            />
          </DialogActions>
        </form>
      </DialogWrapper>
      <div role="presentation" className="tile" onClick={() => setShowModal(true)}>
        <div className="tile-title-container">
          <div className="tile-title">
            {Messages.t('dashboard.weightTile.currentWeight')}
            <ChevronRight />
          </div>
          <div className="tile-subtitle">
            {userWeights && Messages.t('global.lastUpdated', { date: DateUtils.formatDay(userWeights[0]?.date) })}
          </div>
        </div>
        <div>
          {userWeights && UnitsService.getWeightLabel(userWeights[0]?.weight)}
        </div>
      </div>
    </>
  );
}
