import React, {
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  ChevronRight,
  DragHandle,
} from '@mui/icons-material';

type Props = {
  action?: ReactNode,
  children: ReactNode,
  label: string,
  defaultHidden?: boolean,
  showDragHandle?: boolean,

  isFetching: boolean,
};

export default function ExpendableContainer(
  {
    action,
    label,
    children,
    showDragHandle,
    defaultHidden,
    isFetching,
  }: Props,
) {
  const [isOpen, setIsOpen] = useState(!defaultHidden);
  const [initialHeight, setInitialHeight] = useState<number | undefined>(undefined);
  const ref = useRef<HTMLDivElement>(null);

  const setRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      setInitialHeight(node.offsetHeight);

      // @ts-ignore
      ref.current = node;
    }
  }, [isFetching]);
  const containerHeight = (isOpen) ? (initialHeight || ref.current?.offsetHeight) : 0;

  const extendContainer = () => {
    setIsOpen(((prevState) => !prevState));
  };

  return (
    <div className="extendable-container">
      <div className="extendable-header-containter">
        {showDragHandle && <DragHandle />}
        <button
          type="button"
          onClick={extendContainer}
          className="extendable-header"
        >
          {!action && <span>{label}</span>}
          <ChevronRight className={`extend-icon ${isOpen ? 'extended' : ''}`} />
          {!!action && <span>{label}</span>}
        </button>
        {action}
      </div>
      <div style={containerHeight !== undefined ? { height: containerHeight } : {}} className="extendable-body">
        <div className="extendable-content" ref={setRef}>
          {children}
        </div>
      </div>
    </div>
  );
}
