import React, {
  useState,
  useEffect,
} from 'react';
import Messages from 'services/i18n/Messages';
import {
  useLocation,
  useHistory,
} from 'react-router-dom';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import FiltersUtils from 'services/filters/FiltersUtils';
import { Routes } from 'routes/RoutesUtils';
import Button from 'theme/Button';
import { Drawer } from '@mui/material';
import { Tune } from '@mui/icons-material';
import { ActivityFilter } from 'network/queries/ActivityQueries';
import SelectWrapper from 'lib/form/SelectWrapper';
import { getEnumValue } from 'lib/type/enum';
import { ActivityType } from 'types/types';

export default function ActivityFilters() {
  const location = useLocation();
  const history = useHistory();
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const defaultFilter = FiltersUtils.getObjectFromQueryParam<ActivityFilter>({
    dateRange: [],
    type: [],
  }, queryParams);
  const {
    control,
    watch,
  } = useForm<ActivityFilter>(
    {
      defaultValues: {
        ...defaultFilter,
      },
    },
  );

  const values = watch();

  useEffect(() => {
    history.replace(Routes.updatePathWithQuery(
      location,
      FiltersUtils.getQueryParamsFromObject({ ...values }),
    ));
  }, [
    values.type,
  ]);

  return (
    <form>
      <Button roundedIcon variant="icon" onClick={() => setShowMoreFilters(true)}><Tune /></Button>
      <Drawer anchor="right" open={showMoreFilters} onClose={() => setShowMoreFilters(false)}>
        <Controller
          name="type"
          control={control}
          render={(controller) => (
            <SelectWrapper
              apiErrors={{}}
              error={{}}
              multiple
              control={controller}
              values={
                getEnumValue(ActivityType)
                  .map((activity) => ({ key: activity, label: Messages.t(`activity.type.${activity}`, { name: '' }) }))
              }
              label={Messages.t('field.type')}
            />
          )}
        />
      </Drawer>
    </form>
  );
}
