import React from 'react';
import Messages from 'services/i18n/Messages';
import {
  ChevronLeft,
} from '@mui/icons-material';
import { useMealHistoryBackend } from 'network/queries/MealsHistoryQueries';
import { useHistory } from 'react-router-dom';
import {
  MissingMealDay,
} from 'types/types';
import DateUtils from 'services/utils/DateUtils';
import MissingMealTile from 'pages/swole-app/manage/missingMeals/MissingMealTile';

export default function MissiFFngMeals() {
  const history = useHistory();
  const { getUserMissingMeals } = useMealHistoryBackend();
  const { data: missingMeals } = getUserMissingMeals();

  const missingMealByDay = missingMeals?.reduce((acc: Record<string, MissingMealDay[]>, v) => {
    const day = v.date;
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(v);
    return acc;
  }, {});

  return (
    <div className="page-body">
      <h2 role="presentation" onClick={() => history.goBack()}><ChevronLeft /> {Messages.t('manage.missingMeals')}</h2>
      {
        missingMealByDay && Object.keys(missingMealByDay)
          .sort((a, b) => b.localeCompare(a))
          .map((date) => (
            <div key={date}>
              <div>{DateUtils.formatDay(date) || ''}</div>
              <hr />
              <div>
                {
                  missingMealByDay[date].sort((a, b) => a.order - b.order).map((missingMeal) => (
                    <div key={`${date}${missingMeal.id}`} className="activity-tile-container">
                      <MissingMealTile missingMeal={missingMeal} />
                    </div>
                  ))
                }
              </div>
            </div>
          ))
      }
    </div>
  );
}
