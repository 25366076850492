import React from 'react';
import { useParams } from 'react-router-dom';
import { useWorkoutBackend } from 'network/queries/WorkoutQueries';
import WorkoutDetails from 'pages/swole-app/workouts/workout/WorkoutDetails';
import { useActivityBackend } from 'network/queries/ActivityQueries';
import ActivityEdit from 'pages/swole-app/activities/activity/activityForm/ActivityEdit';
import Timer from 'lib/Timer';
import { ActivityType } from 'types/types';

type Param = {
  id: string,
};
export default function ActivityDetails() {
  const { id } = useParams<Param>();

  const { getUserActivity } = useActivityBackend();
  const { data: activity } = getUserActivity(id);

  const { getWorkout } = useWorkoutBackend();
  const { data: workout, isFetching } = getWorkout(
    id,
    !!activity && activity.type === ActivityType.ActivityTypeWorkout,
  );

  return (
    <>
      {
        !!workout && !workout.endDate && <Timer startDate={new Date(workout.startDate)} />
      }
      <div className="page-body">
        {
          activity && !(!!workout && !workout.endDate) && <ActivityEdit activity={activity} />
        }
        {
          workout && <WorkoutDetails isFetching={isFetching} workout={workout} />
        }
      </div>
    </>
  );
}
