/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActivityCreation {
  comment?: string;
  createdAt: string;
  endDate?: string;
  id: string;
  name?: string;
  startDate: string;
  type: ActivityType;
  updatedAt: string;
  userID: string;
  workoutTemplateID?: string;
}

export interface Password {
  password?: string;
}

export interface FrameworkPaginationResultModelOpenFoodReference {
  data?: OpenFoodReference[];
  esIndex?: any[];
  page?: number;
  total?: number;
}

export interface MealDetailHistory {
  foods?: MealFoodDetailHistory[];
}

export interface MealFoodDetailHistory {
  createdAt: string;
  foodReference?: OpenFoodReference;
  foodReferenceID: string;
  id: string;
  updatedAt: string;
  userMealHistoryID: string;
  weight: number;
}

export interface MealHistoryDetail {
  createdAt: string;
  date: string;
  id: string;
  isExcluded: boolean;
  meal?: MealDetailHistory;
  mealSkipped: boolean;
  name?: string;
  recurrentMealID?: string;
  updatedAt: string;
  userID: string;
}

export interface MealHistoryCreation {
  date?: string;
  foods?: UserMealFood[];
  isExcluded?: boolean;
  mealName?: string;
  mealSkipped?: boolean;
  name?: string;
  onlyCreateRecipe?: boolean;
  recipePortions?: number;
  recurrentMealId?: string;
}

export interface Activity {
  comment?: string;
  createdAt: string;
  endDate?: string;
  id: string;
  name?: string;
  startDate: string;
  type: ActivityType;
  updatedAt: string;
  userID: string;
}

export enum ActivityType {
  ActivityTypeWorkout = "WORKOUT",
  ActivityTypeMeal = "MEAL",
  ActivityTypeTennis = "TENNIS",
  ActivityTypeClimbing = "CLIMBING",
  ActivityTypeYoga = "YOGA",
  ActivityTypeBoxe = "BOXE",
  ActivityTypeSki = "SKI",
}

export interface Exercice {
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
}

export interface FoodBrand {
  count?: number;
  id: string;
  name: string;
}

export interface FoodGroup1 {
  id: string;
  label?: string;
  name: string;
}

export interface FoodGroup2 {
  id: string;
  label?: string;
  name: string;
}

export interface FoodType {
  count?: number;
  id: string;
  label?: string;
  name: string;
}

export interface MealHistory {
  createdAt: string;
  date: string;
  id: string;
  isExcluded: boolean;
  mealSkipped: boolean;
  name?: string;
  recurrentMealID?: string;
  updatedAt: string;
  userID: string;
}

export enum MealIcon {
  MealIconCake = "CAKE",
  MealIconBakery = "BAKERY",
  MealIconLunch = "LUNCH",
  MealIconRamen = "RAMEN",
  MealIconDinner = "DINNER",
  MealIconTapas = "TAPAS",
  MealIconSoupe = "SOUPE",
}

export interface OpenFoodReference {
  abbreviatedProductName?: string;
  acidity100g?: number;
  "added-salt100g"?: number;
  "added-sugars100g"?: number;
  additives?: string;
  additivesFr?: string;
  additivesN?: number;
  additivesTags?: string;
  alcohol100g?: number;
  allergens?: string;
  allergensFr?: string;
  "alpha-linolenic-acid100g"?: number;
  "arachidic-acid100g"?: number;
  "arachidonic-acid100g"?: number;
  "behenic-acid100g"?: number;
  "beta-carotene100g"?: number;
  "beta-glucan100g"?: number;
  bicarbonate100g?: number;
  biotin100g?: number;
  brandOwner?: string;
  brands?: string;
  brandsTags?: string;
  "butyric-acid100g"?: number;
  caffeine100g?: number;
  calcium100g?: number;
  "capric-acid100g"?: number;
  "caproic-acid100g"?: number;
  "caprylic-acid100g"?: number;
  carbohydrates100g?: number;
  "carbon-footprint-from-meat-or-fish100g"?: number;
  "carbon-footprint100g"?: number;
  carnitine100g?: number;
  casein100g?: number;
  categories?: string;
  categoriesFr?: string;
  categoriesTags?: string;
  "cerotic-acid100g"?: number;
  chloride100g?: number;
  chlorophyl100g?: number;
  cholesterol100g?: number;
  choline100g?: number;
  chromium100g?: number;
  ciqualID?: number;
  cities?: string;
  citiesTags?: string;
  cocoa100g?: number;
  code?: string;
  "collagen-meat-protein-ratio100g"?: number;
  completeness?: number;
  copper100g?: number;
  countries?: string;
  countriesFr?: string;
  countriesTags?: string;
  createdDatetime?: string;
  createdT?: number;
  creator?: string;
  dataQualityErrorsTags?: string;
  "dihomo-gamma-linolenic-acid100g"?: number;
  "docosahexaenoic-acid100g"?: number;
  ecoscoreGrade?: string;
  ecoscoreScore?: number;
  "eicosapentaenoic-acid100g"?: number;
  "elaidic-acid100g"?: number;
  embCodes?: string;
  embCodesTags?: string;
  "energy-from-fat100g"?: number;
  "energy-kcal100g"?: number;
  "energy-kj100g"?: number;
  energy100g?: number;
  "erucic-acid100g"?: number;
  erythritol100g?: number;
  fat100g?: number;
  fiber100g?: number;
  firstPackagingCodeGeo?: string;
  fluoride100g?: number;
  folates100g?: number;
  foodGroups?: string;
  foodGroupsFr?: string;
  foodGroupsTags?: string;
  foodType?: string;
  fructose100g?: number;
  "fruits-vegetables-nuts-dried100g"?: number;
  "fruits-vegetables-nuts-estimate-from-ingredients100g"?: number;
  "fruits-vegetables-nuts-estimate100g"?: number;
  "fruits-vegetables-nuts100g"?: number;
  "gamma-linolenic-acid100g"?: number;
  genericName?: string;
  glucose100g?: number;
  "glycemic-index100g"?: number;
  "gondoic-acid100g"?: number;
  id: string;
  imageIngredientsSmallURL?: string;
  imageIngredientsURL?: string;
  imageNutritionSmallURL?: string;
  imageNutritionURL?: string;
  imageSmallURL?: string;
  imageURL?: string;
  ingredientsAnalysisTags?: string;
  ingredientsTags?: string;
  ingredientsText?: string;
  inositol100g?: number;
  "insoluble-fiber100g"?: number;
  iodine100g?: number;
  iron100g?: number;
  isFr?: boolean;
  isGeneric: boolean;
  labels?: string;
  labelsFr?: string;
  labelsTags?: string;
  lactose100g?: number;
  lastImageDatetime?: string;
  lastImageT?: number;
  lastModifiedBy?: string;
  lastModifiedDatetime?: string;
  lastModifiedT?: number;
  lastUpdatedDatetime?: string;
  lastUpdatedT?: number;
  "lauric-acid100g"?: number;
  "lignoceric-acid100g"?: number;
  "linoleic-acid100g"?: number;
  magnesium100g?: number;
  mainCategory?: string;
  mainCategoryFr?: string;
  maltodextrins100g?: number;
  maltose100g?: number;
  manganese100g?: number;
  manufacturingPlaces?: string;
  manufacturingPlacesTags?: string;
  "mead-acid100g"?: number;
  "melissic-acid100g"?: number;
  molybdenum100g?: number;
  "monounsaturated-fat100g"?: number;
  "montanic-acid100g"?: number;
  "myristic-acid100g"?: number;
  "nervonic-acid100g"?: number;
  nitrate100g?: number;
  noNutritionData?: string;
  novaGroup?: number;
  nucleotides100g?: number;
  nutrientLevelsTags?: string;
  nutriscoreGrade?: string;
  nutriscoreScore?: number;
  "nutrition-score-fr100g"?: number;
  "nutrition-score-uk100g"?: number;
  "oleic-acid100g"?: number;
  "omega-3-fat100g"?: number;
  "omega-6-fat100g"?: number;
  "omega-9-fat100g"?: number;
  origins?: string;
  originsFr?: string;
  originsTags?: string;
  owner?: string;
  packaging?: string;
  packagingFr?: string;
  packagingTags?: string;
  packagingText?: string;
  "palmitic-acid100g"?: number;
  "pantothenic-acid100g"?: number;
  ph100g?: number;
  phosphorus100g?: number;
  phylloquinone100g?: number;
  pnnsGroups1?: string;
  pnnsGroups2?: string;
  polyols100g?: number;
  "polyunsaturated-fat100g"?: number;
  popularityTags?: string;
  potassium100g?: number;
  productName?: string;
  productQuantity?: number;
  proteins100g?: number;
  purchasePlaces?: string;
  quantity?: string;
  salt100g?: number;
  "saturated-fat100g"?: number;
  selenium100g?: number;
  "serum-proteins100g"?: number;
  servingQuantity?: number;
  servingSize?: string;
  silica100g?: number;
  sodium100g?: number;
  "soluble-fiber100g"?: number;
  starch100g?: number;
  states?: string;
  statesFr?: string;
  statesTags?: string;
  "stearic-acid100g"?: number;
  stores?: string;
  sucrose100g?: number;
  sugars100g?: number;
  sulphate100g?: number;
  taurine100g?: number;
  traces?: string;
  tracesFr?: string;
  tracesTags?: string;
  "trans-fat100g"?: number;
  uniqueScansN?: number;
  "unsaturated-fat100g"?: number;
  url?: string;
  "vitamin-a100g"?: number;
  "vitamin-b1100g"?: number;
  "vitamin-b12100g"?: number;
  "vitamin-b2100g"?: number;
  "vitamin-b6100g"?: number;
  "vitamin-b9100g"?: number;
  "vitamin-c100g"?: number;
  "vitamin-d100g"?: number;
  "vitamin-e100g"?: number;
  "vitamin-k100g"?: number;
  "vitamin-pp100g"?: number;
  "water-hardness100g"?: number;
  zinc100g?: number;
}

export interface RecurrentMeal {
  createdAt?: string;
  icon?: MealIcon;
  id: string;
  isOptional: boolean;
  name: string;
  order: number;
  updatedAt?: string;
  userID: string;
}

export enum UserActivityIntensity {
  UserActivityIntensityNone = "none",
  UserActivityIntensityLow = "low",
  UserActivityIntensityAverage = "average",
  UserActivityIntensityHigh = "high",
}

export enum UserGender {
  UserGenderMale = "male",
  UserGenderFemale = "female",
}

export interface UserInformations {
  activityIntensity?: UserActivityIntensity;
  birthday?: string;
  createdAt: string;
  gender?: UserGender;
  height?: number;
  id: string;
  updatedAt: string;
  userID: string;
  weight?: number;
}

export interface UserMealFood {
  createdAt: string;
  foodReferenceID: string;
  id: string;
  updatedAt: string;
  userMealHistoryID: string;
  weight: number;
}

export interface UserMealTemplate {
  createdAt: string;
  id: string;
  name: string;
  portions: number;
  updatedAt: string;
  userID: string;
}

export interface WeightHistory {
  createdAt: string;
  date: string;
  id: string;
  updatedAt: string;
  userID: string;
  weight: number;
}

export interface Workout {
  createdAt: string;
  endDate?: string;
  id: string;
  name: string;
  startDate: string;
  updatedAt: string;
  userID: string;
  workoutTemplateID: string;
}

export interface WorkoutExercice {
  createdAt: string;
  id: string;
  order: number;
  repetitionNumber?: number;
  repetitionNumberObjectiv: number;
  updatedAt: string;
  weight?: number;
  weightObjectiv?: number;
  workoutSerieID: string;
}

export interface WorkoutSerie {
  createdAt: string;
  exerciceID: string;
  id: string;
  order: number;
  updatedAt: string;
  workoutID: string;
}

export interface WorkoutTemplate {
  createdAt: string;
  id: string;
  name: string;
  updatedAt: string;
  userID: string;
}

export interface WorkoutTemplateExercice {
  createdAt: string;
  id: string;
  order: number;
  repetitionNumber: number;
  updatedAt: string;
  weight?: number;
  workoutTemplateSerieID: string;
}

export interface WorkoutTemplateSerie {
  createdAt: string;
  exerciceID: string;
  id: string;
  order: number;
  updatedAt: string;
  workoutTemplateID: string;
}

export interface MissingMealDay {
  date: string;
  icon?: MealIcon;
  id: string;
  name: string;
  order: number;
}

export interface UserDailyNeed {
  carbs?: number;
  kCals?: number;
  lipids?: number;
  proteins?: number;
}

export interface MealDetail {
  foods?: MealFoodDetail[];
}

export interface MealFoodDetail {
  createdAt: string;
  foodReference?: OpenFoodReference;
  foodReferenceID: string;
  id: string;
  updatedAt: string;
  userMealID: string;
  weight: number;
}

export interface MealTemplateDetail {
  createdAt: string;
  id: string;
  meal?: MealDetail;
  name: string;
  portions: number;
  updatedAt: string;
  userID: string;
}

export interface WorkoutDetailled {
  createdAt: string;
  endDate?: string;
  id: string;
  name: string;
  series?: WorkoutSerieDetailled[];
  startDate: string;
  updatedAt: string;
  userID: string;
  workoutTemplateID: string;
}

export interface WorkoutSerieDetailled {
  createdAt: string;
  exerciceID: string;
  exercices?: WorkoutExercice[];
  id: string;
  order: number;
  updatedAt: string;
  workoutID: string;
}

export interface WorkoutCreation {
  workoutTemplateID?: string;
}
