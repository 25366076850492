import React, { useState } from 'react';
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { FetchError } from 'network/Errors';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import Messages from 'services/i18n/Messages';
import SpinButton from 'theme/SpinButton';
import DialogWrapper from 'lib/DialogWrapper';
import {
  DialogActions,
  DialogContent,
} from '@mui/material';
import confirmationService from 'services/ConfirmationService';
import { NotificationService } from 'lib/notification';
import { useWorkoutTemplateBackend } from 'network/queries/WorkoutTemplateQueries';
import CheckboxWrapper from 'lib/form/CheckboxWrapper';
import { WorkoutTemplateExercice } from 'types/types';

type Props = {
  workoutId: string,
  workoutExercice: WorkoutTemplateExercice,
  onClose: () => void,
};

type WorkoutExerciceUpdate = WorkoutTemplateExercice & { updateEqualWeight: boolean };

export default function ExerciceTemplateModal({ workoutExercice, workoutId, onClose }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const [apiErrors, setApiErrors] = useState({});

  const {
    updateWorkoutTemplateExercice,
    deleteWorkoutTemplateExercice,
  } = useWorkoutTemplateBackend();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<WorkoutExerciceUpdate>({
    defaultValues: {
      updateEqualWeight: true,
      weight: workoutExercice.weight,
      repetitionNumber: workoutExercice.repetitionNumber,
    },
  });

  const deleteExercice = async () => {
    const isConfirmed = await confirmationService.confirm({
      title: Messages.t('workout.delete'),
      message: Messages.t('global.delete'),
    });
    if (isConfirmed) {
      setSubmitting(true);
      deleteWorkoutTemplateExercice.mutateAsync({
        workoutTemplateId: workoutId,
        workoutTemplateSerieId: workoutExercice.workoutTemplateSerieID,
        workoutTemplateExerciceId: workoutExercice.id,
      })
        .then(() => {
          NotificationService.notifySuccess(Messages.t('notifications.delete'));
        })
        .finally(() => setSubmitting(false));
    }
  };

  const onSubmit: SubmitHandler<WorkoutExerciceUpdate> = async (
    data: WorkoutExerciceUpdate,
  ) => {
    setSubmitting(true);
    updateWorkoutTemplateExercice.mutateAsync({
      data,
      workoutId,
      workoutSerieId: workoutExercice.workoutTemplateSerieID,
      workoutExericeId: workoutExercice.id,
      updateEqualWeight: data.updateEqualWeight,
    })
      .then(() => {
        reset(data);
        onClose();
      })
      .catch((error: FetchError) => {
        if (error.json_response) {
          setApiErrors(error.json_response);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <DialogWrapper open onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div className="form-row">
            <Controller
              name="weight"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  type="number"
                  error={errors}
                  control={controller}
                  label={Messages.t('field.weight')}
                />
              )}
            />
            <Controller
              name="repetitionNumber"
              control={control}
              render={(controller) => (
                <TextFieldWrapper
                  apiErrors={apiErrors}
                  type="number"
                  error={errors}
                  control={controller}
                  label={Messages.t('field.repetitionNumber')}
                />
              )}
            />
          </div>
          <Controller
            name="updateEqualWeight"
            control={control}
            render={(controller) => (
              <CheckboxWrapper
                apiErrors={apiErrors}
                error={errors}
                control={controller}
                label={Messages.t('workout.updateWeight.text')}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <SpinButton
            type="button"
            color="error"
            editing
            className="submit-button"
            onClick={deleteExercice}
            spin={submitting}
            title={Messages.t('formButton.delete')}
          />
          <SpinButton
            editing
            className="submit-button"
            spin={submitting}
            title={Messages.t('formButton.confirm')}
          />
        </DialogActions>
      </form>
    </DialogWrapper>
  );
}
