/* eslint-disable react/no-array-index-key,react/jsx-indent */

import React, {
  useRef,
  MouseEvent,
  MutableRefObject,
} from 'react';
import {
  EventProps,
  Event,
  DateLocalizer,
  EventPropGetter,
  SlotPropGetter,
  DayPropGetter,
  ViewStatic,
  TitleOptions,
  NavigateAction,
} from 'react-big-calendar';
import { format } from 'date-fns';
import InfiniteScroll from 'lib/InfiniteScroll';

const navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
};

function isSelected<T>(event: T, selected: T) {
  if (!event || selected == null) {
    return false;
  }
  return event === selected;
}

const DEFAULT_LENGTH = 30;

type Props<T extends Event> = {
  accessors: {
    title: ((event: T) => string);
    tooltip: ((event: T) => string);
    end: ((event: T) => Date);
    start: ((event: T) => Date);
    allDay: ((event: T) => boolean);
  },
  components: {
    date: React.ComponentType;
    time: React.ComponentType;
    event: React.ComponentType<EventProps<T>>;
  },
  date: Date,
  events: T[],
  getters: {
    eventProp: EventPropGetter<T>;
    slotProp: SlotPropGetter;
    dayProp: DayPropGetter;
  },
  length?: number,
  localizer: DateLocalizer;
  onSelectEvent?: (event: T, e: MouseEvent) => void,
  onDoubleClickEvent?: (event: T, e: MouseEvent) => void,
  selected: any,
  isLoading?: boolean,
  isFetchingNextPage: boolean,
  hasNextPage?: boolean,
  fetchNextPage: () => void,
  scrollableDivRef?: MutableRefObject<HTMLDivElement | null>,
};

function AgendaView<T extends Event>(
  {
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading,
    accessors,
    components,
    scrollableDivRef,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    date,
    events,
    getters,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    length = 30,
    localizer,
    onDoubleClickEvent,
    onSelectEvent,
    selected,
  }: Props<T>,
) {
  const headerRef = useRef<HTMLTableElement>(null);
  const dateColRef = useRef<HTMLTableCellElement>(null);
  const timeColRef = useRef<HTMLTableCellElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const tbodyRef = useRef<HTMLTableSectionElement>(null);

  const renderDay = (eventsDay: T[], dateKey: string) => {
    const { event: EventComponent, date: AgendaDateComponent } = components;

    return eventsDay.sort((a, b) => +accessors.start(b) - +accessors.start(a)).map((event, idx) => {
      const title = accessors.title(event);
      const end = accessors.end(event);
      const start = accessors.start(event);

      const userProps = getters.eventProp(
        event,
        start,
        end,
        isSelected(event, selected),
      );

      const dateLabel = idx === 0 && localizer.format(accessors.start(event), 'agendaDateFormat');
      const first = idx === 0 ? (
        <td rowSpan={events.length} className="rbc-agenda-date-cell day-header">
          {AgendaDateComponent ? (
            // @ts-ignore
            <AgendaDateComponent day={accessors.start(event)} label={dateLabel} />
          ) : (
            dateLabel
          )}
        </td>
      ) : false;

      return (
        <tr
          key={`${dateKey}_${idx}`}
          className={userProps.className}
          style={userProps.style}
        >
          {first}
          <td className="rbc-agenda-time-cell">{timeRangeLabel(accessors.start(event), event)}</td>
          <td
            role="presentation"
            className="rbc-agenda-event-cell"
            onClick={(e) => onSelectEvent && onSelectEvent(event, e)}
            onDoubleClick={(e) => onDoubleClickEvent && onDoubleClickEvent(event, e)}
          >
            {
              EventComponent ? (
                // @ts-ignore
                <EventComponent event={event} title={title} />
              ) : title
            }
          </td>
        </tr>
      );
    }, []);
  };

  const timeRangeLabel = (day: Date, event: T) => {
    let labelClass = '';
    const TimeComponent = components.time;
    let label = localizer.messages.allDay;

    const end = accessors.end(event);
    const start = accessors.start(event);

    if (!accessors.allDay(event)) {
      if (localizer.eq(start, end)) {
        label = localizer.format(start, 'agendaTimeFormat');
      } else if (localizer.isSameDate(start, end)) {
        // @ts-ignore
        label = localizer.format({ start, end }, 'agendaTimeRangeFormat');
      } else if (localizer.isSameDate(day, start)) {
        label = localizer.format(start, 'agendaTimeFormat');
      } else if (localizer.isSameDate(day, end)) {
        label = localizer.format(end, 'agendaTimeFormat');
      }
    }

    if (localizer.gt(day, start, 'day')) {
      labelClass = 'rbc-continues-prior';
    }
    if (localizer.lt(day, end, 'day')) {
      labelClass += ' rbc-continues-after';
    }

    return (
      <span className={labelClass.trim()}>
        {TimeComponent ? (
          // @ts-ignore
          <TimeComponent event={event} day={day} label={label} />
        ) : (
          label
        )}
      </span>
    );
  };

  const { messages } = localizer;

  const eventsDisplay = events
    .reduce((acc: Record<string, T[]>, event: T) => {
      const dayKey = format(+accessors.start(event), 'yyyyMMdd');
      if (!acc[dayKey]) {
        acc[dayKey] = [];
      }
      acc[dayKey].push(event);
      return acc;
    }, {} as Record<string, T[]>);
  const getAgenda = () => (
    <div className="rbc-agenda-view">
      {eventsDisplay !== {} ? (
        <>
          <table ref={headerRef} className="rbc-agenda-table">
            <thead>
            <tr>
              <th className="rbc-header" ref={dateColRef}>
                {messages.date}
              </th>
              <th className="rbc-header" ref={timeColRef}>
                {messages.time}
              </th>
              <th className="rbc-header">{messages.event}</th>
            </tr>
            </thead>
          </table>
          <div className="rbc-agenda-content" ref={contentRef}>
            <table className="rbc-agenda-table">
              <tbody ref={tbodyRef}>
              <InfiniteScroll
                isLoading={isLoading || isFetchingNextPage}
                next={fetchNextPage}
                size={events.length}
                hasNext={!!hasNextPage}
                scrollableDivRef={scrollableDivRef}
                childClassName="food-card-container"
              >
                {
                  Object.keys(eventsDisplay)
                    .sort((a, b) => b.localeCompare(a))
                    .map((key) => renderDay(eventsDisplay[key], key))
                }
              </InfiniteScroll>
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <span className="rbc-agenda-empty">{messages.noEventsInRange}</span>
      )}
    </div>
  );
  return (
    getAgenda()
  );
}

AgendaView.range = (start: Date, { length = DEFAULT_LENGTH, localizer }: TitleOptions) => {
  const end = localizer.add(start, length, 'day');
  return { start, end };
};

AgendaView.navigate = (
  date: Date,
  action: NavigateAction,
  { length = DEFAULT_LENGTH, localizer }: TitleOptions,
) => {
  switch (action) {
    case navigate.PREVIOUS:
      return localizer.add(date, -length, 'day');

    case navigate.NEXT:
      return localizer.add(date, length, 'day');

    default:
      return date;
  }
};

AgendaView.title = (start: Date, { length = DEFAULT_LENGTH, localizer }: TitleOptions): string => {
  const end = localizer.add(start, length, 'day');
  return localizer.format({ start, end }, 'agendaHeaderFormat');
};

export default AgendaView as (React.ComponentType<any> & ViewStatic);
