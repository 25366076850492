import React, { useState } from 'react';
import { useWorkoutTemplateBackend } from 'network/queries/WorkoutTemplateQueries';
import Messages from 'services/i18n/Messages';
import {
  Link,
  useHistory,
  useLocation,
} from 'react-router-dom';
import {
  ID,
  MANAGE_WORKOUT_ADD,
  WORKOUT_DETAIL,
  WORKOUTS,
} from 'routes/Routes';
import { START_WORKOUT } from 'routes/QueryParams';
import DialogWrapper from 'lib/DialogWrapper';
import { Routes } from 'routes/RoutesUtils';
import { useWorkoutBackend } from 'network/queries/WorkoutQueries';
import SpinButton from 'theme/SpinButton';
import Header from 'pages/common/Header';

export default function Workouts() {
  const location = useLocation();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const workoutTemplateQueries = useWorkoutTemplateBackend();
  const { getWorkoutTemplate } = workoutTemplateQueries;
  const { data: workoutTemplates } = getWorkoutTemplate();
  const workoutQueries = useWorkoutBackend();
  const { startWorkout } = workoutQueries;
  const startWorkoutId = new URLSearchParams(location.search).get(START_WORKOUT);

  const selectedWorkout = workoutTemplates?.filter((workoutTemplate) => workoutTemplate.id
    === startWorkoutId)[0];

  const startWorkoutFn = (workoutTemplateId: string) => {
    setSubmitting(true);
    startWorkout.mutateAsync({ workoutTemplateID: workoutTemplateId })
      .then((workout) => {
        history.push(Routes.withPath(WORKOUT_DETAIL, [{ label: ID, value: workout.id }]));
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <Header hideBackButton label={Messages.t('workout.start')} />

      <div className="page-body">
        {
          selectedWorkout && (
            <DialogWrapper
              open
              onClose={() => history.push(WORKOUTS)}
            >
              <h1>{Messages.t('workout.start')}</h1>
              <h2>{selectedWorkout.name}</h2>
              <SpinButton
                type="button"
                spin={submitting}
                onClick={() => startWorkoutFn(selectedWorkout.id)}
                title={Messages.t('global.start')}
              />
            </DialogWrapper>
          )
        }
        {
          workoutTemplates?.map((workoutTemplate) => (
            <Link
              key={workoutTemplate.id}
              to={Routes.updatePathWithQuery(
                location,
                [{ label: START_WORKOUT, value: workoutTemplate.id }],
              )}
              className="link-as-button"
            >
              {workoutTemplate.name}
            </Link>
          ))
        }
        <Link to={MANAGE_WORKOUT_ADD} className="link-as-button">
          {Messages.t('workout.create')}
        </Link>
      </div>
    </>
  );
}
