import React, { useState } from 'react';
import Button from 'theme/Button';
import { useMealHistoryBackend } from 'network/queries/MealsHistoryQueries';
import Messages from 'services/i18n/Messages';
import DialogWrapper from 'lib/DialogWrapper';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import TextFieldWrapper from 'lib/form/TextFieldWrapper';
import SpinButton from 'theme/SpinButton';
import { FetchError } from 'network/Errors';
import {
  MissingMealDay,
} from 'types/types';

type Props = {
  onClose: () => void
  missingMeal: MissingMealDay,
};

type UnknowMealCreate = {
  name: string
};

export default function UnknowMealModal({ missingMeal, onClose }: Props) {
  const { createUserMeal } = useMealHistoryBackend();
  const [apiErrors, setApiErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const onSubmit: SubmitHandler<UnknowMealCreate> = async (
    data: UnknowMealCreate,
  ) => {
    setSubmitting(true);
    createUserMeal.mutateAsync({
      date: missingMeal.date,
      mealName: data.name,
      recurrentMealId: missingMeal.id,
    })
      .then(() => {
        onClose();
      })
      .catch((error: FetchError) => {
        if (error.json_response) {
          setApiErrors(error.json_response);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UnknowMealCreate>({
    defaultValues: {},
  });

  return (
    <DialogWrapper open onClose={onClose}>
      <DialogTitle>{Messages.t('meal.unkwnown.title')}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="name"
            control={control}
            render={(controller) => (
              <TextFieldWrapper
                apiErrors={apiErrors}
                requierd
                error={errors}
                control={controller}
                label={Messages.t('field.name')}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error">{Messages.t('formButton.cancel')}</Button>
          <SpinButton
            editing
            className="submit-button"
            spin={submitting}
          >
            {Messages.t('formButton.add')}
          </SpinButton>
        </DialogActions>
      </form>
    </DialogWrapper>
  );
}
